import { Fragment } from "react";
import { Link } from "react-router-dom";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
import { useAuthContext } from "@provider/AuthContextProvider";
import { useNotificationContext } from "@provider/NotificationProvider";
// utils
import { logout } from "@utils/AuthHelpers";
// packages
import { Menu, Transition, MenuButton } from "@headlessui/react";
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.png';
// components
import ProfileBox from "./ProfileBox";
import TeamBox from "./TeamBox";
import MenuLinks, { SocialIcons } from "./MenuLinks";
import MyTournaments from "../MyTournaments/MyTournaments";
import Notifications from "../Notifications/Notifications";
// icons
import { DropDownArrowIcon, SignOutIcon} from "@icons/Header";
import { VersusIcon } from "@src/components/common/icons/Common";

const ProfileMenu = () => {
  const { userObj } = useAuthContext();
  const { setNavbarState, navbarState } = useHeaderContext();
  const { notificationsCount, activeTournamentsCount } = useNotificationContext();

  const handleLogOut = () => {
    setNavbarState({
      ...navbarState,
      profileMenuVisible: false,
    });
    logout();
  }

  const openSignUp = () => {
    setNavbarState({
      ...navbarState,
      signUpMenuVisible: true,
    })
  }

  const openLogIn = () => {
    setNavbarState({
      ...navbarState,
      logInMenuVisible: true,
    })
  }

  const closeProfileMenu = () => {
    document.body.classList.remove("overflow_hidden");
    setNavbarState({
      ...navbarState,
      profileMenuVisible: false,
      notificationMenuVisible: false,
      activeTournamentsVisible: false,
    });
  };

  const toggleProfileMenu = () => {
    if (navbarState.profileMenuVisible) {
      document.body.classList.remove("overflow_hidden");
      setNavbarState({
        ...navbarState,
        profileMenuVisible: false,
        notificationMenuVisible: false,
        activeTournamentsVisible: false,
      });
    } else {
      document.body.classList.add("overflow_hidden");
      setNavbarState({
        ...navbarState,
        profileMenuVisible: true,
        notificationMenuVisible: false,
        activeTournamentsVisible: false,
      });
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      {navbarState.profileMenuVisible ? (
        <Overlay onClick={closeProfileMenu}/>
      ) : ''}
      <MenuButton onClick={toggleProfileMenu}
                  className={`relative outline-none h-full flex items-center`}>
        {userObj ? (
          <div className="flex items-center gap-2">
            <div className="h-fit w-fit relative">
              <span className={`${userObj && (notificationsCount + activeTournamentsCount) > 0 ? 'md:hidden' : 'hidden'} absolute top-[-0.3rem] z-10 right-[-30%] pt-1
                                font-compact bg-green text-sm text-black font-semibold w-[1.2rem] h-[1.2rem] rounded-full
                                flex items-center justify-center`}>
                {notificationsCount + activeTournamentsCount}
              </span>
              <span className={`${userObj && activeTournamentsCount > 0 ? 'hidden md:flex' : 'hidden'} absolute top-[-0.3rem] z-10 right-[-30%] pt-1
                                font-compact bg-green text-xs text-black font-semibold w-[0.9rem] h-[0.9rem] rounded-full
                                items-center justify-center`}>
                {activeTournamentsCount}
              </span>
              <img src={userObj && userObj.displayImage ? userObj.displayImage: imagePlaceholder}
                  alt="avatar-img"
                  className={`w-[30px] h-[30px] md:w-[23px] md:h-[23px] rounded-full bg-lightGray
                  ${userObj && !userObj.displayImage ? "border border-steelGray/50": ""}`}/>
            </div>
            <DropDownArrowIcon />
          </div>
        ) : (
          <div className="flex flex-col justify-center gap-y-[4px] items-end w-[25px] h-auto aspect-square">
            <div className="w-full h-[3px] bg-steelGray"></div>
            <div className="w-full h-[3px] bg-steelGray"></div>
            <div className="w-3/5 h-[3px] bg-steelGray"></div>
          </div>
        )}
      </MenuButton>
      <Transition
        as={Fragment}
        show={navbarState.profileMenuVisible}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className={`fixed custom_lg:absolute z-[22] outline-none left-0 sm:left-[unset] sm:w-[22rem] sm:top-[5.2rem] custom_lg:top-[3.9rem] sm:right-3 custom_lg:right-0
                         w-full h-[calc(100dvh-4.2rem)] sm:h-fit flex bg-lightBlack sm:rounded-xl`}>
            <div className={`absolute top-0 left-0 bg-lightBlack sm:rounded-xl overflow-y-scroll theme_scroll_bar w-full sm:w-[22rem] flex-shrink-0 h-full sm:h-fit px-4 py-10 pb-24 sm:py-4 outline-none flex flex-col justify-between
                            ${navbarState.notificationMenuVisible || navbarState.activeTournamentsVisible ? 'opacity-0 -translate-x-full pointer-events-none' : 'opacity-1'} transition-all`}>
              <div>
                {userObj ? (
                  <>
                    <ProfileBox handleClick={closeProfileMenu} userObj={userObj} />
                    <TeamBox handleClick={closeProfileMenu} />
                  </>
                ) : ''}
                <MenuLinks handleClick={closeProfileMenu} />

                <div className="border-t border-lightGray block"></div>

                {userObj ? (
                  <Link to="/"
                        onClick={handleLogOut}
                        className="flex items-center gap-2 mt-5 hover:opacity-75 transition-opacity">
                    <SignOutIcon className="w-[16px] h-auto aspect-square fill-red/50"/>
                    <p className="font-compact text-red/50 font-normal text-base leading-4">
                      Sign out
                    </p>
                  </Link>
                ) : ""}


              </div>
              <div className="justify-self-end">
                {userObj ? (
                  <button type="button"
                          disabled={true}
                          className="w-full p-2 py-[0.6rem] bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green text-black font-compact font-medium
                                    flex items-center gap-x-2 justify-center rounded-lg  mt-6 mb-2">
                    <VersusIcon className="w-[16px] h-auto aspect-square"/>
                    <span>Membership</span>

                  </button>
                ) : (
                  <div className='flex gap-x-4 my-4 w-full'>
                    <button type="button"
                      onClick={openLogIn}
                      className='text-white font-semibold flex-grow py-3 pb-[0.5rem] uppercase font-compact bg-ebonyClay text-lg rounded-lg hover:opacity-75 transition-opacity'>
                      Log In
                    </button>

                    <button type="button"
                      onClick={openSignUp}
                      className='text-black font-semibold flex-grow py-3 pb-[0.5rem] uppercase font-compact bg-green text-lg rounded-lg hover:opacity-75 transition-opacity'>
                      Sign Up
                    </button>
                  </div>
                )}
                <SocialIcons handleClick={closeProfileMenu} />
              </div>
            </div>
            <div className={`relative w-full sm:w-[22rem] flex-shrink-0 h-full sm:h-fit
                             ${navbarState.notificationMenuVisible || navbarState.activeTournamentsVisible ? '' : 'translate-x-full'} transition-all`}>
              <div className={`absolute top-0 left-0
                              sm:rounded-xl bg-lightBlack overflow-y-scroll theme_scroll_bar w-full h-full sm:h-fit outline-none
                              ${navbarState.notificationMenuVisible ? 'opacity-1' : 'opacity-0 pointer-events-none'} transition-all`}>
                <Notifications setDropdownOverlay={(open: boolean) => open}/>
              </div>
              <div className={`absolute top-0 left-0
                              sm:rounded-xl bg-lightBlack overflow-y-scroll theme_scroll_bar w-full h-full sm:h-fit outline-none
                              ${navbarState.activeTournamentsVisible ? 'opacity-1' : 'opacity-0 pointer-events-none'} transition-all`}>
                <MyTournaments setDropdownOverlay={(open: boolean) => open}/>
              </div>
            </div>
        </div>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;

const Overlay = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      className={
        "fixed top-0 left-0 bg-black h-screen w-full bg-opacity-[0.4] z-[21]"
      }
      onClick={onClick}
    ></div>
  );
};
