import { useState, useRef, useEffect } from "react";
// types
import { DBTeam } from "../../firestore/teams";
// context
import TeamShowProvider from "./TeamShowProvider";
// components
import ProfileHero from "./show/ProfileHero";
import ProfileAbout from "./show/ProfileAbout";
import ProfileTrophies from "./show/ProfileTrophies";
import TeamPlayers from "./show/TeamPlayers";
import Sponsors from "./Sponsors";
// edit form components
import TeamInfoProvider from "./TeamInfoProvider";
import TeamModal from "./creation/TeamModal";
import CreateTeamProfile from './edit/CreateTeamProfile';
import CreateTeamRegion from './edit/CreateTeamRegion';
import CreateTeamLogo from './edit/CreateTeamLogo';
// import CreateTeamInvitation from './edit/CreateTeamInvitation';
// ads
// import SurfsharkAdMobile from "../adverts/surfshark/SurfsharkAdMobile";
import FindATeamGray from "../common/FindTeamGray";

import EditTeamInvitation from './edit/EditTeamInvitation';
// icons
import { LuUnlink } from "react-icons/lu";
import EditTeamPayment from "./edit/EditTeamPayment";
import { ImSpinner8 } from "react-icons/im";

const Team = () => {
  const [team, setTeam] = useState<DBTeam | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const handleTeamChange = (team: DBTeam | null) => {
    setTeam(team);
  }

  const handleLoadingChange = (loading: boolean) => {
    setLoading(loading);
  }
  // state for team modal control (edit form)
  const [currentModalStep, setCurrentModalStep] = useState<number | null>(null); // should start at null

  const changeModalStep = (direction: -1 | 1) => {
    if (currentModalStep) {
      let nextModalStep = currentModalStep + direction;
      if (nextModalStep < 1) {
        nextModalStep = 1;
      } else if (nextModalStep > 5) {
        nextModalStep = 5
      }
      setCurrentModalStep(nextModalStep);
    }
  }

  const closeModal = () => {
    setCurrentModalStep(null);
  }

  const profileHeroRef = useRef(null);
  const profileAboutRef = useRef(null);

  const resizeListener = () => {
    const hero = profileHeroRef.current;
    const about = profileAboutRef.current;
    if (hero && about) {
      (about as HTMLDivElement).style.height = `${(hero as HTMLDivElement).offsetHeight}px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    setTimeout(resizeListener, 500);

    return () => window.removeEventListener('resize', resizeListener);
  }, [])

  return (
    <>
      <TeamShowProvider handleTeamChange={handleTeamChange} handleLoadingChange={handleLoadingChange}>
        <TeamInfoProvider team={team}>
          {loading ? (
            <div className="relative w-full min-h-[80vh]
                            flex items-center justify-center">
              <ImSpinner8 className="w-[60px] h-auto aspect-square animate-spin text-green"/>
            </div>
          ) : (
            <>
              {team ? (
                <>
                  {team.dissolved ? (
                    <div className="relative w-full min-h-[80vh]">
                      <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    text-xl sm:text-4xl font-semibold text-green
                                    flex flex-col sm:flex-row items-center gap-x-4">
                        <p>Team has been dissolved</p>
                        <LuUnlink/>
                      </h3>
                    </div>
                  ) : (
                    <div className="text-white pb-[5rem] custom_container">
                      {/*  Top Row */}
                      <div className="flex gap-x-12 h-fit sm:px-8 lg:px-12">
                        <div className="h-fit w-[65%] flex-grow flex flex-col gap-y-6">
                          <div ref={profileHeroRef} className="w-full h-fit">
                            <ProfileHero setCurrentModalStep={setCurrentModalStep} className="w-full" />
                          </div>
                          <div className="px-4 sm:px-0 flex flex-col gap-y-6">
                            <ProfileAbout className="md:hidden" />
                            <Sponsors setCurrentModalStep={setCurrentModalStep}/>
                            <ProfileTrophies className="md:hidden" />
                            <TeamPlayers setCurrentModalStep={setCurrentModalStep}/>
                          </div>
                        </div>
                        <div className="hidden md:flex w-[26%] flex-grow flex-col gap-y-6">
                          <div ref={profileAboutRef} className="w-full flex flex-col gap-y-4">
                            <ProfileAbout className="w-full flex-grow" />
                            {/* <div className="h-fit w-auto">
                              <SurfsharkAdMobile/>
                            </div> */}
                            <div className="relative bg-lightBlack w-full h-auto !aspect-[1.55] rounded-2xl flex items-center justify-center">
                              <FindATeamGray/>
                            </div>
                          </div>
                          <ProfileTrophies />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="relative w-full min-h-[80vh]">
                  <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                text-xl sm:text-4xl font-semibold text-green
                                flex flex-col sm:flex-row items-center gap-x-4">
                    <p>Team not found</p>
                    <LuUnlink/>
                  </h3>
                </div>
              )}
            </>
          )}
          <TeamModal
            closeModal={closeModal}
            modalOpen={currentModalStep === 1}
            modalInner={
              <CreateTeamProfile closeModal={closeModal} changeModalStep={changeModalStep}/>
            }
            />

          <TeamModal
            closeModal={closeModal}
            modalOpen={currentModalStep === 2}
            modalInner={
              <CreateTeamRegion closeModal={closeModal} changeModalStep={changeModalStep}/>
            }
            />

          <TeamModal
            closeModal={closeModal}
            modalOpen={currentModalStep === 3}
            modalInner={
              <EditTeamPayment closeModal={closeModal} changeModalStep={changeModalStep}/>
            }
            />

          <TeamModal
            closeModal={closeModal}
            modalOpen={currentModalStep === 4}
            modalInner={
              <CreateTeamLogo closeModal={closeModal} changeModalStep={changeModalStep}/>
            }
            />

          <TeamModal
            closeModal={closeModal}
            modalOpen={currentModalStep === 5}
            modalInner={
              <EditTeamInvitation closeModal={closeModal}/>
            }
            />


        </TeamInfoProvider>
      </TeamShowProvider>
    </>
  );
};

export default Team;
