import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from "../TournamentProvider";
import { useLeaderboardContext } from '../LeaderboardProvider';
// components
import LeaderboardStageV from './LeaderboardStage';
import { tournamentStageNumToName, checkIfStageActive } from '../../../firestore/tournaments';
import Skeleton from '../../common/skeleton-loader/Skeleton';

const Leaderboards = () => {
  const { tournament, tournamentCompleted } = useTournamentContext();

  const playStarted = tournament !== null && tournament.stagesInPlay.length > 0;

  const { leaderboardStages } = useLeaderboardContext();

  const [initialStageLoaded, setInitialStageLoaded] = useState<boolean>(false);
  const [currentStage, setCurrentStage] = useState<number>(0);

  useEffect(() => {
    if (tournament) {
      if (!initialStageLoaded) {
        setCurrentStage(tournament.activeStage);
        setInitialStageLoaded(true);
      }
    }
  }, [tournament])

  return tournament && leaderboardStages.length > 0 ? (
    <div className="px-4 sm:px-8 lg:px-12 mt-10">
      <div className='flex items-start gap-x-4 pb-3'>
        <h2 className="font-wide font-bold text-2xl !leading-6 uppercase text-white">
          <span>{playStarted ? tournamentCompleted ? 'Results' : 'Leaderboards' : 'Team Slots'}</span>
        </h2>
        <div className='flex items-center gap-x-2 -mt-2'>
          {leaderboardStages.sort((a, b) => a.stageNum - b.stageNum).map((stage) => (
            <button key={`stage-button-${stage.stageNum}`} type="button"
                    onClick={() => setCurrentStage(stage.stageNum)}
                    className={`p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentStage === stage.stageNum ? `bg-lightGray ${checkIfStageActive(stage.stageNum, tournament) ? 'text-green' : 'text-white/85'}`
                              : `hover:bg-lightGray/20 ${checkIfStageActive(stage.stageNum, tournament) ? 'text-green/70 hover:text-green' : 'text-steelGray hover:border-steelGray'}`}`}>
              {tournamentStageNumToName(tournament, stage.stageNum)}
            </button>
          ))}
        </div>
      </div>

      <div>
        {leaderboardStages.map((stage) => (
          <div key={`stage-view-${stage.stageNum}`}>
            {currentStage === stage.stageNum ? (
              <LeaderboardStageV stage={stage}/>
            ) : ''}
          </div>
        ))}
      </div>
    </div>
  ): (
    <div className="px-4 sm:px-8 lg:px-12 mt-10">
      <div className='flex items-center gap-x-4'>
        <Skeleton className="w-[150px] h-[30px] rounded-lg">
        </Skeleton>
        <div className='flex items-center gap-x-2'>
          <Skeleton className="w-[75px] h-[25px] rounded-xl"/>
          <Skeleton className="w-[75px] h-[25px] rounded-xl"/>
        </div>
      </div>

      <div className='mt-2'>
        <div className="flex items-center gap-x-2 mb-2">
          <Skeleton className="w-[75px] h-[25px] rounded-xl"/>
          <Skeleton className="w-[75px] h-[25px] rounded-xl"/>
          <Skeleton className="w-[75px] h-[25px] rounded-xl"/>
        </div>

        <div className='flex flex-col gap-y-3 mt-2'>
          {Array.from({length: 20}).map((_i , index) => (
            <Skeleton key={index} className="w-full h-[80px] rounded-lg"/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Leaderboards;
