// components
import SearchTeamMate from "./SearchTeamMate";
// icons
import { CrossIcon } from "../../common/icons/FilterIcons";

interface IProps {
  closeModal: () => void,
}

const EditTeamInvitation = (props: IProps) => {

  const { closeModal } = props;

  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="px-4 py-[0.75rem] border-b-[0.063rem] border-lightGray">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
              Invite Players
            </h5>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => closeModal()}
          >
            <CrossIcon />
          </span>
        </div>
      </div>
      <div className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">
        <SearchTeamMate/>
      </div>
      <div
        className="py-[0.75rem] px-4 flex border-t-[0.063rem] border-lightGray"
      >
        <button type="button"
                onClick={closeModal}
                className="flex justify-center items-center font-compact w-full text-base font-semibold py-4 !leading-4 rounded-xl uppercase text-center common_green_btn gap-2">
          Done
        </button>
      </div>
    </div>
  );
};

export default EditTeamInvitation;
