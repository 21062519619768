import { useEffect, useState } from "react";
// context
import { useTeamsContext } from "../TeamsProvider";
// components
import HeroSlideInner from './HeroSlideInner';
import {SkeletonLight, SkeletonDark} from "../../common/skeleton-loader/Skeletons";
// libaries
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, EffectFade, Navigation, Pagination, Scrollbar } from "swiper/modules";
// icons
import { SwiperArrow } from "../../common/icons/Common";

const Hero = () => {
  const { featuredTeams } = useTeamsContext();
  const [swiperController, setSwiperController] = useState<SwiperCore>();

  useEffect(() => {
    setTimeout(() => {
      if (swiperController && featuredTeams.length > 0) {
        // we have to do this to 'activate' swiper to reconfigure itself for the loaded featured tournaments since they arent there when it mounts
        swiperController.slideTo(0);
        swiperController.params.loop = true;
        swiperController.loopCreate();
        // @ts-expect-error loopFix IS defined but inconsistent types provided with swiper
        swiperController.loopFix();
        swiperController.update();
      }
    }, 100)
  }, [featuredTeams, swiperController])

  return featuredTeams.length > 0 ? (
    <div className="md:px-8 lg:px-12 mx-auto relative custom_3xl:max-w-none">
      <button className="z-10 absolute left-0 lg:left-4 translate-x-[50%] top-[43%] translate-y-[-50%] bg-transparent border-0 rotate-[180deg] left_arrow hidden md:block">
        <span className="duration-300 hover:opacity-70 w-8 h-8 rounded-full bg-lightGray flex justify-center items-center">
          <SwiperArrow />
        </span>
      </button>
      <button className="z-10 absolute right-8 lg:right-12 translate-x-[50%] top-[43%] translate-y-[-50%] bg-transparent border-0 right_arrow hidden md:block">
        <span className="duration-300 hover:opacity-70 w-8 h-8 rounded-full bg-lightGray flex justify-center items-center">
          <SwiperArrow />
        </span>
      </button>
      <Swiper
        className="relative compete_swiper pb-11"
        onSwiper={setSwiperController}
        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
        navigation={{ nextEl: ".right_arrow", prevEl: ".left_arrow" }}
        speed={1000}
        loop={false}
        autoplay={false}
        pagination={{ clickable: true }}
        spaceBetween={24}
        slidesPerView={1}>
        {/* <div className="absolute w-full h-full bg-black/50 backdrop-blur z-[50] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                        text-2xl lg:text-5xl text-orange-400 font-wide font-semibold flex items-center justify-center">
          Under Construction
        </div> */}
        <div>
          {featuredTeams.map((team) => (
            <SwiperSlide key={`${team.id}-hero`}>
              <HeroSlideInner team={team}/>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  ) : (
    <div className="md:px-8 lg:px-12 mx-auto relative custom_3xl:max-w-none w-full">
      <div className='relative custom_3xl:max-w-none w-full mx-auto h-fit rounded-xl flex justify-between gap-x-2 mb-[120px] md:mb-0'>
        <SkeletonDark className="w-[90%] h-fit md:w-[40%] custom_xl:w-[32%] md:h-auto md:aspect-[1.7] custom_xl:aspect-[1.35] z-10 md:bg-none hidden md:flex mt-[-6.25rem] md:mt-0 md:mx-0 rounded-xl p-3 lg:p-6
                                 flex-col justify-between">
          <SkeletonLight className="w-1/4 h-[30px] rounded-xl mt-2"/>
          <SkeletonLight className="w-5/6 h-[25px] rounded-xl mt-4"/>
          <SkeletonLight className="w-full h-[15px] rounded-xl mt-2"/>
          <SkeletonLight className="w-full h-[2px] rounded-xl my-3"/>
          <SkeletonLight className="w-full md:h-[40px] custom_xl:h-[50px] rounded-xl mt-2"/>
        </SkeletonDark>
        <SkeletonDark className="w-full h-auto max-md:aspect-[1.7] md:w-[60%] custom_xl:w-[68%] rounded-xl">
          <SkeletonLight className="md:hidden absolute bottom-0 left-1/2 w-[90%] h-fit min-h-[185px] -translate-x-1/2 translate-y-1/2 rounded-xl p-3 lg:p-6
                                 flex flex-col justify-between">
            <div className="flex w-full justify-between">
              <div className="w-[60%]">
                <SkeletonDark className="w-1/4 h-[30px] rounded-xl mt-2"/>
                <SkeletonDark className="w-5/6 h-[25px] rounded-xl mt-4"/>
              </div>
              <SkeletonDark className="rounded-full h-[70px] w-auto aspect-square"/>
            </div>
            <SkeletonDark className="w-full h-[40px] custom_xl:h-[50px] rounded-xl mt-2"/>
          </SkeletonLight>
        </SkeletonDark>
      </div>
      <div className="w-fit mx-auto flex items-center gap-x-3 mt-2 md:mt-10">
        <SkeletonDark className="w-[40px] h-[5px] rounded"/>
        <SkeletonDark className="w-[40px] h-[5px] rounded"/>
      </div>
    </div>
  );
};

export default Hero;
