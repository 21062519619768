import { useState } from 'react';
// context
import TournamentsProvider from "./TournamentsProvider";
// components
import { SkeletonDark, SkeletonLight } from '../common/skeleton-loader/Skeletons';
import MainList from "./Lists/MainList";
import Hero from "./Hero/Hero";
import CompletedList from './Lists/CompletedList';
// adverts
// import FindTeam from "@common/FindTeam";
import SurfsharkAdDesktop from '@components/adverts/surfshark/SurfsharkAdDesktop';
import SurfsharkAdMobile from '@components/adverts/surfshark/SurfsharkAdMobile';

const Tournaments = () => {
  const [tournamentsLoaded, setTournamentsLoaded] = useState<boolean>(false);

  return (
    <div className="mb-[4.5rem] mx-auto custom_container lg:mb-8">
      <TournamentsProvider announceInitiallyLoaded={setTournamentsLoaded}>
        <Hero />
        <MainList />
        {tournamentsLoaded ? (
          <div className='px-4 sm:px-8 lg:px-12 mx-auto'>
            {/* <FindTeam className='!mt-8 !mb-2'/> */}
            <div className='hidden md:block !mt-8 !mb-2'>
              <SurfsharkAdDesktop/>
            </div>
            <div className='md:hidden !mt-8 !mb-2'>
              <SurfsharkAdMobile/>
            </div>
          </div>
        ) : (
          <div className='px-4 sm:px-8 lg:px-12 mx-auto mt-12 mb-[6rem]'>
            <SkeletonDark className='flex items-center justify-between w-full h-[150px] rounded-xl p-3 xl:p-6'>
              <div className='w-[70%] h-full flex justify-between gap-x-2'>
                <SkeletonLight className='w-[40%] h-full rounded-lg'/>
                <div className="w-[50%]">
                  <SkeletonLight className='w-[80%] h-[30px] rounded-lg mt-2'/>
                  <SkeletonLight className='w-[90%] h-[25px] rounded-lg mt-2'/>
                  <SkeletonLight className='w-[90%] h-[25px] rounded-lg mt-2'/>
                </div>

              </div>

              <SkeletonLight className='w-[15%] h-[35px] rounded-lg'/>
            </SkeletonDark>
          </div>
        )}
        <CompletedList />
      </TournamentsProvider>
    </div>
  );
};
export default Tournaments;
