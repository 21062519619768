import { Link } from "react-router-dom";
// types
import { tournamentRegionToString, Tournament } from "@src/firestore/tournaments";
// utils
import { gameToString } from '@src/types/Games/Games';
// icons
import { WhiteRegionIcon, Trophy ,GroupUsers} from "@common/icons/Common";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon  } from "@common/icons/Games";

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[16px] lg:w-[18px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[16px] lg:w-[18px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[16px] lg:w-[18px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[16px] lg:w-[18px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon'
}

interface ITournamentSlide {
  className?: string,
  tournament: Tournament
}

const TournamentSlide: React.FC<ITournamentSlide> = ({tournament, className}) => {
  const totalPrizePool = tournament ? tournament.prizePool.prizes.reduce((acc, prize) => acc + prize.prizeAmount, 0) : 0;
  const capacityPercentage = (tournament.entrantsCount / tournament.teamCapacity) * 100;
  const gameIcon = gameNameToIcon(gameToString(tournament.game));


  return (
    <Link to={`/tournaments/${tournament.id}`}
          className={`${className} md:bg-lightBlack rounded-lg overflow-hidden block w-full`}>
        <div className="relative flex flex-col-reverse md:flex-row flex-wrap justify-between items-center md:items-center">
          <div className="w-[90%] h-fit md:w-[40%] custom_xl:w-[32%] md:h-auto md:aspect-[1.7] custom_xl:aspect-[1.35] !bg-lightBlack z-10 md:bg-none rounded-lg mt-[-6.25rem] md:mt-0 md:mx-0">
            <div className="w-full p-3 sm:p-6 sm:px-8 md:!py-1 lg:py-6 h-full flex flex-col justify-between md:justify-center gap-y-6">
              <div className="flex flex-col">

                <div className="flex justify-between md:justify-normal md:flex-col w-full">
                  <div className="w-full">

                    <div className={`px-[0.65rem] py-[0.475rem] lg:px-3 custom_xl:py-[0.594rem] bg-lightGray rounded-full flex items-center whitespace-nowrap text-white w-fit`}>
                      <span className="inline-block">
                          {gameIcon}
                      </span>
                      <span className={`font-compact font-normal text-sm lg:text-base !leading-4 ms-[0.6rem] tracking-[0.009rem] -mb-[0.188rem]`}>
                          {gameToString(tournament.game)}
                      </span>
                    </div>

                    <h2 className="font-wide font-bold mt-3 md:mt-2 text-[1.65rem] md:text-base custom_xl:text-[1.65rem] custom_xl:mt-6 xl:!text-[2.4rem] xl:leading-[2.4rem] text-white !md:leading-[1.875rem] uppercase flex-grow-0">
                      {tournament.name}
                    </h2>
                  </div>
                </div>
                <div className="md:flex hidden flex-col md:flex-row items-center justify-center md:justify-normal md:gap-3 progress-bar w-full h-[5rem] md:w-auto md:h-auto mt-3 xl:mt-4">
                  <p className="text-lg font-medium tracking-[0.01rem] font-compact text-green !leading-4">
                    {Math.round(capacityPercentage)}%
                  </p>
                  <div className={`hidden sm:block relative duration-300 group-hover:bg-ebonyClay bg-lightGray h-[6px] w-full rounded-md after:content-[''] after:absolute after:top-0 after:left-0 after:h-[6px] after:bg-green after:rounded-md after:shadow-[0px_0px_24px_4px_yellowGreen] overflow-y-hidden`}>
                    <span style={{ width: `${capacityPercentage}%` }}
                      className={`absolute  bg-green h-full`}></span>
                  </div>
                  <p className="text-sm font-normal tracking-[0.009rem] font-compact text-darkGray !leading-4">
                    {tournament.entrantsCount}/{tournament.teamCapacity}
                  </p>
                </div>
              </div>

              <div className="hidden md:block w-full h-[2px] rounded bg-lightGray"></div>

              <div className="flex items-center justify-between border border-lightGray p-4 px-0 sm:p-3 custom_lg:px-4 lg:py-5 xl:px-2 rounded-lg w-full">
                <div className="w-full flex items-center flex-col text-center px-5">
                  <p className="font-compact font-normal text-darkGray !leading-4 tracking-[0.009rem] mb-0">
                    Region
                  </p>
                  <div className="flex items-center gap-2 mt-2">
                    <WhiteRegionIcon />
                    <p className="font-compact font-medium text-white !leading-4 tracking-[0.009rem] mt-[0.188rem]">
                      {tournamentRegionToString(tournament.region)}
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-col items-center border-x-[0.063rem] border-lightGray px-5">
                  <p className="font-compact font-normal whitespace-nowrap text-darkGray !leading-4 tracking-[0.009rem] mb-0">
                    Team Size
                  </p>
                  <div className="flex items-center gap-2 mt-2">
                    <GroupUsers />
                    <p className="font-compact font-medium text-white !leading-4 tracking-[0.009rem] mt-1">
                      {tournament.teamSize}
                    </p>
                  </div>
                </div>
                <div className="w-full flex flex-col items-center px-5">
                  <p className="font-compact whitespace-nowrap font-normal text-darkGray !leading-4 tracking-[0.009rem] mb-0">
                    Prize Pool
                  </p>
                  <div className="flex items-center gap-2 mt-2">
                    <Trophy className="fill-green"/>
                    <p className="font-compact font-medium text-white !leading-4 tracking-[0.009rem] mt-[0.188rem]">
                      {totalPrizePool > 0 ? `${tournament.prizePool.currency}${totalPrizePool}` : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:absolute md:right-0 md:top-0 w-full md:w-[60%] custom_xl:w-[68%] h-full flex-grow">
            <img
              className="hidden md:block w-full h-full object-cover object-center xl:object-right"
              src={tournament.bannerImage}
              alt="Tournament hero image"
            />
            <img
              className="md:hidden w-full h-[26.75rem] object-cover object-top"
              src={tournament.cardImage}
              alt="Tournament hero image"
            />
          </div>
        </div>
      </Link>
  )
}

export default TournamentSlide
