import NewsDetails from "./NewsDetails";
import NewsDetailsBanner from "./NewsDetailsBanner";

const NewsDetailMain = () => {
  return (
    <>
      <NewsDetailsBanner />
      <NewsDetails />
    </>
  );
};

export default NewsDetailMain;
