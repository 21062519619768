import { useState, useEffect, createContext, ReactNode, useContext, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
// firebase
import { firestore, storage } from "@src/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
// context
import { useAuthContext } from "@src/provider/AuthContextProvider";
import { toast } from "react-toastify";
// types
import { userConverter, ProfileGame } from "@src/firestore/users";
// utils
import { resizeImage } from "@src/utils/ResizeImage";
import { formatLink } from "@src/utils/Link";

export enum ProfileCompletionStep {
  main,
  social,
  game,
  confirm
}

interface IProfileInfo {
  displayName: string,
  username: string,
  dateOfBirth: Date | null,
  showAge: boolean,
  nationality: string,
  bio: string,
  games: ProfileGame[],
  discord: string,
  twitch: string,
  instagram: string,
  youtube: string,
  twitter: string,
  displayImage: File | null,
  bannerImage: File | null,
  displayImageUrl: string,
  bannerImageUrl: string,
  originalDisplayImageUrl: string,
  originalBannerImageUrl: string,
}

interface IProfileInfoContext {
  profileInfo: IProfileInfo;
  setProfileInfo: (info: IProfileInfo) => void,
  currentModalStep: ProfileCompletionStep | -1,
  setCurrentModalStep: (step: ProfileCompletionStep | -1) => void,
  completeProfile: () => void,
  updateProfile: () => void,
  updateMode: boolean,
  openCompleteProfile: () => void,
  openEditProfile: () => void,
  reloadInfo: () => void,
}

const defaultProfileInfo = {
  displayName: '',
  username: '',
  dateOfBirth: null,
  showAge: false,
  nationality: '',
  games: [],
  bio: '',
  discord: '',
  twitch: '',
  instagram: '',
  youtube: '',
  twitter: '',
  displayImage: null,
  bannerImage: null,
  displayImageUrl: '',
  bannerImageUrl: '',
  originalDisplayImageUrl: '',
  originalBannerImageUrl: '',
};

const defaultProfileInfoContext = {
  profileInfo: defaultProfileInfo,
  setProfileInfo: (info: IProfileInfo) => console.log(info),
  currentModalStep: -1,
  setCurrentModalStep:  (step: ProfileCompletionStep | -1) => console.log(step),
  completeProfile: () => false,
  updateProfile: () => false,
  openCompleteProfile: () => false,
  openEditProfile: () => false,
  reloadInfo: () => false,
  updateMode: false,
};

const ProfileInfoContext = createContext<IProfileInfoContext>(defaultProfileInfoContext);

export const useProfileInfoContext = () => {
  const context = useContext(ProfileInfoContext);

  return context;
};

interface IProfileInfoProvider {
  children: ReactNode
}

const ProfileInfoProvider: React.FC<IProfileInfoProvider> = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userObj } = useAuthContext();
  const [initialUserLoaded, setInitialUserLoaded] = useState<boolean>(false);


  const [profileInfo, setProfileInfo] = useState<IProfileInfo>(defaultProfileInfo);
  const [currentModalStep, setCurrentModalStep] = useState<ProfileCompletionStep | -1>(-1);
  const [updateMode, setUpdateMode] = useState<boolean>(false);

  interface LoadProfileOptions {
    manual: boolean
  }

  const loadProfileInfo = useCallback((options?: LoadProfileOptions) => {
    const manual = options && options.manual;
    if (userObj && (!initialUserLoaded || manual || updateMode !== userObj.profileComplete)) {
      if (userObj.profileComplete) {
        const loadedInfo = {
          displayName: userObj.displayName,
          username: userObj.username,
          dateOfBirth: userObj.dateOfBirth,
          showAge: userObj.showAge,
          nationality: userObj.nationality,
          bio: userObj.bio,
          games: userObj.addedGames,
          discord: userObj.discord,
          twitch: userObj.twitch,
          instagram: userObj.instagram,
          youtube: userObj.youtube,
          twitter: userObj.twitter,
          displayImage: null,
          bannerImage: null,
          displayImageUrl: userObj.displayImage,
          bannerImageUrl: userObj.bannerImage,
          originalDisplayImageUrl:  userObj.displayImage,
          originalBannerImageUrl: userObj.bannerImage,
        };
        setProfileInfo(loadedInfo);
        setUpdateMode(true);
      } else {
        const loadedInfo = {
          displayName: userObj.username,
          username: userObj.username,
          originalDisplayImageUrl: userObj.displayImage ?? '',
          originalBannerImageUrl: userObj.bannerImage
        }
        setProfileInfo({
          ...defaultProfileInfo,
          ...loadedInfo
        });
        setUpdateMode(false);
      }
      setInitialUserLoaded(true);
    }
  }, [userObj, initialUserLoaded]);

  useEffect(() => {
    loadProfileInfo();
  }, [userObj, loadProfileInfo]);

  const reloadInfo = () => {
    loadProfileInfo({manual: true});
  }

  const openCompleteProfile = () => {
    navigate('/my-profile');
    setCurrentModalStep(0);
  }

  const openEditProfile = () => {
    navigate('/my-profile');
    setCurrentModalStep(0);
  }

  const updateProfile = async () => {
    if (userObj) {
      const userRef = doc(firestore, 'users', userObj.uid).withConverter(userConverter);

      let displayImageUrl = '';
      let bannerImageUrl = '';

      if (profileInfo.displayImage) {
        const displayImageResized = await resizeImage(profileInfo.displayImage, {width: 500});
        if (displayImageResized) {
          const displayImageStorageRef = ref(storage, `users/${userObj.uid}/displayImage.webp`);
          await uploadBytes(displayImageStorageRef, displayImageResized).then(async (snapshot) => {
            displayImageUrl = await getDownloadURL(snapshot.ref);
          });
        }
      }

      if (profileInfo.bannerImage) {
        const bannerImageResized = await resizeImage(profileInfo.bannerImage, {width: 1000});
        if (bannerImageResized) {
          const bannerImageStorageRef = ref(storage, `users/${userObj.uid}/bannerImage.webp`);
          await uploadBytes(bannerImageStorageRef, bannerImageResized).then(async (snapshot) => {
            bannerImageUrl = await getDownloadURL(snapshot.ref);
          });
        }
      }

      const updatePromise = updateDoc(userRef, {
        profileComplete: true,
        displayName: profileInfo.displayName,
        addedGames: profileInfo.games,
        dateOfBirth: profileInfo.dateOfBirth ?? new Date(),
        showAge: profileInfo.showAge,
        nationality: profileInfo.nationality,
        bio: profileInfo.bio,
        discord: profileInfo.discord,
        twitch: formatLink(profileInfo.twitch),
        instagram: formatLink(profileInfo.instagram),
        youtube: formatLink(profileInfo.youtube),
        twitter: formatLink(profileInfo.twitter),
        displayImage: displayImageUrl && displayImageUrl !== '' ? displayImageUrl : profileInfo.originalDisplayImageUrl,
        bannerImage: bannerImageUrl && bannerImageUrl !== '' ? bannerImageUrl: profileInfo.originalBannerImageUrl,
      })

      toast.promise(updatePromise, {
        pending: 'Updating profile',
        success: 'Your profile has been updated',
        error: 'Error updating profile'
      });

      await updatePromise;
      setCurrentModalStep(-1);
      loadProfileInfo();
    }
  }

  const completeProfile = async () => {
    if (userObj) {
      const userRef = doc(firestore, 'users', userObj.uid).withConverter(userConverter);

      let displayImageUrl = '';
      let bannerImageUrl = '';

      if (profileInfo.displayImage) {
        const displayImageResized = await resizeImage(profileInfo.displayImage, {width: 500});
        if (displayImageResized) {
          const displayImageStorageRef = ref(storage, `users/${userObj.uid}/displayImage.webp`);
          await uploadBytes(displayImageStorageRef, displayImageResized).then(async (snapshot) => {
            displayImageUrl = await getDownloadURL(snapshot.ref);
          });
        }
      }

      if (profileInfo.bannerImage) {
        const bannerImageResized = await resizeImage(profileInfo.bannerImage, {width: 1000});
        if (bannerImageResized) {
          const bannerImageStorageRef = ref(storage, `users/${userObj.uid}/bannerImage.webp`);
          await uploadBytes(bannerImageStorageRef, bannerImageResized).then(async (snapshot) => {
            bannerImageUrl = await getDownloadURL(snapshot.ref);
          });
        }
      }

      const updatePromise = updateDoc(userRef, {
        profileComplete: true,
        displayName: profileInfo.displayName,
        addedGames: profileInfo.games,
        dateOfBirth: profileInfo.dateOfBirth ?? new Date(),
        showAge: profileInfo.showAge,
        nationality: profileInfo.nationality,
        displayImage: displayImageUrl && displayImageUrl !== '' ? displayImageUrl : profileInfo.originalDisplayImageUrl,
        bannerImage: bannerImageUrl && bannerImageUrl !== '' ? bannerImageUrl: profileInfo.originalBannerImageUrl,
      })

      toast.promise(updatePromise, {
        pending: 'Completing profile',
        success: 'Your profile has been completed',
        error: 'Error completing profile'
      });

      await updatePromise;
      setCurrentModalStep(-1);
      loadProfileInfo();
    }
  }

  useEffect(() => {
    if (userObj && !userObj.profileComplete && location.pathname === '/my-profile') {
      setCurrentModalStep(ProfileCompletionStep.main);
    }
  }, [location.pathname]);

  const contextValue = {
    profileInfo,
    setProfileInfo,
    currentModalStep,
    setCurrentModalStep,
    completeProfile,
    updateProfile,
    openCompleteProfile,
    openEditProfile,
    updateMode,
    reloadInfo
  };

  return (
    <ProfileInfoContext.Provider value={contextValue}>
      {children}
    </ProfileInfoContext.Provider>
  );
}

export default ProfileInfoProvider;
