import { Link } from "react-router-dom";
import { NewsDetailsList } from "../common/content/News";
import { BottomGreenIcon, GreenWatchIcon } from "../common/icons/Common";

interface TreadingCard {
  title: string;
  description: string;
  label: string;
  date: string;
  img: string;
}

const RelatedArticles = () => {
  return (
    <div>
      {/* Container for related articles */}
      <div className="border-t border-lightGray mb-10 sm:mb-20">
        {/* Heading for related articles */}
        <p className="text-2xl font-bold text-start sm:text-center text-white font-wide pt-10 pb-6 sm:py-10 uppercase">
          Related articles
        </p>
        {/* Mapping over related articles and rendering news cards */}
        {NewsDetailsList.slice(0, 1).map((value, index) => (
          <div
            key={index}
            className={`flex flex-col sm:flex-row overflow-x-auto sm:max-w-[55.5rem] creators_scrollbar gap-4 sm:gap-6 ${index === 0 ? "" : "mt-6"
              }`}
          >
            {/* Mapping over trending news and rendering news cards */}
            {value.treadingList
              .slice(0, 4)
              .map((value: TreadingCard, index: number) => {
                // Creating a string without spaces for the URL
                const stringWithoutSpaces = value.title
                  .toLowerCase()
                  .replace(/\s+/g, "-");
                return (
                  <Link
                    to={`/news/${stringWithoutSpaces}`}
                    className="bg-lightBlack cursor-pointer min-w-[17.732rem] rounded-lg overflow-hidden news_card_parent flex sm:flex-col p-3 sm:p-0 hover:bg-ebonyClay duration-300 min-h-[9.25rem] sm:min-h-[23.125rem] md:min-h-[24.375rem] xl:min-h-[24.063rem] group"
                    key={index}
                  >
                    {/* Overlay and image for news card */}
                    <span className="relative">
                      <span className="absolute bg-[#ffffff1b] top-0 left-0 w-full h-full z-10 opacity-0 news_card_img_overlay duration-300"></span>
                      <img
                        className="max-w-[9.5rem] sm:max-w-full sm:w-full object-cover rounded h-[9rem] md:h-[10.75rem]"
                        src={value.img}
                        alt="cover-img"
                      />
                    </span>
                    {/* Content of the news card */}
                    <div className="sm:p-4 lg:p-6 relative ps-3 sm:min-h-[13rem] lg:min-h-[14rem] xl:min-h-[15rem] sm:flex flex-col justify-between">
                      <span className="absolute end-0 group-hover:end-2 bottom-0 group-hover:bottom-2 scale-0 duration-300 group-hover:scale-100">
                        <BottomGreenIcon />
                      </span>
                      {/* Label for the news card */}
                      <label className="sm:absolute font-normal text-sm font-compact text-white pb-[0.313rem] pt-[0.438rem] px-[0.813rem] bg-lightGray rounded-[3rem] -top-[1rem] left-[1.5rem] z-[10]">
                        {value.label}
                      </label>
                      <div>
                        {/* Title of the news card */}
                        <p className="pt-3 sm:pt-2 mb-3 text-white font-bold text-sm sm:text-base uppercase font-wide !leading-5 line-clamp-4 news_card_title">
                          {value.title}
                        </p>
                        {/* Paragraph of the news card */}
                        <p className="text-darkGray font-normal text-base font-compact mb-3 leading-6 hidden sm:line-clamp-2">
                          {value.description}
                        </p>
                      </div>
                      {/* Details and date of the news card */}
                      <div className="flex items-center gap-2">
                        <GreenWatchIcon />
                        <span className="text-green pt-1 font-normal text-sm font-compact leading-4">
                          {value.date}
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedArticles;
