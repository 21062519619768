import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
// context
import { useTournamentsContext } from "../TournamentsProvider";
// types
import { tournamentRegionToString, Tournament } from '@src/firestore/tournaments';
// utils
import { gameToString } from '@src/types/Games/Games';
import { debounce } from '@utils/Debounce';
// components
import TournamentCard from "./TournamentCard";
import SkeletonTournamentCard from './SkeletonTournamentCard';
import SearchSortFilterBarTournaments from '@components/global/SearchSortFilterBarTournaments';
// icons
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from "@icons/Games";
import { ImSpinner8 } from 'react-icons/im';
import { VscDebugDisconnect } from 'react-icons/vsc';

const regionDropdownOptions = [
  { value: 0, label: "All Regions" },
  { value: 1, label: "NA" },
  { value: 2, label: "EMEA" },
  { value: 3, label: "APAC" },
  { value: 4, label: "LATAM" }
];

const sortingDropdownOptions = [
  { value: 0, label: 'Up Next'},
  { value: 1, label: 'Prize: High-Low'},
  { value: 2, label: 'Name: A-Z'},
];

const selectableGames = [
  {
    value: 0,
    disabled: false,
    title: 'Apex Legends',
    icon: <ApexLegendsIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 1,
    disabled: true,
    title: 'Valorant',
    icon: <ValorantIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 2,
    disabled: true,
    title: 'Fortnite',
    icon: <FortniteIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 3,
    disabled: true,
    title: 'Rocket League',
    icon: <RocketLeagueIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  }
];



const MainList = () => {
  const { tournaments, tournamentsLoaded } = useTournamentsContext();
  const [loading, setLoading] = useState<boolean>(true);

  const [tournamentSearchQuery, setTournamentSearchQuery] = useState<string>('');
  const tournamentSearchQueryRef = useRef('');
  const [searchQueued, setSearchQueued] = useState<boolean>(false);

  const [currentSortingDropdownOption, setCurrentSortingDropdownOption] = useState<number>(0);
  const [currentRegionDropdownOption, setCurrentRegionDropdownOption] = useState<number>(0);

  const [selectedGameValues, setSelectedGameValues] = useState<number[]>([0]);

  const [filteredTournaments, setFilteredTournaments] = useState<Tournament[]>([]);

  const processTournamentSorting = (tournaments: Tournament[], sortingOption: number) => {
    const localTournaments = [...tournaments];
    switch (sortingOption) {
      case 0: // Up Next
        localTournaments.sort((a, b) => {
          return a.statusDates.confirmation.getTime() - b.statusDates.confirmation.getTime();
        })
        break;
      case 1: // Prize: High - Low
        localTournaments.sort((a, b) => {
          const prizeA = a.prizePool.prizes.reduce(
            (acc, prize) => acc + prize.prizeAmount,
            0
          );
          const prizeB = b.prizePool.prizes.reduce(
            (acc, prize) => acc + prize.prizeAmount,
            0
          );
          return prizeB - prizeA;
        });
        break;
      case 2: // Name: A - Z
        localTournaments.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }
    return localTournaments;
  }

  const handleToggleGameSelection = (gameNumber: number) => {
    const localSelectedGameValues = [...selectedGameValues];
    if (localSelectedGameValues.includes(gameNumber)) {
      const selectionIndex = localSelectedGameValues.indexOf(gameNumber);
      localSelectedGameValues.splice(selectionIndex, 1);
    } else {
      localSelectedGameValues.push(gameNumber);
    }
    setSelectedGameValues(localSelectedGameValues);
  }

  const filterTournaments = useCallback(async () => {
    setSearchQueued(false);
    setLoading(true);
    let localFilteredTournaments: Tournament[] = [];
    // ------ Filter ------
    // apply search query filter
    const searchQuery = tournamentSearchQueryRef.current;
    if (searchQuery !== '') {
      localFilteredTournaments = tournaments.filter((tournament) => tournament.name.toLowerCase().includes(searchQuery.toLowerCase()));
      setFilteredTournaments(localFilteredTournaments);
    } else {
      localFilteredTournaments = [...tournaments];
    }
    // apply simple local filters
    const selectedGameTitles = selectableGames.filter((selectableGame) => selectedGameValues.includes(selectableGame.value)).map((selectedGame) => selectedGame.title);
    localFilteredTournaments = localFilteredTournaments.filter((tournament) => selectedGameTitles.some((gameTitle) => gameTitle === gameToString(tournament.game)));

    const selectedRegion = currentRegionDropdownOption;
    if (selectedRegion !== 0) {
      const regionName = regionDropdownOptions.find((regionOption) => regionOption.value === selectedRegion)!.label;
      localFilteredTournaments = localFilteredTournaments.filter((tournament) => tournamentRegionToString(tournament.region) === regionName);
    }

    // ------ Sort ------
    localFilteredTournaments = processTournamentSorting(localFilteredTournaments, currentSortingDropdownOption);
    setFilteredTournaments(localFilteredTournaments);
    setLoading(false);
  }, [tournaments, selectedGameValues, currentRegionDropdownOption, currentSortingDropdownOption]);

  const debouncedFilterTournaments = useMemo(() => debounce(filterTournaments, 500), [filterTournaments]);

  useEffect(() => {
    tournamentSearchQueryRef.current = tournamentSearchQuery;
    if (tournamentSearchQueryRef.current) {
      setSearchQueued(true);
      debouncedFilterTournaments();
    } else {
      filterTournaments();
    }
  }, [tournaments, tournamentSearchQuery]);

  useEffect(() => {
    filterTournaments();
  }, [selectedGameValues, currentRegionDropdownOption, currentSortingDropdownOption]);


  const gameCheckBoxSubtextValues = {
    'Apex Legends': `${tournaments.filter((tournament) => gameToString(tournament.game) === 'Apex Legends' && !tournament.completed).length} tournaments`,
    'Valorant': `0 tournaments`,
    'Fortnite': `0 tournaments`,
    'Rocket League': `0 tournaments`
  }

  return (
    <div className='overflow-hidden px-3 sm:px-5 lg:px-12 custom_container pt-[calc(3rem-4px)]'>
      <SearchSortFilterBarTournaments
        listTitle="tournaments"
        searchQuery={tournamentSearchQuery}
        setSearchQuery={setTournamentSearchQuery}
        selectedRegionOption={currentRegionDropdownOption}
        setSelectedRegionOption={setCurrentRegionDropdownOption}
        selectedGameValues={selectedGameValues}
        gameCheckBoxSubtextValues={gameCheckBoxSubtextValues}
        handleToggleGameSelection={handleToggleGameSelection}
        selectedSortingOption={currentSortingDropdownOption}
        setSelectedSortingOption={setCurrentSortingDropdownOption}
        sortingOptions={sortingDropdownOptions}
        initiallyLoaded={tournamentsLoaded}
        searchBarPlaceholder="Search for a tournament"
      />
      {/* <div className="mx-3 pb-6 md:pb-3 sm:pt-6" >
        <FilterLayoutBar title="tournaments" />
      </div> */}
      {!loading && tournamentsLoaded ? (
        <>
          {searchQueued ? (
            <div className="flex flex-col items-center justify-center min-h-[350px]">
              <ImSpinner8 className="w-[60px] h-auto aspect-square text-green animate-spin" />
            </div>
          ) : (
            <>
              {filteredTournaments.length > 0 ? (
                <div className="overflow-auto">
                  <div className="flex justify-start flex-wrap-0 overflow-auto w-full md:overflow-hidden lg:grid lg:grid-cols-3 xl:grid-cols-4 text-white scrollbar_none gap-8 pb-3">
                    {filteredTournaments.map((tournament) => (
                      <TournamentCard
                        key={`main-list-${tournament.id}`}
                        tournament={tournament}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
                // </div>
                <div className="flex flex-col items-center justify-center gap-y-2 min-h-[350px]">
                  <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
                    <VscDebugDisconnect className="text-white text-2xl" />
                  </div>
                  <p className="font-wide text-white text-2xl font-semibold uppercase">
                    No tournaments found
                  </p>
                  <p className="text-steelGray font-compact">
                    Try adjusting your search parameters
                  </p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="overflow-auto">
          <div className="flex overflow-auto w-full md:overflow-hidden md:flex-wrap text-white scrollbar_none pb-3">
            {Array.from({ length: 6 }).map((_i, index) => (
              <SkeletonTournamentCard key={`main-list-${index}`} />
            ))}
          </div>
        </div>
        // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
        // </div>
      )}
    </div>
  );
};

export default MainList;
