// components
import { SkeletonDark, SkeletonLight } from "@src/components/common/skeleton-loader/Skeletons";

const SkeletonPlayerCard = () => {
  return (
    <div className={`px-[0.70rem] md:px-[0.600rem] xl:px-3 md:py-3 w-[90%] min-w-[310px] max-w-[380px] md:w-4/12 lg:w-3/12 h-auto md:aspect-[0.57]`}>
      <SkeletonDark className="h-full w-full rounded-lg overflow-hidden" >
        <SkeletonLight className="w-full h-[30%]"/>
        <div className="p-4 py-6 xl:p-6 flex flex-col justify-between h-[70%]">
          <SkeletonLight className="w-2/5 h-[35px] mt-2 rounded-xl"/>
          <SkeletonLight className="w-4/5 h-[25px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-3/5 h-[25px] rounded-lg"/>
          <SkeletonLight className="w-3/4 h-[20px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-full h-[15px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-full h-[15px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-full h-[50px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-full h-[15px] mt-2 rounded-lg"/>
          <SkeletonLight className="w-full h-[15px] mt-2 rounded-lg"/>
        </div>
      </SkeletonDark>
    </div>
  )
}

export default SkeletonPlayerCard;
