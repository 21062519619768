import { useState, useEffect, useCallback } from 'react';
// icons
import { MagnifyingGlassIcon } from "@src/components/common/icons/Common";
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';

interface ISearchBar {
  loaded?: boolean,
  className?: string,
  placeholder?: string,
  value: string,
  setValue: (newValue: string) => void,
}

const SearchBar: React.FC<ISearchBar> = ({value, setValue, placeholder, loaded, className}) => {
  const [searchBarActive, setSearchBarActive] = useState<boolean>(false);
  const id = `id-${Math.random().toString(16).slice(2)}`;

  const checkForOutsideInputClick: EventListener = useCallback((e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains(`.${id}`) && !target.closest(`.${id}`)) {
        setSearchBarActive(false);
      }
    }
  }, [id]);

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [checkForOutsideInputClick])

  return loaded !== false ? (
    <div className={`${className} ${id} relative bg-lightBlack rounded-xl overflow-hidden border-2 ${searchBarActive ? 'border-green' : 'border-transparent'}`}>
      <MagnifyingGlassIcon className={`absolute left-3 top-1/2 -translate-y-1/2 w-[16px] h-auto aspect-square stroke-green`}/>
      <input type="text"
              value={value}
              onClick={() => setSearchBarActive(true)}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder ? placeholder : ''}
              className={`w-full p-2 pl-12 bg-transparent outline-none font-compact font-thin
                        ${value ? 'text-white' : ''}
                        placeholder:font-compact placeholder:font-thin placeholder:text-steelGray`}/>
    </div>
  ) : (
    <SkeletonDark className={`${className} ${id} relative h-fit rounded-xl`}>
      <input type="text" className='opacity-0 pointer-events-none p-2  outline-none font-compact font-thin'/>
    </SkeletonDark>
  );
}

export default SearchBar;
