// firebase
import { firestore } from "../firebase";
import { doc, collection, setDoc } from "firebase/firestore";
import { enquiryConverter } from "../firestore/enquiries";

export const sendEnquiry = async (enquiry: {name: string, email: string, message: string, context: string}) => {
  const { name, email, message , context} = enquiry;
  if (/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
    try {
      const enquiryRef = doc(collection(firestore, 'enquiries')).withConverter(enquiryConverter);
      const createPromise = setDoc(enquiryRef, {
        id: enquiryRef.id,
        name: name,
        email: email,
        message: message,
        context: context
      })
      await createPromise;
    } catch (err) {
      throw new Error(err as string);
    }
  } else {
    throw new Error('Invalid email provided');
  }
}
