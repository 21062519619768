import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// libraries
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
// components
import Modal from "@src/components/ui/Modal";
// icons
import { BlackArrow, MouseClickIcon } from '@icons/Common';
import { formatDateTime, getTimezone } from '@src/utils/Date';
import { SwordsIcon } from '@src/components/common/icons/TournamentIcons';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const DateStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [stepValid, setStepValid] = useState<boolean>(true);

  useEffect(() => {
    // TODO
    const statusDates = tournamentInfo.statusDates;
    if (statusDates.confirmation && statusDates.ongoing && statusDates.results && tournamentInfo.dateChosen) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo]);

  const handleSetOngoingDate = (date: Date | null) => {
    if (date) {
      const ongoingDate = date;
      const checkInDate = new Date(ongoingDate.getTime() - 7_200_000);
      const resultsDate = new Date(ongoingDate.getTime() + 14_400_000);
      setTournamentInfo({
        ...tournamentInfo,
        dateChosen: true,
        statusDates: {
          confirmation: checkInDate,
          ongoing: ongoingDate,
          results: resultsDate
        }
      });
    } else {
      setTournamentInfo({
        ...tournamentInfo,
        statusDates: {
          confirmation: new Date(),
          ongoing: new Date(new Date().getTime() + 7_200_000),
          results: new Date(new Date().getTime() + 14_400_000)
        }
      });
    }
  }

  return (
    <Modal title="Date"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={8}
           totalSteps={10}
           open={currentStep === CreationFlowPage.date}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.comms)}
           buttonOnClick={() => changeStep(CreationFlowPage.savePreset)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start">
        <div className='w-full flex flex-col gap-y-1'
             onClick={(e) => e.stopPropagation()}>
          <p className='text-steelGray font-compact text-sm text-left'>Choose Tournament Start Time</p>
          <div className="w-full h-fit">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker value={tournamentInfo.statusDates.ongoing}
                              onChange={(newDate: Date | null) => handleSetOngoingDate(newDate)}
                              orientation="landscape"
              format="dd/MM/yyyy hh:mm" />
            </LocalizationProvider>
          </div>
        </div>

        {tournamentInfo.dateChosen ? (
          <div className='flex flex-col gap-y-2 w-full text-start'>
            <p className='text-steelGray font-compact text-sm text-left'>Dates Chosen:</p>

            <div className='text-white font-compact text-sm bg-ebonyClay p-2 rounded
                              flex items-center gap-x-3'>
              <MouseClickIcon className='w-[12px] h-auto aspect-square fill-white'/>
              <span className='w-[60px]'>Check-In: </span>
              <em className='not-italic text-green'>{formatDateTime(tournamentInfo.statusDates.confirmation)} {getTimezone()}</em>
            </div>

            <div className='text-white font-compact text-sm bg-ebonyClay p-2 rounded
                              flex items-center gap-x-3'>
              <SwordsIcon className='w-[12px] h-auto aspect-square fill-white'/>
              <span className='w-[60px]'>Start Time: </span>
              <em className='not-italic text-green'>{formatDateTime(tournamentInfo.statusDates.ongoing)} {getTimezone()}</em>
            </div>
          </div>
        ) : ''}
      </div>
    </Modal>
  )
}

export default DateStep;
