import { ReactNode, useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
// types
import { TournamentStatus, TournamentTeam } from '@src/firestore/tournaments';
import { TournamentSubViews } from '@components/tournament/Tournament';
// context
import { useTournamentContext, TournamentStage } from '@components/tournament/TournamentProvider';
import { useLeaderboardContext } from "@components/tournament/LeaderboardProvider";
import { useAuthContext } from '@provider/AuthContextProvider';
// utils
import { positionNumberToString } from "@src/firestore/tournamentPointsSystems";
// libaries
import confetti from 'canvas-confetti';
// components
import MiniTeamCard from "./MiniTeamCard";
// icons
import { TrophyIcon } from "@icons/Common";
import { TwitchIcon, YouTubeIcon } from "@icons/Socials";
import { DiscordSocialIcon } from "@icons/TournamentIcons";
import PopoverText from "@src/components/ui/PopoverText";

interface IResultsCompletedViews {
  setCurrentSubView: (subView: TournamentSubViews) => void,
}

const ResultsCompletedViews: React.FC<IResultsCompletedViews> = ({setCurrentSubView}) => {
  const { userTeam } = useAuthContext();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const {
    tournament,
    tournamentStages,
    tournamentTeams,
    userTournamentState,
  } = useTournamentContext();

  const {
    leaderboardGroups
  } = useLeaderboardContext();

  const [tournamentStage, setTournamentStage] = useState<TournamentStage | undefined>(undefined);

  const [userTournamentTeam, setUserTournamentTeam] = useState<TournamentTeam | undefined>(undefined);

  const [finalPlacementInTournament, setFinalPlacementInTournament] = useState<number>(0);

  useEffect(() => {
    if (tournament) {
      setTournamentStage(tournamentStages.find((stage) => stage.stageNum === tournament.activeStage));
    }
  }, [tournament, tournamentStages]);

  useEffect(() => {
    if (userTeam) {
      setUserTournamentTeam(tournamentTeams.find((tournamentTeam) => tournamentTeam.id === userTeam.id));
    }
  }, [userTeam, tournamentTeams])

  useEffect(() => {
    if (tournament && tournament.status === TournamentStatus.results && tournamentStage && userTournamentTeam) {
      const finalStage = tournamentStage;
      const finalGroupId = finalStage.groups[0].id;
      const finalLeaderboardGroup = leaderboardGroups.find((group) => group.groupId === finalGroupId);
      if (finalLeaderboardGroup) {
        const teamInfo = finalLeaderboardGroup.teams.find((finalTeam) => finalTeam.id === userTournamentTeam.id);
        if (teamInfo) {
          setFinalPlacementInTournament(teamInfo.placement);
        }
      }
    }
  }, [tournament, tournamentStage, userTournamentTeam, leaderboardGroups]);

  const [confettiFunc, setConfettiFunc] = useState<(() => void )| null>(null);
  const [confettiLaunched, setConfettiLaunched] = useState<boolean>(false);

  const launchConfetti = useCallback(() => {
    if (confettiFunc) {
      confettiFunc();
    }
  }, [confettiFunc]);

  useEffect(() => {
    if (confettiFunc && !confettiLaunched) {
      launchConfetti();
      setConfettiLaunched(true);
    }
  }, [confettiFunc, confettiLaunched, launchConfetti]);

  useEffect(() => {
    if (!confettiFunc && userTournamentState.confirmed && finalPlacementInTournament !== 0 && finalPlacementInTournament < 4 && canvasRef.current) {
      const canvas = canvasRef.current;

      const confettiBase = confetti.create(canvas, {
        resize: true,
      });

      let prizeColorHex: string = '';
      switch (finalPlacementInTournament) {
        case 1:
          prizeColorHex = '#D9A746';
          break;
        case 2:
          prizeColorHex = '#6E7681';
          break;
        case 3:
          prizeColorHex = '#C24D28';
          break;
        default:
          prizeColorHex ='#D4FF27';
          break;
      }

      const customConfetti = () => {
        confettiBase({
          particleCount: 150,
          angle: 270,
          spread: 200,
          gravity: 0.8,
          startVelocity: 55,
          ticks: 240,
          scalar: 0.75,
          origin: {
            x: 0.5,
            y: -0.25
          },
          colors: ['#D4FF27', prizeColorHex, '#FFFF']
        });
      }
      setConfettiFunc(() => customConfetti);
    }
  }, [userTournamentState, finalPlacementInTournament, canvasRef, confettiFunc]);

  // button state
  const [buttonStyle] = useState<string>('');
  const [buttonInfo, setButtonInfo] = useState<ReactNode>('');
  const [buttonInner, setButtonInner] = useState<ReactNode | string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [secondaryButton, setSecondaryButton] = useState<ReactNode>('');
  const [buttonOnClick, setButtonOnClick] = useState<() => void>(() => () => false);
  const [buttonPopoverText, setButtonPopoverText] = useState<string>('');

  const setButtonState = useCallback(() => {
    if (!tournament) return;

    setButtonInfo('');
    setButtonInner('');
    setButtonDisabled(false);
    setSecondaryButton('');
    setButtonPopoverText('');
    setButtonOnClick(() => () => false);

    if (userTournamentState.ignored || userTournamentState.declined || userTournamentState.eliminated) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
    } else if (userTournamentState.confirmed) {
      let placementClass = '';
      switch (finalPlacementInTournament) {
        case 1:
          placementClass = 'text-[#D9A746]';
          break;
        case 2:
          placementClass = 'text-[#6E7681]';
          break;
        case 3:
          placementClass = 'text-[#C24D28]';
          break;
        default:
          placementClass = 'text-green';
          break;
      }
      setButtonInfo(
        <button type='button'
                onClick={() => launchConfetti()}
                className="relative w-full p-2 py-3 rounded-lg border-2 border-ebonyClay
                           flex items-center justify-evenly gap-x-2 font-compact font-medium uppercase text-white
                           hover:opacity-75 transition-opacity">
          <p>Placement: <em className={`not-italic ${placementClass}`}>{positionNumberToString(finalPlacementInTournament)}</em></p>
        </button>
      );
    }
    setSecondaryButton(tournament.vods.activeVod !== 'none' && tournament.vods.activeVod !== '' ? (
      <a className={`text-black h-[40px] w-auto aspect-square rounded-xl flex items-center justify-center
                      transition-all
                      ${tournament.vods.activeVod === 'twitch' ? "bg-purple" : "bg-white"}`}
              href={tournament.vods.activeVod === 'twitch' ? tournament.vods.twitch : tournament.vods.youtube}
              target="_blank">
        {tournament.vods.activeVod === 'twitch' ? (
          <TwitchIcon className="w-[20px] h-auto aspect-square fill-white"/>
        ) : (
          <YouTubeIcon className="w-[20px] h-auto aspect-square fill-red"/>
        )}
      </a>
    ) : '');

    setButtonInner(
      <>
      <span className="-mb-1">
          View Results
        </span>
        <span>
          <TrophyIcon className="w-[16px] h-auto aspect-square fill-black"/>
        </span>
      </>
    );

    setButtonOnClick(() => () => setCurrentSubView(TournamentSubViews.leaderboards));
  }, [finalPlacementInTournament, setCurrentSubView, tournament, userTeam, userTournamentState, launchConfetti]);

  useEffect(() => {
    setButtonState();
  }, [tournament, userTournamentState, setButtonState]);

  return (
    <>
      {userTournamentState.confirmed && finalPlacementInTournament < 4 ? (
        <canvas ref={canvasRef}
                className="fixed top-0 left-0 w-full h-full max-h-[100vh] z-[10] pointer-events-none">

        </canvas>
      ) : ''}
      <div className='w-1/2 h-full flex-grow flex flex-col items-center gap-y-8 justify-end'>
        {buttonInfo}
        <div className="w-full flex items-center gap-x-2">
          <Link to={tournament && tournament.discordSupport ? tournament.discordSupport : "https://discord.gg/versus-gg"}
            target="_blank"
            className="xl:hidden p-2 bg-lightGray rounded-xl h-[40px] w-auto aspect-square flex items-center justify-center">
            <DiscordSocialIcon />
          </Link>
          <div className="relative group w-5/6 flex-grow">
            {buttonPopoverText ? (
              <PopoverText right={true} visible={true} className="opacity-0 group-hover:opacity-100">
                {buttonPopoverText}
              </PopoverText>
            ) : ''}
            <button className={`font-compact font-semibold text-base !leading-4 text-black uppercase w-full h-[40px]
                            flex gap-2 items-center justify-center py-[0.8125rem] bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green opacity rounded-xl transition-opacity
                            ${buttonStyle}`}
              disabled={buttonDisabled}
              onClick={buttonOnClick}>
              {buttonInner}
            </button>
          </div>
          {secondaryButton}
        </div>
      </div>
    </>
  );

}

export default ResultsCompletedViews;
