// types
import { DropDownOption } from "@src/components/ui/DropDown";

export enum Platform {
  pc,
  console,
  crossplay
}

export const platformToString = (platform: Platform): string => {
  switch (platform) {
    case Platform.pc:
      return 'PC'
    case Platform.console:
      return 'Console'
    case Platform.crossplay:
      return 'Crossplay'
    default:
     return '';
  }
};

const platformEnumValues = Object.values(Platform).filter((value) => typeof value === 'number');

export const platformNames = platformEnumValues.map((platformEnum) => platformToString(platformEnum));

export const platformsAsDropDownOptions: DropDownOption[] = platformEnumValues.map((enumValue) => ({
  value: enumValue,
  label: platformToString(enumValue)
}));
