import { useState } from 'react'
// components
import TeamPrizes from './SubViews/TeamPrizes';
import IndividualPrizes from './SubViews/IndividualPrizes';
import { useTournamentContext } from '@src/components/tournament/TournamentProvider';

enum PrizePoolViews {
  teamPrizes,
  individualPrizes
}

const PrizePool = () => {
  const { tournament } = useTournamentContext();

  const [currentView, setCurrentView] = useState<PrizePoolViews>(PrizePoolViews.teamPrizes);

  const checkInStarted = tournament !== null && tournament.status > 0;

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Prize Pool
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView(PrizePoolViews.teamPrizes,)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === PrizePoolViews.teamPrizes ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Team Prizes</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(PrizePoolViews.individualPrizes,)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === PrizePoolViews.individualPrizes ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Individual Prizes</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-1/2'>
          {currentView === PrizePoolViews.teamPrizes ? (
            <TeamPrizes checkInStarted={checkInStarted}/>
          ) : ''}
          {currentView === PrizePoolViews.individualPrizes ? (
            <IndividualPrizes checkInStarted={checkInStarted}/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default PrizePool;
