import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// components
import Modal from "@ui/Modal";
// icons
import { BlackArrow } from '@icons/Common';
import NumericalInput from '@src/components/ui/NumericalInput';
import CheckBox from '@src/components/ui/CheckBox';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const EntryStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [stepValid, setStepValid] = useState<boolean>(true);

  useEffect(() => {
    // TODO
    if (tournamentInfo.entryFee >= 0 && tournamentInfo.teamSize && tournamentInfo.maxTeamSize && (tournamentInfo.teamSize <= tournamentInfo.maxTeamSize)) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo])

  return (
    <Modal title="Entry"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           scroll={true}
           step={5}
           totalSteps={10}
           open={currentStep === CreationFlowPage.entry}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.format)}
           buttonOnClick={() => changeStep(CreationFlowPage.prize)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start">

        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Entry Fee - <em className='not-italic text-white/70'>(Versus Tokens)</em></p>
          <div className="flex items-center gap-x-3">
            <NumericalInput value={tournamentInfo.entryFee}
                            interval={1}
                            minValue={0}
                            onChange={(newValue) => {
                              setTournamentInfo({
                                ...tournamentInfo,
                                entryFee: newValue
                              })
                            }}
                            black={true}/>
            {tournamentInfo.entryFee === 0 ? (
              <p className='text-green text-sm font-compact translate-y-[2px]'>(Free)</p>
            ) : ''}
          </div>
        </div>


        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Min Team Size</p>
          <NumericalInput value={tournamentInfo.teamSize}
                          interval={1}
                          minValue={1}
                          maxValue={10}
                          onChange={(newValue) => {
                            setTournamentInfo({
                              ...tournamentInfo,
                              teamSize: newValue
                             })
                          }}
                          black={true}/>
        </div>

        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Max Team Size</p>
          <NumericalInput value={tournamentInfo.maxTeamSize}
                          interval={1}
                          minValue={1}
                          maxValue={10}
                          onChange={(newValue) => {
                            setTournamentInfo({
                              ...tournamentInfo,
                              maxTeamSize: newValue
                             })
                          }}
                          black={true}/>
        </div>
        <div className="pointer-events-none flex flex-col gap-y-3 opacity-50">

          <div className="flex flex-col gap-y-2 w-full text-start items-start">
            <p className='text-sm text-steelGray font-compact'>Competed Tournaments</p>
            <div className="flex items-center gap-x-4 w-full">
              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Min</p>
                <NumericalInput value={3}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>

              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Max</p>
                <NumericalInput value={10}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 w-full text-start items-start">
            <p className='text-sm text-steelGray font-compact'>Match Wins</p>
            <div className="flex items-center gap-x-4 w-full">
              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Min</p>
                <NumericalInput value={3}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>

              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Max</p>
                <NumericalInput value={10}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-2 w-full text-start items-start">
            <p className='text-sm text-steelGray font-compact'>Win Rate</p>
            <div className="flex items-center gap-x-4 w-full">
              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Min</p>
                <NumericalInput value={3}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>

              <div className='flex flex-col text-start items-start'>
                <p className='text-xs text-steelGray font-compact'>Max</p>
                <NumericalInput value={10}
                                interval={1}
                                onChange={(newValue) => {
                                  console.log(newValue);
                                }}
                                black={true}/>
              </div>
            </div>
          </div>



          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Entrant Type</p>
            <button onClick={() => setTournamentInfo({
                          ...tournamentInfo,
                          premium: !tournamentInfo.premium
                        })}
                    className={`flex gap-x-2 items-center font-compact uppercase hover:bg-ebonyClay transition-all rounded-lg p-2
                                ${tournamentInfo.premium ? 'bg-ebonyClay' : 'bg-lightGray'}`}>
              <span className={`${tournamentInfo.premium ? 'text-white' : 'text-steelGray'} transition-colors translate-y-[1px]`}>Member Only</span>
              <CheckBox selected={tournamentInfo.premium}
                        setSelected={(newValue) => setTournamentInfo({
                          ...tournamentInfo,
                          premium: newValue
                        })}
                        small={true} asDiv={true} interactive={false}/>
            </button>
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default EntryStep;
