// context
import NoticeText from "@src/components/ui/NoticeText";
import { useTournamentAdminContext } from "../TournamentAdminProvider";

const Ignored = () => {
  const { ignoredTeams } = useTournamentAdminContext();

  return (
    <div>
      <div className="text-white flex flex-col gap-y-2 mt-2">
        {ignoredTeams.length > 0 ? (
          <>
            {ignoredTeams.map((team) => (
              <div key={team.id!} className="flex items-center gap-x-3 bg-lightGray/80 h-[55px] w-2/3 rounded-xl pl-5">
                <img src={team.logoUrl} alt={`${team.teamName} logo`}
                    className="h-3/5 w-auto aspect-square rounded-lg"/>
                <div>
                  <p className="text-white font-compact">
                    {team.teamName}
                  </p>
                  <p className="text-steelGray font-compact">
                    {team.teamTag}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <NoticeText>
            No ignored teams
          </NoticeText>
        )}
      </div>
    </div>
  )
}

export default Ignored;
