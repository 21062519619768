// components
import CreateTournament from './CreateTournament/CreateTournament';
import UnpublishedTournaments from './UnpublishedTournaments';
import PublishedTournaments from './PublishedTournaments';
import CompletedTournaments from './CompletedTournaments';

const Tournaments = () => {

  return (
    <div className="flex flex-col md:flex-row md:items-center gap-4 relative z-1 text-steelGray">
      <UnpublishedTournaments/>
      <PublishedTournaments/>
      <CompletedTournaments/>
      <CreateTournament/>
    </div>
  )
}

export default Tournaments;
