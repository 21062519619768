import { useContext, useState } from 'react';
// context
import { TeamInfoContext } from '../TeamInfoProvider';
// components
import UploadLogo from "./UploadLogo";
// icons
import { CrossIcon } from "../../common/icons/FilterIcons";
import { ImSpinner8 } from "react-icons/im";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const CreateTeamLogo = (props: IProps) => {
  const { updateTeam } = useContext(TeamInfoContext);

  const { closeModal } = props;

  // tracking whether form is submitting to prevent multple submittion attemps (button disabled on submitting)
  const [submitting, setSubmitting] = useState<boolean>(false);


  const handleTeamUpdate = async () => {
    setSubmitting(true); // state to track whether form currently submitting
    const teamUpdated: boolean = await updateTeam();
    if (teamUpdated) {
      closeModal();
    }
    setSubmitting(false);
  }

  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="px-4 py-3 border-b-[0.063rem] border-lightGray">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
              Sponsor Logos
            </h5>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              closeModal();
            }}>
            <CrossIcon />
          </span>
        </div>
      </div>
      <div className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">
        <div className="w-full p-6 ">
          <h2 className="text-steelGray !text-center pb-4 font-compact flex justify-start items-center gap-x-2">
            <span>Sponsors Logos</span>
            <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
          </h2>
          <UploadLogo/>
        </div>
      </div>
      <div className="py-3 px-4 border-t-[0.063rem] border-lightGray">
        <button type="button"
          className={`font-compact text-base font-medium !leading-4 !tracking-[0.01rem] uppercase w-full rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 cursor-pointer opacity-100`}
          onClick={handleTeamUpdate}>
          <span className="mb-[-0.1rem]">Update Logos</span>
          <span className="mb-[0.01rem]">
            {submitting ? (
              // icon spinner
              <ImSpinner8 className="text-lg animate-spin"/>
            ) : ""}
          </span>
        </button>
      </div>
    </div>
  );
};

export default CreateTeamLogo;
