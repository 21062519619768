/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  LeftArrowIcon,
  LeftMobileArrowIcon,
  RightArrowIcon,
} from "../common/icons/NewsDetails";
import { NewsDetailsList } from "../common/content/News";

interface CardObject {
  img: string;
  label: string;
  title: string;
  description: string;
  date: string;
}
const ValorantRumor = () => {
  const params = useParams();
  const [newsList, setNewsList] = useState<CardObject | undefined>(undefined);

  useEffect(() => {
    // Assuming title is unique, directly find the matching object
    const matchingObject = NewsDetailsList
      .flatMap((card) => card.treadingList)
      .find(
        (obj) => obj.title.toLowerCase().replace(/\s+/g, "-") === params.id
      );

    if (matchingObject) {
      setNewsList(matchingObject);
    }
  }, [NewsDetailsList, params]);

  return (
    <>
      <div className="pt-24 sm:pt-[5.375rem]">
        <div className="px-4 md:px-0 md:w-10/12 lg:w-8/12 xl:max-w-[55.5rem] mx-auto sm:px-8 lg:px-12">
          <Link to="/news" className="sm:hidden inline-block">
            <LeftMobileArrowIcon />
          </Link>
          <div className="sm:flex justify-between items-center hidden">
            <Link
              to="/news"
              className="flex items-center duration-300 hover:border-steelGray text-white font-compact font-medium text-base pl-[0.875rem] pr-[1.125rem] pt-[0.5rem] pb-[0.438rem] border-lightGray border rounded-xl"
            >
              <span className="pr-2">
                <LeftArrowIcon />
              </span>
              <span className="-mb-1">Back</span>
            </Link>
            <div className="flex items-center gap-x-2 sm:gap-x-4">
              <div className="flex items-center">
                <Link
                  to="/news"
                  className="text-ebonyClay font-compact font-medium text-sm hover:text-darkGray duration-300"
                >
                  News
                </Link>
                <span className="pl-2 sm:pl-4">
                  <RightArrowIcon />
                </span>
              </div>
              <p className="text-slateGray font-compact font-medium text-sm line-clamp-1 overflow-hidden max-w-[6.25rem] sm:max-w-[18.75rem] md:max-w-[22.188rem]">
                {newsList && newsList.title}
              </p>
            </div>
          </div>

          <div className="pt-6">
            <h2 className="text-white font-wide text-xl md:text-2xl lg:text-3xl font-bold uppercase max-w-[62.5rem]">
              {newsList && newsList.title}
            </h2>
            <div className="pt-4 flex items-center gap-x-3">
              {newsList && newsList.label === undefined ? (
                ""
              ) : (
                <span className="inline-block rounded-[3rem] pt-[0.438rem] pb-[0.375rem] px-[0.813rem] text-white font-normal text-sm  font-compact bg-lightGray">
                  {newsList && newsList.label}
                </span>
              )}

              <p className="text-green font-compact font-normal text-base">
                {newsList && newsList.date}
              </p>
            </div>
            <div className="md:rounded-lg pt-6 pb-10 md:py-10 px-0">
              <img
                className="w-full max-h-[22.625rem] object-cover md:rounded-lg"
                src={newsList && newsList.img}
                alt="rumor-image"
              />
            </div>
            <p className="text-slateGray font-compact font-medium text-base leading-[200%]">
              {newsList && newsList.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValorantRumor;
