const dev = {
  ENV:"dev",
  HOST_URL:"http://127.0.0.1:9001",
  REDIRECT_URL:"http://127.0.0.1:5001/versus-app-73c69/us-central1/api/discord/callback",
  DISCORD_LOGIN_URL:"http://127.0.0.1:5001/versus-app-73c69/us-central1/api/discord/login",
}

const prod = {
  ENV:"prod",
  HOST_URL:"https://versusgaming.gg",
  REDIRECT_URL:"https://us-central1-versus-app-73c69.cloudfunctions.net/api/discord/callback",
  DISCORD_LOGIN_URL:"https://us-central1-versus-app-73c69.cloudfunctions.net/api/discord/login",
}

const appConfig = process.env.NODE_ENV === 'production'
? prod
: dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...appConfig
};
