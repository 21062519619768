import { loginWithDiscord } from "@src/utils/AuthHelpers";
import React from "react";

interface AuthIconsProps {
  url: string;
  Icon: React.ReactNode;
}

export const DiscordLogin: React.FC<AuthIconsProps> = ({ url, Icon }) => {
  return (
    <>
      <button
        onClick={() => loginWithDiscord(url)}
        // target="_blank"
        className="bg-lightGray rounded-lg py-4 w-full flex justify-center group hover:bg-ebonyClay  duration-200"
      >
        {Icon}
      </button>
    </>
  );
};
