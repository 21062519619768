/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import playButton from "../../assets/images/png/news/video-play-Button.png";

// Defining the prop types for the component
interface AuthIconsProps {
  object: any;
  mapKey: number;
  index: number;
}

const VideoModal: React.FC<AuthIconsProps> = ({ object, mapKey, index }) => {
  // State to manage video playback
  const [isPlaying, setIsPlaying] = useState(false);

  // Reference to the video element
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Function to handle video play/pause on button click
  const handlePlay = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Rendering the component
  return (
    <div
      className={` ${
        index === 1 ? "w-full relative" : index === 2 ? "hidden" : "sm:w-6/12"
      }`}
    >
      {/* Conditional rendering based on the type of content (image or video) */}
      {object.type === "image" ? (
        // Displaying an image if the content type is an image
        <img
          className={`${
            mapKey === 0 ? "" : "sm:pl-2 pt-4 sm:pt-0"
          }  w-full h-full max-h-[25rem] object-cover rounded-lg overflow-hidden`}
          src={object.image}
          alt="leaker-img"
        />
      ) : (
        // Displaying a video if the content type is a video
        <div className="relative">
          {/* Video element with controls */}
          <video
            muted
            poster={object.image}
            ref={videoRef}
            controls
            className="w-full max-h-[25rem] h-full"
          >
            <source src={object.videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* Play button overlay */}
          <img
            onClick={handlePlay}
            className={`max-w-[4.375rem] md:max-w-[6.25rem] xl:max-w-[7.5rem] cursor-pointer absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  ${
              isPlaying ? "hidden" : "block"
            }`}
            src={playButton}
            alt="playButton"
          />
        </div>
      )}
    </div>
  );
};

export default VideoModal;
