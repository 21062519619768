import {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
// context
import { useProfileInfoContext } from '@src/components/completeProfile/ProfileInfoProvider';
// types
import { TeamInvite } from '@src/provider/NotificationProvider';
// utils
import { formatTimeElapsed } from '@src/utils/Date';
// icons
import { BlackCheckIcon, WhiteCrossIcon} from "@icons/Header";
import { DBUser } from '@src/firestore/users';
import { ImSpinner8 } from 'react-icons/im';

interface ITeamInviteNotif {
  index: number,
  userObj: DBUser | null,
  invite: TeamInvite,
  acceptTeamInvite: (teamId: string, inviteIndex: number) => Promise<void>,
  declineTeamInvite: (teamId: string, inviteIndex: number) => void
}

const TeamInviteNotif: React.FC<ITeamInviteNotif> = ({index, invite, userObj, acceptTeamInvite, declineTeamInvite}) => {
  const navigate = useNavigate();
  const { setCurrentModalStep } = useProfileInfoContext();

  const [accepting, setAccepting] = useState<boolean>(false);
  const [declining, setDeclining] = useState<boolean>(false);

  const [timeSinceInviteFormatted, setTimeSinceInviteFormatted] = useState<string>('');

  const updateTimeSinceInvite = () => {
    const localTimeSinceInviteFormatted = formatTimeElapsed(invite.timeReceived);
    setTimeSinceInviteFormatted(localTimeSinceInviteFormatted);
  }

  useEffect(() => {
    updateTimeSinceInvite();

    setInterval(() => {
      updateTimeSinceInvite();
    }, 1000)
  }, [])

  return (
    <div className="bg-lightGray rounded-xl p-4">
      <div className="flex items-start">
        <Link to={`/profile/${invite.captainId}`}
              className="w-[30px] h-[30px] rounded-full overflow-hidden border border-ebonyClay
                         hover:opacity-75 transition-opacity">
          <img src={invite.captainDisplayImage} alt={`${invite.captainName} display image`}
               className='w-full h-full object-cover'/>
        </Link>
        <div className="ms-3">
          <div className="mb-1 font-normal text-base leading-6 text-white font-compact">
            <p>{`${invite.captainName} invited you to join `}
              <Link to={`/team/${invite.teamId}`}
                    className='not-italic text-green hover:text-gorse transition-colors'>
                {invite.teamName}
              </Link>
            </p>
            <p className="text-steelGray font-compact font-medium">{timeSinceInviteFormatted} {timeSinceInviteFormatted === 'Just now' ? '' : 'ago'}</p>
          </div>
          {/* <p className="text-darkGray text-sm font-normal font-compact">
            {'time'}
          </p> */}
          {/* <div className='flex items-center font-wide gap-x-2'>
            <Link to={`/team/${invite.teamId}`}
                  className='underline text-white/70'>
              See team
            </Link>
          </div> */}
        </div>
      </div>
      <div className='mt-3'>
        {userObj === null || !userObj.profileComplete ? (
          <button onClick={() => {
                    navigate('/my-profile');
                    setCurrentModalStep(0);
                  }}
                  className="text-red/70 text-sm font-compact my-2 underline block w-full text-center">
            {`You must complete your profile`}
          </button>
        ) : ''}
        <div className="flex items-center justify-between">
          {/* Accept Button */}
          <button
            onClick={async () => {
              setAccepting(true);
              await acceptTeamInvite(invite.teamId, index);
              setAccepting(false);
            }}
            disabled={accepting || userObj === null || !userObj.profileComplete}
            className={`w-[48%] bg-green hover:bg-gorse uppercase font-medium focus_visible_hidden text-base leading-4 px-3 pt-[0.60rem] pb-[0.425rem] rounded-xl font-compact flex items-center gap-2 justify-center
                      disabled:opacity-50 disabled:hover:bg-green transition-all ${declining ? 'pointer-events-none' : ''}`}>
            accept
            <span className="mb-1">
              {accepting ? (
                <ImSpinner8 className='text-black text-lg animate-spin'/>
              ) : (
                <BlackCheckIcon />
              )}
            </span>
          </button>
          {/* Decline Button */}
          <button
            onClick={() => {
              setDeclining(true);
              declineTeamInvite(invite.teamId, index);
            }}
            disabled={declining || userObj === null}
            className={`w-[48%] bg-red hover:opacity-75 uppercase font-medium text-base leading-4 px-3 pt-[0.60rem] pb-[0.425rem] rounded-xl font-compact text-white flex items-center gap-2 justify-center
                      disabled:opacity-50 disabled:hover:bg-opacity-50 transition-all ${accepting ? 'pointer-events-none' : ''}`}>
            decline
            <span className="mb-1">
              {declining ? (
                <ImSpinner8 className='text-white text-lg animate-spin'/>
              ) : (
                <WhiteCrossIcon />
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default TeamInviteNotif
