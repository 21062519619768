import { Link } from "react-router-dom";
// types
import { tournamentRegionToString } from "../../../../firestore/tournaments";
// utils
import { gameToString } from '@src/types/Games/Games';
import { formatDateTime, getTimezone } from "../../../../utils/Date";
// context
import { useTournamentContext } from "../../TournamentProvider";
// icons
import { WhiteRegionIcon } from "../../../common/icons/Common";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from "../../../common/icons/Games";
import { CelenderIcon } from "../../../common/icons/TournamentIcons";
import { DiscordSocialIcon, TeamFeesIcon} from "../../../common/icons/TournamentIcons";

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon';
}

const InfoTop = () => {
  const { tournament } = useTournamentContext();

  const totalPrizePool = tournament ? tournament.prizePool.prizes.reduce((acc, prize) => acc + prize.prizeAmount, 0) : 0;

  return tournament ? (
    <div>
      <div>
        <div className="w-full inline-block ">
          <div className="flex items-center justify-between">
            <div className="w-full flex justify-between">
              <div className='w-full'>
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-2">
                    <div className="flex items-center gap-2 bg-lightGray px-3 py-2 rounded-full">
                      <span className="tournament_details_hero_label_icon">
                        {gameNameToIcon(gameToString(tournament.game))}
                      </span>
                      <h5 className="text-white font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] mb-[-0.188rem]">
                        {gameToString(tournament.game)}
                      </h5>
                    </div>

                    <div className="flex items-center gap-2 bg-lightGray px-3 py-2 rounded-full">
                      <span className="tournament_details_hero_label_icon">
                        <WhiteRegionIcon />
                      </span>
                      <h5 className="text-white font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] mb-[-0.188rem]">
                        {tournamentRegionToString(tournament.region)}
                      </h5>
                    </div>

                  </div>
                  <div className="hidden md:block">
                    <Link to={tournament.discordSupport ? tournament.discordSupport : 'https://discord.gg/versus'}
                          target="_blank"
                          className="hidden xl:block p-2 bg-lightGray rounded-xl tournament_social_desktop_Icon">
                      <DiscordSocialIcon />
                    </Link>
                  </div>
                </div>{" "}
                <h2 className="font-wide max-sm:max-w-[17.25rem] font-bold text-[1.65rem] xl:text-3xl !leading-6 xl:!leading-8 text-white uppercase my-4 xl:my-6 lg:max-w-[23.125rem] xl:min-h-[46px]">
                  {tournament.name}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex sm:hidden items-center justify-center md:justify-normal gap-3 w-full h-fit my-6">
            <p className={`text-lg text-center font-medium tracking-[0.01rem] font-compact !leading-4
                          text-green`}>
              {Math.round(tournament.entrantsCount / tournament.teamCapacity * 100)}%
            </p>
            <div className={`block relative duration-300 group-hover:bg-ebonyClay bg-lightGray h-[6px] w-full rounded-md after:content-[''] after:absolute after:top-0 after:left-0 after:h-[6px] after:bg-green after:rounded-md after:shadow-[0px_0px_24px_4px_yellowGreen] overflow-y-hidden`}>
              <span style={{ width: `${(tournament.entrantsCount / tournament.teamCapacity) * 100}%` }}
                className={`absolute h-full bg-green`}></span>
            </div>
            <p className="text-sm font-normal tracking-[0.009rem] font-compact text-darkGray !leading-4">
              {tournament.entrantsCount}/{tournament.teamCapacity}
            </p>
            </div>
          </div>

          <div className="flex md:hidden items-center gap-4 border border-lightGray justify-center pt-[1.063rem] pb-[0.938rem] px-3 rounded-lg mt-6 md:mt-0">
            <p className="font-compact font-normal text-sm custom_sm:text-base !leading-4 text-steelGray flex gap-2 items-center">
              <span className="-mt-0.5"><CelenderIcon/></span>
              <span className="-m-0.5">{formatDateTime(tournament.statusDates.ongoing)} {getTimezone()}</span>
            </p>
          </div>
        </div>

      <div className="flex items-center justify-center md:justify-between bg-lightGray py-[1.438rem] custom_xl:py-[1.6rem] md:p-4  sm:px-4 xl:px-6 rounded-lg my-4 xl:my-0 xl:gap-3">
        <div className="hidden md:flex flex-col items-center  md:border-e  border-ebonyClay w-full">
          <h2 className="font-compact whitespace-nowrap font-normal text-sm text-white !leading-4 tracking-[0.0088rem] mb-0">
            Entry
          </h2>
          <div className="flex items-center gap-2 mt-2">
            {tournament.entryFee ? (
              <TeamFeesIcon />
            ) : ''}
            <h2 className="font-wide font-medium text-2xl sm:text-base text-green uppercase !leading-4 tracking-[0.0088rem] mt-[0.188rem] whitespace-nowrap">
              {tournament.entryFee ? `${tournament.entryFee} tokens` : 'Free'}
            </h2>
          </div>
        </div>

        <div className="flex max-md:gap-3 md:flex-col max-md:justify-center items-center w-full justify-center gap-x-4">
          <h2 className="font-compact whitespace-nowrap font-normal text-sm text-white !leading-4 tracking-[0.0088rem] -mb-1 md:mb-0">
            Prize Pool
          </h2>
          <div className="flex items-center gap-2 md:mt-2">
            {/* <span className="trophy_icon_tournament_hero">
              <GreenTrophyIcon />
            </span> */}
            <h2 className="font-wide text-green uppercase font-medium text-2xl sm:text-base !leading-4 tracking-[0.0088rem] md:mt-1 mt-1">
              {totalPrizePool > 0 ? `${tournament.prizePool.currency}${totalPrizePool}` : 'N/A'}
            </h2>
          </div>
        </div>
      </div>

    </div>
  ) : '';
};

export default InfoTop;
