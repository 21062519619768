interface resizeImageOptions {
  width: number,
}

export const resizeImage = async (file: File, options: resizeImageOptions): Promise<Blob | null> => {
  const fileType = file.type.split('/')[1]?.toUpperCase();

  if (fileType === 'JPEG' || fileType === 'PNG' || fileType === 'WEBP') {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const width = options.width;
        const height = options.width * (img.height / img.width);

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => { // TODO CHECK IF WE CAN CONTROL FILE SIZE
          resolve(blob);
        }, "image/webp", 0.6); // TODO change type to webp
      };

      img.onerror = function () {
        reject(new Error('Failed to load image.'));
      };

      img.src = URL.createObjectURL(file);
    });
  } else {
    console.error(`Filetype: ${fileType} is not allowed, please check your attachments`);
    return null;
  }
};
