import { Link } from 'react-router-dom';
// assetts
import surfsharkBanner from '@assets/images/partners/surfshark/surfsharkDesktop.png';

const SurfsharkAdDesktop = () => {
  return (
    <Link to="https://surfshark.com/influencer/vs" target="_blank"
          className='rounded-lg !overflow-hidden w-full h-auto aspect-[9.4] hover:opacity-75 transition-opacity'>
      <img src={surfsharkBanner} alt="Surfshark: Enter Coupon VS for extra 4 months"
           className='w-full h-full object-cover rounded-lg'/>
    </Link>
  )
}

export default SurfsharkAdDesktop;
