// Function that returns a debounced version which allows for multiple calls
//... and resets of queues to ensure only one request for request bursts (commonly typing triggered invokations)

export const debounce = (func: () => void, delay: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return () => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func()
    }, delay);
  }
};
