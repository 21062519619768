import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// context provider
import TeamInfoProvider from "./TeamInfoProvider";
import { useAuthContext } from "../../provider/AuthContextProvider";
// components
import CreateTeamInvitation from "./creation/CreateTeamInvitation";
import CreateTeamLogo from "./creation/CreateTeamLogo";
import CreateTeamProfile from "./creation/CreateTeamProfile";
import CreateTeamRegion from "./creation/CreateTeamRegion";
import CreateTeamPayment from "./creation/CreateTeamPayment";
import TeamModal from "./creation/TeamModal";
// icons
import { PlusIcon, TeamGroupIcon } from "../common/icons/ProfileIcons";
import { GoBackIcon, NextRouteIcon } from "../common/icons/TournamentIcons";

interface ILocationBar {
  className?: string,
  backLink?: string,
  parentLink: string,
  parentLocation: string,
  currentLocation: string,
}

const LocationBar: React.FC<ILocationBar> = ({className, backLink, parentLink, parentLocation, currentLocation}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (backLink) {
      navigate(backLink);
    } else {
      navigate(-1);
    }
  }

  return (
    <div className={`${className} md:flex justify-between items-center hidden mt-4`}>
      <button type="button"
              onClick={handleBack}
              className="font-compact py-3 ps-3 pe-4 font-medium text-base !leading-4 !text-white gap-2 border rounded-xl border-lightGray flex items-center group hover:opacity-80">
        <span className="duration-300 group-hover:translate-x-[-0.313rem]">
          <GoBackIcon />
        </span>
        <span className="-mb-0.5"> Back</span>
      </button>
      <div className="flex gap-4 items-center">
        <Link
          to={parentLink}
          className="font-compact font-normal text-sm !leading-4 text-ebonyClay duration-200 hover:text-slateGray -mb-0.5"
        >
          {parentLocation}
        </Link>
        <span>
          <NextRouteIcon />
        </span>
        <span className="font-compact font-normal text-sm !leading-4 text-slateGray -mb-0.5">
          {currentLocation}
        </span>
      </div>
    </div>
  );
}



const CreateTeam = () => {
  // STEPS and respective modals:
  // allClose: null, createTeamPopup: 1, createTeamRegion: 2, createTeamLogo (sponsors logo upload): 3,
  const [currentModalStep, setCurrentModalStep] = useState<number | null>(1); // should start at null
  const { user, userObj } = useAuthContext();

  const changeModalStep = (direction: -1 | 1) => {
    if (currentModalStep) {
      let nextModalStep = currentModalStep + direction;
      if (nextModalStep < 1) {
        nextModalStep = 1;
      } else if (nextModalStep > 5) {
        nextModalStep = 5
      }
      setCurrentModalStep(nextModalStep);
    }
  }

  const closeModal = () => {
    setCurrentModalStep(null);
  }

  return (
    <div className="h-screen bg-black flex items-center justify-center flex-col px-4 text-center lg:mb-0">
      <LocationBar className="absolute w-full top-[3rem] left-1/2 -translate-x-1/2 pt-4 px-4 sm:px-8 lg:px-12 custom_container" parentLink="/teams" parentLocation="Teams" currentLocation="Create Team"/>
      <TeamGroupIcon />
      {user && userObj ? (
        <>
          {!userObj.profileComplete ? (
            <div className="flex flex-col gap-y-2 text-sm items-center text-steelGray">
              <h2 className="text-white text-2xl font-bold font-wide mt-6 mb-2 uppercase">Profile not complete</h2>
              <p className="text-sm text-steelGray mb-6 font-compact font-semibold">You must complete your profile before creating / joining a team</p>
              <Link to={`/my-profile`}
                      className="font-compact text-base font-medium py-3.5 ps-6 pe-4 bg-green common_green_btn rounded-xl uppercase cursor-pointer flex items-center gap-2 max-[36rem]:w-full justify-center !tracking-[0.009rem]">
                <span className="-mb-1">Complete Profile</span>
              </Link>
            </div>
            ) : (
            <>
              {userObj.team ? (
                <div className="flex flex-col gap-y-2 text-sm items-center text-steelGray">
                  <h2 className="text-white text-2xl font-bold font-wide mt-6 mb-2 uppercase">You are already in a team</h2>
                  <p className="text-sm text-steelGray mb-6 font-compact font-semibold">You must leave your current team before creating a new one</p>
                  <Link to={`/team/${userObj.team}`}
                          className="font-compact text-base font-medium py-3.5 ps-6 pe-4 bg-green common_green_btn rounded-xl uppercase cursor-pointer flex items-center gap-2 max-[36rem]:w-full justify-center !tracking-[0.009rem]">
                    <span className="-mb-1">Team Page</span>
                  </Link>
                </div>
              ) : (
                <>
                  <h5 className="text-white text-2xl font-bold font-wide mt-6 mb-2 uppercase">
                    No Team
                  </h5>
                  <p className="text-sm text-steelGray mb-6 font-compact">
                    You will be able to view your team on this page once you create or join
                    one.
                  </p>
                  <button type="button"
                          className="font-compact text-base font-medium py-3.5 ps-6 pe-4 bg-green common_green_btn rounded-xl uppercase cursor-pointer flex items-center gap-2 max-[36rem]:w-full justify-center !tracking-[0.009rem]"
                          onClick={() => setCurrentModalStep(1)}>
                    <span className="-mb-1">Create team</span>
                    <PlusIcon />
                  </button>

                  <TeamInfoProvider>
                    <TeamModal
                      closeModal={closeModal}
                      modalOpen={currentModalStep === 1}
                      modalInner={
                        <CreateTeamProfile closeModal={closeModal} changeModalStep={changeModalStep}/>
                      }
                    />

                    <TeamModal
                      closeModal={closeModal}
                      modalOpen={currentModalStep === 2}
                      modalInner={
                        <CreateTeamRegion closeModal={closeModal} changeModalStep={changeModalStep}/>
                      }
                    />

                    <TeamModal
                      closeModal={closeModal}
                      modalOpen={currentModalStep === 3}
                      modalInner={
                        <CreateTeamPayment closeModal={closeModal} changeModalStep={changeModalStep}/>
                      }
                    />

                    <TeamModal
                      closeModal={closeModal}
                      modalOpen={currentModalStep === 4}
                      modalInner={
                        <CreateTeamLogo closeModal={closeModal} changeModalStep={changeModalStep}/>
                      }
                    />

                    <TeamModal
                      closeModal={closeModal}
                      modalOpen={currentModalStep === 5}
                      modalInner={
                        <CreateTeamInvitation closeModal={closeModal} changeModalStep={changeModalStep}/>
                      }
                    />
                  </TeamInfoProvider>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col gap-y-2 text-sm items-center text-steelGray">
          <p>Log in to create a team!</p>
          <button type="button"
                  className="font-compact text-base font-medium py-3.5 ps-6 pe-4 bg-green common_green_btn rounded-xl uppercase cursor-pointer flex items-center gap-2 max-[36rem]:w-full justify-center !tracking-[0.009rem]">
            <span className="-mb-1">Log In</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateTeam;
