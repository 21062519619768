import { useState, useEffect } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useAuthContext } from '@src/provider/AuthContextProvider';
// types
import { CheckInModalStep } from "../CheckInViews";
// components
import Modal from "@src/components/ui/Modal";
import CheckInPlayerSelect from "./StepViews/CheckInPlayerSelect";
import CheckInPaymentSettings from "./StepViews/CheckInPaymentSettings";
import CheckInConfirmation from "./StepViews/CheckInConfirmation";
// icons
import { FaArrowRightLong } from "react-icons/fa6";
import { MouseClickIcon } from "@icons/Common";
import { ImSpinner8 } from "react-icons/im";

interface ICheckInModalFlow {
  confirmPresence: () => void,
  checkInSelectedPlayers: string[],
  setCheckInSelectedPlayers: (players: string[]) => void,
  checkInModalStep: CheckInModalStep | -1,
  setCheckInModalStep: (step: CheckInModalStep | -1) => void
}

const CheckInModalFlow: React.FC<ICheckInModalFlow> = ({
  confirmPresence,
  checkInSelectedPlayers,
  setCheckInSelectedPlayers,
  checkInModalStep,
  setCheckInModalStep}) => {
  const { userTeam } = useAuthContext();

  const [step1Valid, setStep1Valid] = useState<boolean>(false);
  const [step2Valid, setStep2Valid] = useState<boolean>(false);

  const [initialPaypalLinkSet, setInitialPaypalLinkSet] = useState<boolean>(false);
  const [paypalLink, setPaypalLink] = useState<string>('');

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (userTeam && !initialPaypalLinkSet) {
      setPaypalLink(userTeam.paypalLink)
      setInitialPaypalLinkSet(true);
    }
  }, [userTeam, initialPaypalLinkSet]);

  const handleConfirmPresence = async () => {
    if (userTeam) {
      setSubmitting(true);
      if (userTeam.paypalLink !== paypalLink) {
        const teamRef = doc(firestore, 'teams', userTeam.id);
        await updateDoc(teamRef, {
          paypalLink: paypalLink
        })
      }
      setCheckInModalStep(-1);
      confirmPresence();
      setSubmitting(false);
    }
  }

  return (
    <>
      <Modal title="Squad Select"
             scroll={false}
             step={1}
             totalSteps={3}
             buttonDisabled={!step1Valid}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Squad Select</span>
                <FaArrowRightLong className="text-black"/>
              </div>
             )}
             buttonOnClick={() => {
              setCheckInModalStep(CheckInModalStep.paymentSettings)
             }}
             onBackClick={() => {
              setCheckInModalStep(-1);
             }}
             open={checkInModalStep === CheckInModalStep.playerSelect}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInPlayerSelect checkInSelectedPlayers={checkInSelectedPlayers}
                             setCheckInSelectedPlayers={setCheckInSelectedPlayers}
                             setStepValid={setStep1Valid}/>
      </Modal>

      <Modal title="Verify Payment Info"
             scroll={false}
             step={2}
             totalSteps={3}
             buttonDisabled={!step2Valid}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Verify Payment Info</span>
                <FaArrowRightLong className="text-black"/>
              </div>
             )}
             buttonOnClick={() => {
              setCheckInModalStep(CheckInModalStep.confirmation)
             }}
             onBackClick={() => {
              setCheckInModalStep(CheckInModalStep.playerSelect);
             }}
             open={checkInModalStep === CheckInModalStep.paymentSettings}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInPaymentSettings paypalLink={paypalLink} setPaypalLink={setPaypalLink} setStepValid={setStep2Valid}/>
      </Modal>

      <Modal title="Check-In"
             scroll={false}
             step={3}
             totalSteps={3}
             buttonDisabled={submitting}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Check-In</span>
                {submitting ? (
                  <ImSpinner8 className='text-black animate-spin'/>
                ) : (
                  <MouseClickIcon className="w-[12px] h-auto aspect-square fill-black" />
                )}
              </div>
             )}
             buttonOnClick={() => {
              handleConfirmPresence();
             }}
             onBackClick={() => {
              setCheckInModalStep(CheckInModalStep.paymentSettings);
             }}
             open={checkInModalStep === CheckInModalStep.confirmation}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInConfirmation/>
      </Modal>
    </>
  )
}

export default CheckInModalFlow
