import { createContext, useState, useEffect, ReactNode, useContext } from "react";
// firebase
import { firestore } from "../../firebase";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
// types
import { Tournament, tournamentConverter } from "../../firestore/tournaments";

interface ITournamentsContext {
  tournaments: Tournament[], // all tournaments
  completedTournaments: Tournament[],
  featuredTournaments: Tournament[],
  tournamentsLoaded: boolean,
  completedTournamentsLoaded: boolean,
  featuredTournamentsLoaded: boolean,
  initiallyLoaded: boolean,
}

const defaultTournamentsContext = {
  tournaments: [],
  completedTournaments: [],
  featuredTournaments: [],
  tournamentsLoaded: false,
  completedTournamentsLoaded: false,
  featuredTournamentsLoaded: false,
  initiallyLoaded: false,
}

const TournamentsContext = createContext<ITournamentsContext>(defaultTournamentsContext)

export const useTournamentsContext = () => {
  const context = useContext(TournamentsContext);
  return context;
}

interface ITournamentsProvider {
  announceInitiallyLoaded: (loaded: boolean) => void,
  children: ReactNode
}

const TournamentsProvider: React.FC<ITournamentsProvider> = ({ children, announceInitiallyLoaded }) => {
  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);

  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [tournamentsLoaded, setTournamentsLoaded] = useState<boolean>(false);

  const [completedTournaments, setCompletedTournaments] = useState<Tournament[]>([]);
  const [completedTournamentsLoaded, setCompletedTournamentsLoaded] = useState<boolean>(false);


  const [featuredTournaments, setFeaturedTournaments] = useState<Tournament[]>([]);
  const [featuredTournamentsLoaded, setFeaturedTournamentsLoaded] = useState<boolean>(false);

  const getFeaturedTournaments = () => {
    setFeaturedTournamentsLoaded(false);
    const tournamentsCollection = collection(firestore, 'tournaments').withConverter(tournamentConverter);
    const q = query(tournamentsCollection, where('hidden', '==', false), where('completed', '==', false), orderBy("statusDates.confirmation", "asc"), limit(3));
    const unsubscribe = onSnapshot(q, async (snapshots) => {
      // await new Promise((res) => setTimeout(res, 2000));
      const localFeaturedTournaments = snapshots.docs.map((doc) => doc.data()).sort((a,b) => b.entrantsCount- a.entrantsCount);
      setFeaturedTournaments(localFeaturedTournaments);
      setFeaturedTournamentsLoaded(true);
    })

    return unsubscribe;
  }

  const getTournaments = () => {
    setTournamentsLoaded(false);
    const tournamentsCollection = collection(firestore, 'tournaments').withConverter(tournamentConverter);
    const q = query(tournamentsCollection, where('hidden', '==', false), where('completed', '==', false), limit(12));

    const unsubscribe = onSnapshot(q, async (snapshots) => {
      const localTournaments = snapshots.docs.map((doc) => doc.data());
      setTournaments(localTournaments);
      setTournamentsLoaded(true);

      setInitiallyLoaded(true);
      announceInitiallyLoaded(true);
    })

    return unsubscribe;
  }

  const getCompletedTournaments = () => {
    setCompletedTournamentsLoaded(false);

    const tournamentsCollection = collection(firestore, 'tournaments').withConverter(tournamentConverter);
    const q = query(tournamentsCollection, orderBy('statusDates.confirmation', 'desc'), where('completed', '==', true), limit(24));

    const unsubscribe = onSnapshot(q, async (snapshots) => {
      const localCompletedTournaments = snapshots.docs.map((doc) => doc.data());
      setCompletedTournaments(localCompletedTournaments);
      setCompletedTournamentsLoaded(true);
    });

    return unsubscribe;
  }

  useEffect(() => {
    const tournamentsUnsubscribe = getTournaments();
    const completedTournamentsUnsubscribe = getCompletedTournaments();

    return () => [tournamentsUnsubscribe, completedTournamentsUnsubscribe].forEach((unsubscribe) => {
      unsubscribe()
    });
  }, [])

  useEffect(() => {
    const unsubscribe = getFeaturedTournaments();

    return () => unsubscribe();
  }, [])

  const contextvalue = {
    tournaments: tournaments,
    completedTournaments: completedTournaments,
    featuredTournaments: featuredTournaments,
    tournamentsLoaded: tournamentsLoaded,
    completedTournamentsLoaded: completedTournamentsLoaded,
    featuredTournamentsLoaded: featuredTournamentsLoaded,
    initiallyLoaded: initiallyLoaded
  }

  return (
    <TournamentsContext.Provider value={contextvalue}>
      {children}
    </TournamentsContext.Provider>
  )
}

export default TournamentsProvider;
