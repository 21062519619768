export const LeftArrowIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6668 8.00065H4.13081M8.0004 3.33398L3.33374 8.00065L8.0004 12.6673"
        stroke="#4D545E"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.40002 3.33398L11.0667 8.00065L6.40002 12.6673"
        stroke="#1F272E"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LeftMobileArrowIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#151A1F" />
      <path
        d="M24.667 19.9999H16.131M20.0006 15.3333L15.3339 19.9999L20.0006 24.6666"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
