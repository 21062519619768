import { Link } from "react-router-dom";
// types
import { DBUser } from "@src/firestore/users";
// utils
import { getWeaponImage, getLegendHeadshot, getLegendBackground } from '@utils/Games/ApexLegends';
import { getAgeFromDateOfBirth } from '@src/utils/Date';
// packages
import { getName } from 'country-list';
import "/node_modules/flag-icons/css/flag-icons.min.css";
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.png';
// components
import PopoverText from '@ui/PopoverText';
// icons
import { ConsoleIcon, ControllerIcon, MonitorIcon, MouseIcon } from '@src/components/common/icons/Common';
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from '@src/components/common/icons/TournamentIcons';

const platformStringToIcon = (platform: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon className='w-[12px] lg:w-[15px] h-auto aspect-square fill-steelGray'/>
      );
    default:
      return (
        <ConsoleIcon className='w-[20px] h-auto aspect-square fill-steelGray  transition-colors mt-[2px]'/>
      );
  }
}

const gameInputStringToIcon = (inputName: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon className='w-[12px] lg:w-[15px] h-auto aspect-square fill-steelGray'/>
      );
    case 'Controller':
      return (
        <ControllerIcon className='w-[12px] lg:w-[15px] h-auto aspect-square fill-steelGray'/>
      );
    default:
      return '';
  }
}

interface IHeroSlideInner {
  player: DBUser
}

const HeroSlideInner: React.FC<IHeroSlideInner> = ({ player }) => {
  const playerTrophies = player.trophyData;

  const apexGameEntry = player.addedGames.find((game) => game.title === 'Apex Legends');
  const goldTrophies = playerTrophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = playerTrophies.filter((trophy) => trophy.position === 2);
  const bronzeTrophies = playerTrophies.filter((trophy) => trophy.position === 3);

  return (
    <Link to={`/profile/${player.uid}`}
          className="slider_cards_overlay bg-lightBlack rounded-lg overflow-hidden block w-full h-auto md:aspect-[4.2]">
      <div className="flex flex-col-reverse md:flex-row flex-wrap justify-between items-center md:items-center h-full">
        <div className="w-11/12 md:w-[44%] h-full py-8 md:py-0 flex flex-col justify-center lg:w-[32%] bg-lightBlack z-10 relative md:bg-none rounded-lg mt-[-6.25rem] md:mt-0 md:mx-0">
          <div className="relative flex items-center gap-x-10 z-[2] w-fit ml-8">
            <div className="relative z-[2] h-fit w-fit">
              {player.teamLogoUrl ? (
                <div className='absolute top-3 -right-3 -translate-y-1/3 !w-[38px] h-auto !aspect-square rounded-xl overflow-hidden border-[3px] border-lightBlack bg-lightGray'>
                  <img src={player.teamLogoUrl}
                      alt={`${player.displayName} team logo`}
                      className='w-full h-full object-cover'/>
                </div>
              ) : ''}
              <img src={player.displayImage !== '' ? player.displayImage : placeholderImage} alt={`${player.displayName} display image`}
                  className='w-[80px] lg:w-[100px] xl:w-[110px] h-auto aspect-square rounded-full border-[3px] border-lightBlack object-cover'/>
            </div>

            <div className='flex flex-col gap-y-2'>
              <h3 className='font-wide font-semibold uppercase text-white text-2xl'>{player.displayName}</h3>
              <div className='flex items-center gap-x-4'>
                <div className="relative group/nation  w-fit h-fit">
                  <div className={`opacity-75 fi fi-${player.nationality}`}></div>
                  <PopoverText className="group-hover/nation:opacity-100" visible={false}>
                    {getName(player.nationality)}
                  </PopoverText>
                </div>
                {player.showAge ? (
                  <p className="text-steelGray font-compact font-semibold -mb-1 xl:text-lg">{getAgeFromDateOfBirth(player.dateOfBirth)}</p>
                ) : ''}
                {apexGameEntry ? (
                  <>
                    <div className="relative group/platform text-steelGray font-compact font-semibold">
                      <p>{platformStringToIcon(apexGameEntry.platform)}</p>
                      <PopoverText className="group-hover/platform:opacity-100" visible={false}>
                        {apexGameEntry.platform}
                      </PopoverText>
                    </div>
                    <p className="text-steelGray font-compact font-semibold">{gameInputStringToIcon(apexGameEntry.input)}</p>
                  </>
                ) : ''}
              </div>
            </div>
          </div>

          <div className="w-[calc(100%-4rem)] h-[2px] mx-auto bg-lightGray my-4 lg:my-8"></div>

          <div className="flex flex-col gap-y-4 w-fit ml-8">

            <div className='flex items-center'>
              <div className='flex items-center gap-x-1'>

                <div className="relative w-fit h-fit">
                  <div className={`${goldTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{goldTrophies.length}</p>
                  </div>
                  <TrophyGoldImage className={`w-[54px] h-auto aspect-square object-contain ${goldTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

                <div className="relative w-fit h-fit">
                  <div className={`${silverTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{silverTrophies.length}</p>
                  </div>
                  <TrophySilverImage className={`w-[54px] h-auto aspect-square object-contain ${silverTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

                <div className="relative w-fit h-fit">
                  <div className={`${bronzeTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                                  flex items-center justify-center`}>
                    <p className='text-xs mt-[3px] font-compact text-white'>{bronzeTrophies.length}</p>
                  </div>
                  <TrophyBronzeImage className={`w-[54px] h-auto aspect-square object-contain ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}`}/>
                </div>

              </div>
              <div className="h-[35px] w-[2px] bg-lightGray ml-2 mr-4"></div>
              <div className='mt-1 xl:text-lg'>
                <p className="font-wide font-semibold text-white uppercase">Winnings</p>
                <p className="font-wide font-semibold text-green uppercase">{player.totalWinnings > 0 ? `$${Math.round(player.totalWinnings)}` : 'N/A'}</p>
              </div>
            </div>

            {apexGameEntry ? (
              <div className='hidden sm:flex items-center gap-x-2'>
                <img src={getLegendHeadshot(apexGameEntry.mainLegend!)} className='w-[35px] xl:w-[45px] h-auto aspect-square rounded-lg border border-lightGray object-cover'/>
                <img src={getLegendHeadshot(apexGameEntry.altLegend!)} className='w-[35px] xl:w-[45px] h-auto aspect-square rounded-lg border border-lightGray  object-cover'/>
                <img src={getWeaponImage(apexGameEntry.favouriteWeapon!)} className='h-[35px] xl:h-[45px] w-auto max-w-[98px] object-contain -scale-x-100'/>
              </div>
            ) : ''}
          </div>

        </div>
        <div className="h-full md:w-[56%] lg:w-[68%] w-full">
          <img
            className="hidden md:block w-full h-full object-cover object-center xl:object-right"
            src={player.bannerImage !== '' ? player.bannerImage : apexGameEntry ? getLegendBackground(apexGameEntry.mainLegend!) : placeholderImage}
            alt="player banner image"
          />
          <img
            className="md:hidden w-full h-[30rem] object-cover object-top"
            src={player.bannerImage !== '' ? player.bannerImage : apexGameEntry ? getLegendBackground(apexGameEntry.mainLegend!) : placeholderImage}
            alt="player display image"
          />
        </div>
      </div>
    </Link>
  );
}
export default HeroSlideInner;
