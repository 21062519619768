// Import necessary modules from React and third-party libraries
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import FooterAccordion from "../common/FooterAccordion";
import pageLogo from "../../assets/images/png/homepage/page_logo.png";

import {
  FooterLinksCollection,
  SocialFooterIconsCollection,
} from "./FooterHelper";
import { HOME_PAGE_ACTIVE } from "../../utils/Header";
import StayUpdatedForm from "../Footer/StayUpdatedForm";

// Footer component
const Footer = () => {
  // Get the current year
  const today = new Date();
  const year = today.getFullYear();

  // Get the current location using React Router's useLocation hook
  const location = useLocation();

  // Scroll to the specified element when the location changes
  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Render the Footer component
  return (
    <>
      <div
        className={`px-5 max-[420px]:px-4 sm:px-8 lg:px-12 bg-lightBlack md:mt-0  ${
          location.pathname === HOME_PAGE_ACTIVE ? "pb-0" : "pb-[70px] lg:pb-0"
        }`}
      >
        <div className="custom_container">
          <div className="flex flex-col md:flex-row items-center justify-center md:border-b md:border-b-ebonyClay md:py-8 pt-6 ">
            <Link
              to="/"
              onClick={scrollToTop}
              className="md:pr-6 md:border-r border-r-ebonyClay cursor-pointer"
            >
              <img
                className="w-[124px] h-[16px]"
                src={pageLogo}
                alt="footer-page-logo"
              />
            </Link>
            <p className="text-steelGray font-compact font-normal text-sm md:pl-6 mt-6 md:mt-0">
              © {year} Versus All Rights Reserved.
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between md:border-b md:border-b-ebonyClay py-5 lg:py-8 ">
            <div className="lg:w-4/12">
              <StayUpdatedForm/>
              <div className="flex justify-center md:justify-start items-center gap-2 md:gap-x-6 mt-1 md:mt-0 pb-6 md:pb-0 border-b border-b-lightGray md:border-none ">
                {SocialFooterIconsCollection.map((data, index) => (
                  <Link
                    key={index}
                    className="footerIcons duration-300 max-md:p-3 rounded-xl max-md:cursor-pointer max-md:bg-lightGray"
                    to={data.url}
                    target="_blank"
                  >
                    {data.icon}
                  </Link>
                ))}
              </div>
            </div>
            {/* Links section for larger screens */}
            <div className="xl:w-[45%] lg:w-1/2 w-full mt-10 lg:mt-0 md:block hidden">
              <div className="flex flex-wrap  justify-between">
                {FooterLinksCollection.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="max-[500px]:w-[140px] max-[500px]:mt-6"
                    >
                      <h4 className="text-base font-normal font-compact text-white">
                        {data.title}
                      </h4>
                      <div className="flex flex-col gap-3 mt-4">
                        {data.links.map((obj, index) => {
                          return (
                            <span key={index}>
                              {" "}
                              <Link
                                onClick={scrollToTop}
                                className="font-compact font-normal text-sm leading-4 text-steelGray tracking-[0.14px] hover:text-green duration-300 inline-block"
                                to={obj.url}
                              >
                                {obj.links}
                              </Link>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Accordion for smaller screens */}
            <div className="block md:hidden">
              {" "}
              <FooterAccordion />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center text-center pb-6 md:pt-6 lg:py-8 m-auto max-w-[951px]">
          <p className={`text-steelGray font-compact font-normal text-xs`}>
            Versus is neither endorsed nor directly affiliated with Apple Inc,
            Electronic Arts, Activision Blizzard, Take-Two Interactive, Riot
            Games, Microsoft, Xbox, or Epic Games. It does not receive
            maintenance or sponsorship from these companies. All trademarks,
            trade names, game titles, trade dress, artwork, and associated
            imagery belong to their respective owners
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
