import { useEffect, useState } from "react";
// context
import { useTournamentAdminContext } from "../TournamentAdminProvider";
// types
import { TournamentGroup, TournamentGame } from "../../../../firestore/tournaments";
// components
import GameCodes from "./GameCodes";
// icons
import { IoIosCheckmark } from "react-icons/io";
import { useTournamentContext } from "../../TournamentProvider";


interface IGroupCodes {
  group: TournamentGroup, // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'
  stageNum: number,
}

const GroupCodes: React.FC<IGroupCodes> = ({group, stageNum}) => {
  const { tournament } = useTournamentContext();
  const { groupGames } = useTournamentAdminContext();
  const games = groupGames[group.id].sort((a,b) => a.gamePosition - b.gamePosition) ?? [];

  const [currentGame, setCurrentGame] = useState<number>(0);

  const checkIfGameActive = (game: TournamentGame) => {
    return tournament && tournament.status === 3 && tournament.activeStage === stageNum && game.gamePosition === group.activeGame;
  }

  useEffect(() => {
    const index = games.indexOf(games.find((game) => game.gamePosition === group.activeGame) ?? games[games.length-1]);
    setCurrentGame(index);
  }, [group.activeGame])

  return (
    <div>
      <div className='flex items-center gap-x-2 w-full'>
        <div className='flex items-center gap-x-2'>
          {games.map((game, index) => (
            <button key={`game-button-${index}`} type="button"
                    onClick={() => setCurrentGame(index)}
                    className={`relative p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentGame === index ? `bg-lightGray ${checkIfGameActive(game) ? 'text-green' : 'text-white/85'}`
                                  : `hover:bg-lightGray/20 ${checkIfGameActive(game) ? 'text-green/70 hover:text-green' : 'text-steelGray hover:border-steelGray'}`}`}>
              {game.gameName}
              {game.completed ? (
                <IoIosCheckmark className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 text-xl text-green bg-black border border-lightGray rounded-full"/>
              ) : ''}
            </button>
          ))}
        </div>
      </div>
      <div>
        {games.map((game, index) => (
          <div key={`game-view-${index}`}>
          {currentGame === index ? (
            <GameCodes game={game} group={group} gamesInGroup={games}/>
          ) : ''}
          </div>
        ))}
      </div>
    </div>
  )
}

export default GroupCodes;
