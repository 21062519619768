import { useState, useEffect, useCallback} from "react"; // Import React and useState
// firebase
import { User, updatePassword } from "firebase/auth";
// context
import { useAuthContext } from "../../../provider/AuthContextProvider";
// utils
import { isPasswordValid } from "@src/utils/validations/users";
// libaries
import { toast } from 'react-toastify';
// components
import Input from "@ui/Input";

const ChangePassword = () => {
  const { user } = useAuthContext();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValidFormat, setPasswordValidFormat] = useState(true);

  const [passwordFormValid, setPasswordFormValid] = useState(false);

  const handleChangePassword = async () => {
    if (user) {
      try {
        const updatePromise = updatePassword(user as User, password);
        toast.promise(updatePromise, {
          pending: 'Changing password',
          success: 'Password sucessfully changed',
          error: 'Error changing password'
        });
        setPassword("");
        setPasswordConfirmation("");
      } catch (error) {
        console.error("Error updating password:", error);
      }
    }
  }

  const validatePasswordFields = useCallback(() => {
    const localpasswordsMatch = (password === '' || passwordConfirmation === '') || password === passwordConfirmation;
    setPasswordsMatch(localpasswordsMatch);
    const localPasswordValid = password === '' || isPasswordValid(password);
    setPasswordValidFormat(localPasswordValid);
  }, [password, passwordConfirmation]);

  const validatePasswordForm = useCallback(() => {
    setPasswordFormValid(passwordsMatch && (password !== '' && passwordValidFormat));
  }, [password, passwordsMatch, passwordValidFormat]);

  useEffect(() => {
    validatePasswordForm();
  }, [passwordsMatch, passwordValidFormat, validatePasswordForm]);

  useEffect(() => {
    validatePasswordFields();
  }, [password, passwordConfirmation, validatePasswordFields]);

  return (
    <div className="mb-12">
      <h2 className="text-white font-compact font-semibold text-lg mb-2">Change Password</h2>
      <div className="flex flex-col gap-y-4">
        <Input label="New Password"
              value={password}
              onChange={(newValue: string) => setPassword(newValue)}
              placeholder="New password"
              valid={passwordValidFormat}/>
        {!passwordValidFormat ? (
          <div className="flex justify-end mt-2">
            <span className={`text-red/70 font-compact text-sm font-normal leading-4`}>
              Minimum 8 characters and one capital letter
            </span>
          </div>
        ) : ''}

        <Input label="New Password Confirmation"
                value={passwordConfirmation}
                onChange={(newValue: string) => setPasswordConfirmation(newValue)}
                placeholder="Confirm your new password"
                valid={passwordsMatch}/>
        {!passwordsMatch ? (
          <div className="flex justify-end mt-2">
            <span className=" font-compact text-sm font-normal leading-4 text-red">
              New password does not match.
            </span>
          </div>
        ): ''}

        <button
          className="bg-green mt-6 text-black pt-[1.125rem] pb-[0.875rem] px-6 uppercase hover:bg-gorse rounded-xl font-compact text-base font-medium !leading-4
          !tracking-[0.01rem] text-center disabled:opacity-30 "
          disabled={!passwordFormValid}
          onClick={handleChangePassword}>
          Change password
        </button>
      </div>
    </div>
  )
}

export default ChangePassword;
