import { ReactNode, useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
// context
import { useTournamentContext } from "../TournamentProvider";
import { LeaderboardTeam, useLeaderboardContext } from "../LeaderboardProvider";
// types
import { Prize, TournamentStatus } from "@src/firestore/tournaments";
import { DBTeam } from "@src/firestore/teams";
// icons
import { PrizePoolTrophyIcon, WhiteLocationIcon } from "../../common/icons/TournamentIcons";
import { VscDebugDisconnect } from "react-icons/vsc";
import { DiscordIcon, TwitterIcon, TwitchIcon, WebsiteIcon } from '@icons/Socials';
import { MedalIcon } from "@src/components/common/icons/Common";

interface IPrizeCard {
  positionCard: boolean,
  prize: PrizeWithTeam,
  prizeCurrency: string,
}

const PrizeCard: React.FC<IPrizeCard> = ({ positionCard, prize, prizeCurrency}) => {

  let prizeColor;
  switch (prize.position) {
    case 1:
      prizeColor = '#D9A746';
      break;
    case 2:
      prizeColor = '#6E7681';
      break;
    case 3:
      prizeColor = '#C24D28';
      break;
    default:
      prizeColor = '#D4FF27';
      break;
  }

  const PrizeIcon = (): ReactNode => {
    const prizeClassName = `w-[30px] h-auto aspect-square`;
    return prize.position && prize.position < 4 ? (
      <PrizePoolTrophyIcon className={prizeClassName} fill={prizeColor}/>
    ) : (
      <MedalIcon className="w-[30px] h-auto aspect-square fill-green"/>
    );
  }

  return (
    <div className={`bg-lightBlack rounded-lg px-4 pb-4 pt-5 flex gap-4 relative mt-2`}>
      <span className={`absolute left-0 w-1 h-10 rounded-r bg-[${prizeColor}]`}></span>
      <span className={`mt-1`}>{positionCard ? PrizeIcon() : <WhiteLocationIcon fill="#d4ff27" className="!w-[25px]"/>}</span>
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-x-4">
          <div>
            <h2 className="uppercase font-wide font-bold text-2xl text-white !leading-6">
              {prize.prizeName}
            </h2>
            <p className="font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] text-steelGray mt-1 capitalize">
              {prize.prizeDescription}
            </p>
          </div>
          {prize.team ? (
            <div className="flex justify-start gap-x-4 w-full">
              <Link to={`/team/${prize.team.id}`} className='flex items-center gap-x-4 hover:opacity-75 transition-opacity'>
                <div className="h-[40px] w-auto aspect-square rounded-lg overflow-hidden border border-lightGray">
                  <img
                    className="h-full w-full object-cover"
                    src={prize.team.logoUrl}
                    alt="entrants-image"
                  />
                </div>
                <div className="w-fit">
                  <h2 className={`font-wide font-medium uppercase text-sm text-[${prizeColor}] !leading-6 flex items-center gap-4
                                group-hover/card:text-green transition-colors`}>
                    {prize.team.teamName}
                  </h2>
                  <p className="font-compact text-xs text-steelGray">
                    {prize.team.teamTag}
                  </p>
                </div>
              </Link>
              <span className="hidden sm:flex items-center gap-x-2 h-fit mt-[0.35rem]">
                {prize.team.discordLink ? (
                  <Link target="_blank" to={prize.team.discordLink}>
                    <DiscordIcon className='w-[14px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                  </Link>
                ) : ''}
                {prize.team.twitterLink ? (
                  <Link target="_blank" to={prize.team.twitterLink}>
                    <TwitterIcon className='w-[14px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                  </Link>
                ) : ''}
                {prize.team.twitchLink ? (
                  <Link target="_blank" to={prize.team.twitchLink}>
                    <TwitchIcon className='w-[14px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                  </Link>
                ) : ''}
                {prize.team.websiteLink ? (
                  <Link target="_blank" to={prize.team.websiteLink}>
                    <WebsiteIcon className='w-[14px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                  </Link>
                ) : ''}
              </span>
            </div>
          ) : ''}
        </div>
        <span className="font-compact font-bold text-2xl !leading-6 text-white pt-1">
          {prizeCurrency}{prize.prizeAmount}
        </span>
      </div>
    </div>
  );
}

interface PrizeWithTeam extends Prize {
  team: DBTeam | null
}

const PrizePool = () => {
  const { tournament, tournamentTeamObjs } = useTournamentContext();
  const { leaderboardGroups } = useLeaderboardContext();

  const [positionPrizesWithTeam, setPositionPrizesWithTeam] = useState<PrizeWithTeam[]>([]);
  const specialPrizes: PrizeWithTeam[] = tournament?.prizePool.prizes.filter((prize) => prize.special)
                                                     .sort((a, b) => b.prizeAmount - a.prizeAmount)
                                                     .map((prize) => ({...prize, team: null}))
                                                     ?? [];

  const compilePositionPrizesWithTeam = useCallback(() => {
    let localPositionPrizesWithTeam: PrizeWithTeam[] = [];

    if (tournament && tournament.status === TournamentStatus.results) {
      const finalsGroup = leaderboardGroups.find((group) => group.final);

      if (finalsGroup) {
        const wonLastMatch = (team: LeaderboardTeam) => {
          const lastMatch = team.gameResults.sort((a, b) => b.gamePosition - a.gamePosition)[0];

          return lastMatch !== undefined  && lastMatch.result.teamPlacement === 1;
        }

        const finalsTeams = finalsGroup.teams;

        if (tournament.matchPointFormatFinish) {
          const winningTeamIndex = finalsTeams.findIndex((team) => wonLastMatch(team));
          const winningTeamPlacement = finalsTeams[winningTeamIndex].placement;
          if (winningTeamPlacement !== 1) {
            const firstPlaceOnPointsTeamIndex = finalsTeams.findIndex((team) => team.placement === 1);
            finalsTeams[firstPlaceOnPointsTeamIndex].placement = winningTeamPlacement;
            finalsTeams[winningTeamIndex].placement = 1;
          }
        }

        const positionPrizes = tournament?.prizePool.prizes.filter((prize) => !prize.special).sort((a, b) => a.position! - b.position!) ?? [];




        for (const prize of positionPrizes) {
          const associatedTeamId = finalsTeams.find((team) => team.placement === prize.position)?.id ?? '';

          if (associatedTeamId) {
            const associatedTeamObj = tournamentTeamObjs.find((team) => team.id === associatedTeamId);

            const prizeWithTeam: PrizeWithTeam = {
              ...prize,
              team: associatedTeamObj ?? null
            }

            localPositionPrizesWithTeam.push(prizeWithTeam);
          }
        }

      }
    } else {
      localPositionPrizesWithTeam = tournament?.prizePool.prizes.filter((prize) => !prize.special)
                                                                .sort((a, b) => a.position! - b.position!)
                                                                .map((prize) => ({...prize, team: null}))
                                                                ?? [];
    }
    setPositionPrizesWithTeam(localPositionPrizesWithTeam);
  }, [tournament, tournamentTeamObjs, leaderboardGroups]);

  useEffect(() => {
    compilePositionPrizesWithTeam();
  }, [compilePositionPrizesWithTeam]);

  return tournament ? (
    <div className="px-4 sm:px-8 lg:px-12 mt-10">
      <h2 className="font-wide font-bold text-2xl !leading-6 uppercase text-white">
        Prize Pool
      </h2>
      {positionPrizesWithTeam.length + specialPrizes.length > 0 ? (
        <>
          <div className="mt-6">
            {positionPrizesWithTeam ? (
              <>
                {positionPrizesWithTeam.map((prize, index) => (
                  <PrizeCard key={`special-${index}`}
                              positionCard={true}
                              prize={prize}
                              prizeCurrency={tournament.prizePool.currency}/>
                ))}
              </>
            ) : ''}
            {specialPrizes.length > 0 ? (
              <>
                <div className="bg-steelGray/30 h-[1px] w-full my-6"></div>
                {specialPrizes.map((prize, index) => (
                  <PrizeCard key={`special-${index}`}
                             positionCard={false}
                             prize={prize}
                             prizeCurrency={tournament.prizePool.currency}/>
                ))}
              </>
            ) : ''}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center mt-10 gap-y-2">
          <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
            <VscDebugDisconnect className="text-white text-2xl" />
          </div>
          <p className="font-wide text-white text-2xl font-semibold uppercase">
            No prize pool
          </p>
          <p className="text-steelGray font-compact">
            This tournament has no prize pool
          </p>
        </div>
      )}
  </div>
  ) : '';
};

export default PrizePool;
