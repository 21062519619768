// context
import { useTournamentContext } from "@src/components/tournament/TournamentProvider";
import { useTournamentAdminContext } from "../../../TournamentAdminProvider";
// components
import NoticeText from '@src/components/ui/NoticeText';

interface ITournamentEditPage {
  checkInStarted: boolean
}

const IndividualPrizes: React.FC<ITournamentEditPage> = () => {
  const { tournament } = useTournamentContext();
  const { tournamentEditInfo } = useTournamentAdminContext();

  return tournament && tournamentEditInfo ? (
    <div className='text-white font-compact flex flex-col gap-y-3'>
      <NoticeText>
        Coming Soon
      </NoticeText>
    </div>
  ) : '';
}

export default IndividualPrizes;
