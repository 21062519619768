import { useState } from 'react';
// firebase
import { firestore } from "../../../../firebase";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
// context
import { useTournamentContext } from "../../TournamentProvider";
// types
import { ApexPointSystem, positionNumberToString } from "../../../../firestore/tournamentPointsSystems";
// libraries
import { toast } from 'react-toastify';
// assetts
import skullWhite from "../../../../assets/images/tournamentPoints/skullWhite.png";
import damageWhite from "../../../../assets/images/tournamentPoints/damageWhite.png";
import assistWhite from "../../../../assets/images/tournamentPoints/assistWhite.png";
import { useTournamentAdminContext } from "../TournamentAdminProvider";
import { FiAlertTriangle } from "react-icons/fi";

interface IDeletePreset {
  disabled?: boolean,
  disabledMessage?: string,
  deletePreset: () => void
}

const DeletePreset: React.FC<IDeletePreset> = ({disabled, disabledMessage, deletePreset}) => {
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <div className="flex items-center gap-x-3 w-full mt-2">
      {confirm ? (
        <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
          <div className="flex items-center gap-x-2 w-full">
            <button type="button"
                    onClick={() => deletePreset()}
                    className="w-3/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center  text-red/70 bg-red/20 hover:opacity-75 transition-opacity">
              Confirm
            </button>
            <button type="button"
                    onClick={() => setConfirm(false)}
                    className="w-1/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          {disabled && disabledMessage ? (
            <p className="font-medium font-compact text-red/70 mb-2">
              {disabledMessage}
            </p>
          ) : ''}
          <button type="button"
                  disabled={disabled}
                  onClick={() => setConfirm(true)}
                  className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity w-full
                            disabled:opacity-50 disabled:pointer-events-none">
            Delete Preset
          </button>
        </div>
      )}
    </div>
  );
}

interface IPointSystem {
  pointSystem: ApexPointSystem
}

const PointSystem: React.FC<IPointSystem> = ({pointSystem}) => {
  const { tournament } = useTournamentContext();
  const { playStarted } = useTournamentAdminContext();

  const handleUsePreset = async () => {
    if (tournament) {
      try {
        const tournamentRef = doc(firestore, 'tournaments', tournament.id);
        const previousPointSystemId = tournament.pointSystem;
        const tournamentPromise = updateDoc(tournamentRef, {
          pointSystem: pointSystem.id
        })
        const pointSystemRef = doc(firestore, 'apexTournamentPointSystems', pointSystem.id);
        const pointSystemPromise = updateDoc(pointSystemRef, {
          tournamentsUsing: arrayUnion(tournament.id)
        })
        let previousPointSystemPromise = Promise.resolve();
        if (previousPointSystemId) {
          const previousPointSystemRef = doc(firestore, 'apexTournamentPointSystems', previousPointSystemId);
          previousPointSystemPromise = updateDoc(previousPointSystemRef, {
            tournamentsUsing: arrayRemove(tournament.id)
          })
        }
        const combinedPromise = Promise.all([previousPointSystemPromise, pointSystemPromise, tournamentPromise])
        toast.promise(combinedPromise, {
          pending: 'Updating point system',
          success: 'Point system updated',
          error: 'Error updating point system'
        })
      } catch (err) {
        console.error(err);
        toast.error('Error updating point system')
      }
    }
  }

  const handleDeletePreset = async () => {
    if (pointSystem) {
      try {
        const pointSystemRef = doc(firestore, 'apexTournamentPointSystems', pointSystem.id);
        const deletePromise = deleteDoc(pointSystemRef);
        toast.promise(deletePromise, {
          pending: 'Deleting point system',
          success: 'Point system deleted',
          error: 'Error deleting point system'
        })
      } catch (err) {
        console.error(err);
        toast.error('Error deleting point system')
      }
    }
  }

  return (
    <div className='text-white font-compact flex flex-col gap-y-6'>
      <div>
        <h3>Action Points</h3>
        <div className='flex flex-col gap-y-2 mt-2 w-full lg:w-2/3'>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={skullWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Kill</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white px-4`}>
              <p className='text-sm font-semibold'>{pointSystem.perKill} point{pointSystem.perKill > 1 || pointSystem.perKill === 0 ? 's' : ''}</p>
            </div>
          </div>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={damageWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Damage</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white px-4`}>
              <p className='text-sm font-semibold'>{pointSystem.perDamage} point{pointSystem.perDamage > 1 || pointSystem.perDamage === 0 ? 's' : ''}</p>
            </div>
          </div>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={assistWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Assist</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white px-4`}>
              <p className='text-sm font-semibold'>{pointSystem.perAssist} point{pointSystem.perAssist > 1 || pointSystem.perAssist === 0 ? 's' : ''}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-6'>
        <h3>Placement Points</h3>
        <div className='flex gap-x-6 mt-2'>
          <div className='flex flex-col gap-y-2 w-1/2'>
            {pointSystem.placements.slice(0, 10).map((placementPoints, index) => (
              <div key={`placement-${index+1}-points`} className="relative flex items-center gap-x-2 w-full h-[45px]">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-1/2 rounded-xl pl-5">
                  <p className='uppercase font-wide text-white font-semibold'>
                    {positionNumberToString(index + 1)}
                  </p>
                </div>
                <div className={`w-1/2 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white px-4`}>
                  <p className='text-sm font-semibold'>{placementPoints} point{placementPoints > 1 || placementPoints === 0 ? 's' : ''}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-y-2 w-1/2'>
            {pointSystem.placements.slice(10, 20).map((placementPoints, index) => (
              <div key={`placement-${index+11}-points`} className="relative flex items-center gap-x-2 w-full h-[45px]">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-1/2 rounded-xl pl-5">
                  <p className='uppercase font-wide text-white font-semibold'>
                    {positionNumberToString(index + 11)}
                  </p>
                </div>
                <div className={`w-1/2 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white px-4`}>
                  <p className='text-sm font-semibold'>{placementPoints} point{placementPoints > 1 || placementPoints === 0 ? 's' : ''}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='mt-4'>
          {playStarted && (tournament && tournament.pointSystem !== pointSystem.id) ? (
            <p className='text-red/70 flex items-center gap-x-2'>
              <FiAlertTriangle className='text-xl mb-1'/>
              <span>
                Can not change point system after play has started
              </span>
            </p>
          ) : ''}
          <button type='button'
                  disabled={tournament !== null && (tournament.pointSystem === pointSystem.id || playStarted)}
                  onClick={handleUsePreset}
                  className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-green text-black rounded-xl font-compact font-semibold uppercase
                            hover:opacity-75 transition-opacity mt-2 disabled:opacity-50'>
            <span>{tournament && tournament.pointSystem === pointSystem.id ? 'Using this preset' : 'Use Preset'}</span>
          </button>
          <DeletePreset disabled={pointSystem.tournamentsUsing.length > 0}
                        disabledMessage={`${pointSystem.tournamentsUsing.length} tournament${pointSystem.tournamentsUsing.length !== 1 ? 's' : ''} using this preset`}
                        deletePreset={handleDeletePreset}/>
        </div>
      </div>
    </div>
  )
}

export default PointSystem;
