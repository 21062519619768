import { useContext } from "react";
// context
import { TeamShowContext } from "./TeamShowProvider";
// icons
import { FaPlus } from "react-icons/fa";
// import { EditProfileBtn } from "../common/icons/ProfileIcons";
import { FaHandshakeAngle } from "react-icons/fa6";


interface ISponsors {
  setCurrentModalStep: (step: number) => void,
}

const Sponsors: React.FC<ISponsors> = ({setCurrentModalStep}) => {
  const { team, editor } = useContext(TeamShowContext);

  return team && (team.sponsorLogoUrls.length > 0 || editor) ? (
    <>
      <div className="mt-3">
        <div className="flex items-center justify-between gap-2">
          <h2 className="font-wide font-bold text-white !leading-5 uppercase">
            Sponsors
          </h2>
          {editor ? (
            <button
              type="button"
              onClick={() => setCurrentModalStep(4)}
              className="w-fit py-2 bg-lightGray duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-medium px-6 rounded-xl flex gap-2 items-center !leading-4">
                <span>Manage Sponsors</span>
                <FaHandshakeAngle className="text-green text-xl" />
            </button>
          ) : ''}
        </div>


          {team.sponsorLogoUrls.length > 0 ? (
            <div className="mt-6 mb-10 flex flex-nowrap gap-3 w-full overflow-x-scroll">
              {team.sponsorLogoUrls.map((logoUrl) => (
                <div className="bg-lightBlack py-5 xl:py-8 lg:px-5 px-3 flex-shrink-1
                                  rounded-xl h-[9rem] flex-grow flex justify-center items-center cursor-pointer hover:opacity-70 duration-300">
                  <img src={logoUrl} alt="image"
                      className="max-w-[120px] lg:max-w-[150px] h-auto aspect-[2] object-contain"/>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center gap-x-3 h-[100px] bg-lightBlack my-4 rounded-lg">
              {editor ? (
                <>
                  <p className="font-wide font-semibold uppercase text-2xl text-steelGray text-center">
                    No sponsors added
                  </p>
                  <button type="button"
                          aria-label="add sponsor logos"
                          onClick={() => setCurrentModalStep(4)}
                          className="flex items-center p-2 gap-x-2 transition-opacity font-semibold text-steelGray border-2 border-steelGray/20 rounded-lg hover:opacity-75">
                    <p className="font-wide">Add</p>
                    <FaPlus/>
                  </button>
                </>
              ) : (
                <>
                  <p className="font-wide font-semibold uppercase text-2xl text-steelGray text-center">
                    No sponsors
                  </p>
                </>
              )}
            </div>
          )}

      </div>
    </>
  ): '';
};

export default Sponsors;
