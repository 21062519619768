import { useState, useEffect } from "react";
// types
import { TournamentStatus } from "@src/firestore/tournaments";
// context
import { useTournamentContext } from "../TournamentProvider";
// utils
import { formatDateCountdown, formatDateTime, getTimezone } from "@utils/Date";
// components
import MobileStatus from "./MobileStatus";
// icons
import { LeftArrow } from "@icons/TournamentIcons";
import { WatchIcon, CelenderIcon } from "@icons/TournamentIcons";
import { MdChecklistRtl } from "react-icons/md";


const Status = () => {
  const { tournament } = useTournamentContext();
  const [shiftedRight, setShiftedRight] = useState(false); // has the list been shifted to the right (on small screens)

  const registrationActive = tournament?.status === TournamentStatus.registration;
  const confirmationActive = tournament?.status === TournamentStatus.confirmation || tournament?.status === TournamentStatus.prep;
  const ongoingActive = tournament?.status === TournamentStatus.ongoing;
  const completed = tournament?.status === TournamentStatus.results;

  const [confirmationCountdown, setConfirmationCountdown] = useState<string>('');
  const [ongoingCountdown, setOngoingCountdown] = useState<string>('');
  const [resultsCountdown, setResultsCountdown] = useState<string>('');

  useEffect(() => {
    let confirmationIntervalId: NodeJS.Timeout;
    let ongoingIntervalId: NodeJS.Timeout;
    let resultsIntervalId: NodeJS.Timeout;

    if (tournament) {
      if (confirmationCountdown === '') {
        confirmationIntervalId = setInterval(() => {
          setConfirmationCountdown(formatDateCountdown(tournament.statusDates.confirmation));
        }, 1000);
      }
      if (ongoingCountdown === '') {
        ongoingIntervalId = setInterval(() => {
          setOngoingCountdown(formatDateCountdown(tournament.statusDates.ongoing));
        }, 1000);
      }
      if (resultsCountdown === '') {
        resultsIntervalId = setInterval(() => {
          setResultsCountdown(formatDateCountdown(tournament.statusDates.results));
        }, 1000);
      }
    }
    // we clear the countdown intervals on rerender so they dont multiply
    return () => {
      clearInterval(confirmationIntervalId);
      clearInterval(ongoingIntervalId);
      clearInterval(resultsIntervalId);
    };
  }, [tournament]);

  return tournament ? (
    <div className="px-4 sm:px-8 lg:px-12 mt-10">
      <div className="border-b border-lightGray pb-10 overflow-hidden">
        <h2 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase">
          Tournament Status
        </h2>
        <MobileStatus registrationActive={registrationActive}
                     confirmationActive={confirmationActive}
                     ongoingActive={ongoingActive}
                     completed={completed}
                     confirmationCountdown={confirmationCountdown}
                     ongoingCountdown={ongoingCountdown}
                     resultsCountdown={resultsCountdown}/>
        <div className="relative mt-8 hidden md:block">
          <div className="flex xl:hidden justify-between absolute top-0 left-0 w-full h-full z-10">
            {shiftedRight ? (
              <div className="w-[5.5rem] h-full bg-gradient-to-r from-black via-transparent to-transparent flex justify-center items-center">
                <span
                  onClick={() => setShiftedRight(false)}
                  className="cursor-pointer"
                >
                  <LeftArrow />
                </span>
              </div>
            ) : ''}
            {!shiftedRight ? (
              <div className="w-[5.5rem] h-full bg-gradient-to-l from-black via-transparent to-transparent flex justify-center items-center ms-auto">
                <span
                  // to={"#results"}
                  onClick={() => setShiftedRight(true)}
                  className="cursor-pointer rotate-[180deg]"
                >
                  {/* Actually a right arrow (its rotated.... O.o) */}
                  <LeftArrow />
                </span>
              </div>
            ): ''}
          </div>
          <div className={`hidden md:flex min-w-[74rem] duration-300
                          ${shiftedRight? "translate-x-[-30.313rem] lg:translate-x-[-16.875rem] xl:translate-x-0" : ""} rounded-xl overflow-hidden`}>
              <div className={`flex-grow xl:w-3/12 -ml-5 md:w-1/2 bg-transparent tournament_phase_first_card w-fit pl-[25px] ${registrationActive ? "active" : ""}`}>
                <div className={`group duration-300 pe-3 py-4 pl-3 bg-no-repeat bg-center`}>
                  <h2 className={`font-compact font-medium text-base  duration-300 !leading-4 tracking-[0.0088rem] uppercase
                                  ${registrationActive ? "text-white" : "text-steelGray"}`}>
                    Registration
                  </h2>
                  <p className={`font-compact font-normal text-sm !leading-[1.125rem] tracking-[0.0088rem] pt-2 pb-3
                                ${registrationActive ? "text-slateGray" : "text-steelGray"}`}>
                    Teams can register for the tournament
                  </p>
                  <p className={`flex items-center gap-2 font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] duration-300
                                ${registrationActive ? "text-white" : "text-steelGray"}`}>
                    <MdChecklistRtl className={`text-2xl ${registrationActive ? "text-green" : ""}`}/>
                    <span>{tournament.status > 0 ? 'Completed' : 'In progress'}</span>
                  </p>
                </div>
              </div>

              <div className={`flex-grow xl:w-3/12 -ml-5 md:w-1/2 bg-transparent tournament_phase_card w-fit pl-[35px] ${confirmationActive ? "active" : ""}`}>
                <div className={`group duration-300 pe-3 py-4 pl-3 bg-no-repeat bg-center`}>
                  <h2 className={`font-compact font-medium text-base  duration-300 !leading-4 tracking-[0.0088rem] uppercase
                                  ${confirmationActive ? "text-white" : "text-steelGray"}`}>
                    Check In
                  </h2>
                  <p className={`font-compact font-normal text-sm !leading-[1.125rem] tracking-[0.0088rem] pt-2 pb-3
                                ${confirmationActive ? "text-slateGray" : "text-steelGray"}`}>
                    Check In of Teams Registered
                  </p>
                  <p className={`flex items-center gap-2 font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] duration-300
                                ${confirmationActive ? "text-white" : "text-steelGray"}`}>
                    <span className={`${confirmationActive ? "text-green" : ""}`}>
                      <WatchIcon fill={confirmationActive ? '#D4FF27' : ''}/>
                    </span>
                    <span className="flex items-center gap-x-1">
                      {confirmationActive ? (
                        <span>In progress</span>
                      ) : (
                        <>
                          {tournament.status > 1 ? (
                            <span>Completed</span>
                          ) : (
                            <span className="flex items-center">
                              <span>{formatDateTime(tournament.statusDates.confirmation)}  {getTimezone()}</span>
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </p>
                </div>
              </div>

              <div className={`flex-grow xl:w-3/12 -ml-5 md:w-1/2 bg-transparent tournament_phase_card w-fit pl-[35px] ${ongoingActive ? "active" : ""}`}>
                <div className={`group duration-300 pe-3 py-4 pl-3 bg-no-repeat bg-center`}>
                  <h2 className={`font-compact font-medium text-base  duration-300 !leading-4 tracking-[0.0088rem] uppercase
                                  ${ongoingActive ? "text-white" : "text-steelGray"}`}>
                    In Progress
                  </h2>
                  <p className={`font-compact font-normal text-sm !leading-[1.125rem] tracking-[0.0088rem] pt-2 pb-3
                                ${ongoingActive ? "text-slateGray" : "text-steelGray"}`}>
                    Tournament play starts
                  </p>
                  <p className={`flex items-center gap-2 font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] duration-300
                                ${ongoingActive ? "text-white" : "text-steelGray"}`}>
                    <CelenderIcon fill={ongoingActive ? '#D4FF27' : ''}/>
                    <span className="flex items-center gap-x-1">
                      {ongoingActive ? (
                        <span>In progress</span>
                      ) : (
                        <>
                          {tournament.status > 2 ? (
                            <span>Completed</span>
                          ) : (
                            <span className="flex items-center">
                              <span>{formatDateTime(tournament.statusDates.ongoing)}  {getTimezone()}</span>
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </p>
                </div>
              </div>

              <div className={`flex-grow xl:w-3/12 -ml-5 md:w-1/2 bg-transparent tournament_phase_card w-fit pl-[35px] ${completed ? "active" : ""}`}>
                <div className={`group duration-300 pe-3 py-4 pl-3 bg-no-repeat bg-center`}>
                  <h2 className={`font-compact font-medium text-base  duration-300 !leading-4 tracking-[0.0088rem] uppercase
                                  ${completed ? "text-white" : "text-steelGray"}`}>
                    Results
                  </h2>
                  <p className={`font-compact font-normal text-sm !leading-[1.125rem] tracking-[0.0088rem] pt-2 pb-3
                                ${completed ? "text-slateGray" : "text-steelGray"}`}>
                    Announcement of tournament winners
                  </p>
                  <div className={`flex items-center gap-2 font-compact font-normal text-sm !leading-4 tracking-[0.0088rem] duration-300
                                ${completed ? "text-white" : "text-steelGray"}`}>
                    <CelenderIcon fill={completed ? '#D4FF27' : ''}/>
                    <div className="flex items-center gap-x-1">
                      {completed ? (
                        <span>In progress</span>
                      ) : (
                        <span>On finals completion</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  ): '';
};

export default Status;
