import { Link } from "react-router-dom";
import { findTeamList } from "../common/content/About";
import { BottomGreenIcon ,BottomBannerIcon,SubmitArrow} from "../common/icons/Common";

const FindATeam = () => {
  return (
    <>
      <div className="px-4 sm:px-8 lg:px-12 flex flex-col sm:flex-row gap-4 sm:gap-6 mt-4 sm:mt-6 mb-24 custom_container mx-auto">
        {findTeamList.map((obj, index) => {
          return (
            <div
              className={`pt-6 xl:p-10 md:w-1/2 rounded-xl overflow-hidden relative duration-300 ease-out transition-all ${obj.parentStyle}`}
            >
              {index === 0 ? (
                <span className="find_team_triangle_icon absolute end-3 bottom-3">
                  <BottomGreenIcon />
                </span>
              ) : (
                <span className="find_team_triangle_icon absolute end-3 bottom-3">
                  <BottomBannerIcon />
                </span>
              )}

              <div
                key={index}
                className="flex flex-col xl:flex-row items-center"
              >
                <div className="xl:w-1/2">
                  <img
                    className={`max-w-full xl:max-w-[280px] custom_2xl:max-w-[19.063rem] w-full h-[10.625rem] sm:h-[11.125rem] custom_xsm:h-[13.438rem] md:h-[14.375rem] xl:h-[20rem] object-cover object-right xl:absolute bottom-0 start-0 ${obj.imageShadowStyle}`}
                    src={obj.img}
                    alt="find team image"
                  />
                </div>
                <div className="xl:w-1/2 xl:ps-5 sm:text-center xl:text-start mt-6 xl:mt-0 p-6 xl:p-0">
                  <p
                    className={`font-wide font-bold text-sm !leading-4 uppercase duration-300 ease-out transition-all ${obj.subTitleStyle}`}
                  >
                    {obj.subTitle}
                  </p>
                  <h2
                    className={`font-wide font-bold text-xl md:text-2xl custom_xsm:text-4xl sm:text-xl lg:text-4xl leading-4 md:!leading-9 uppercase my-3 duration-300 ease-out transition-all ${obj.className}`}
                  >
                    {obj.title}
                  </h2>
                  <p
                    className={`font-compact font-normal text-sm !leading-4 opacity-60 duration-300 ease-out transition-all ${obj.descriptionStyle}`}
                  >
                    {obj.description}
                  </p>
                  <Link
                    to={obj.url}
                    className={`font-compact font-medium text-base !leading-4 pt-[1.125rem] pb-4 px-6 mt-8 common_green_btn uppercase duration-300 ease-out transition-all inline-block gap-2 sm:mx-auto xl:mx-0 ${obj.btnStyle}`}
                  >
                    <span className="flex items-center">
                      <span>{obj.btnContent}</span>
                      <span className={`pl-3 ${index === 0 ? "hidden" : ""}`}>
                        <SubmitArrow />
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FindATeam;
