import { useState } from 'react'
// components
import Publish from './Publish';
import AnnounceTournament from './AnnounceTournament';
import EventStart from './EventStart';
import EventComplete from './EventComplete';
import Winnings from './Winnings';

const EventSettings = () => {
  const [currentView, setCurrentView] = useState<'publishTournament' | 'announceTournament' | 'eventStart' | 'eventComplete' | 'winnings'>('publishTournament');

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Event Settings
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView('publishTournament')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'publishTournament' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Publish</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView('announceTournament')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'announceTournament' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Announce</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView('eventStart')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'eventStart' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Event Start</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView('eventComplete')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'eventComplete' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50  hover:border-steelGray'}`}>
              <span>Event Complete</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView('winnings')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'winnings' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50  hover:border-steelGray'}`}>
              <span>Winnings</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-3/5'>
          {currentView === 'publishTournament' ? (
            <Publish/>
          ) : ''}
          {currentView === 'announceTournament' ? (
            <AnnounceTournament/>
          ) : ''}
          {currentView === 'eventStart' ? (
            <EventStart/>
          ) : ''}
          {currentView === 'eventComplete' ? (
            <EventComplete/>
          ) : ''}
          {currentView === 'winnings' ? (
            <Winnings/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default EventSettings;
