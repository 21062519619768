import { ReactNode } from 'react'

interface IPopoverText {
  className?: string,
  bottom?: boolean,
  right?: boolean,
  visible: boolean,
  children: ReactNode
}

const PopoverText: React.FC<IPopoverText> = ({right, children, visible, className, bottom}) => {
  return (
    <div className={`${className} ${visible ? '' : 'opacity-0 pointer-events-none'} transition-opacity absolute w-fit z-[20] ${bottom ? 'bottom-0 translate-y-[calc(100%+10px)]' : 'top-0 -translate-y-[calc(100%+10px)]'} ${right ? 'right-0' : 'left-0'}
                        p-2 rounded-lg bg-lightBlack/70 backdrop-blur-md border border-steelGray/50 font-semibold flex flex-col gap-y-3`}>
      <p className="text-white text-sm font-compact font-thin whitespace-nowrap">
        {children}
      </p>
    </div>
  )
}

export default PopoverText;
