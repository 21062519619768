/* eslint-disable @typescript-eslint/no-explicit-any */
import { NewsDetails } from "../common/content/News";
import NewsDetailRelatedArticles from "./RelatedArticles";
import NewsDetailVideo from "./VideoModal";

const LeakerRevelation = () => {
  // Rendering the component
  return (
    <>
      {/* Container for the main content */}
      <div className="px-4 md:px-0 md:w-10/12 lg:w-8/12 xl:max-w-[55.5rem] mx-auto sm:px-8 lg:px-12">
        {/* Mapping over the list of news details for leakers */}
        {NewsDetails.map((data, index) => {
          return (
            <div
              key={index}
              className="border-t border-t-ebonyClay mt-10 pt-10"
            >
              <div>
                {/* Displaying leaker title */}
                <h3 className="text-white font-wide text-xl md:text-2xl font-bold uppercase">
                  {data.title}
                </h3>
                {/* Mapping over paragraphs in leaker details */}
                {data.description.map((obj, i) => {
                  return (
                    <div key={i}>
                      {/* Displaying paragraphs with conditional styling */}
                      <p
                        className={
                          "text-slateGray pt-6 leading-[200%] font-compact text-base font-normal"
                        }
                      >
                        {obj.para}
                      </p>
                    </div>
                  );
                })}
                {/* Displaying leaker images or videos */}
                <div
                  className={`flex flex-wrap " ${
                    index === 1
                      ? "border-t border-t-ebonyClay pt-10 mt-10"
                      : "pt-10 flex-col sm:flex-row"
                  }`}
                >
                  {data.image.map((object: any, indx: number) => (
                    <NewsDetailVideo
                      object={object}
                      key={indx}
                      mapKey={indx}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        })}
        <NewsDetailRelatedArticles />
      </div>
    </>
  );
};

export default LeakerRevelation;
