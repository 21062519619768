import { useEffect, useRef, useState, useContext, useCallback } from "react";
// context
import { TeamInfoContext } from "../TeamInfoProvider";
// content
import { CreateBackArrowIcon, RightCheckIcon } from "../../common/icons/ProfileIcons";
// icons
import { CrossIcon } from "../../common/icons/FilterIcons";
import { FaPaypal } from "react-icons/fa";
import { ImSpinner8 } from "react-icons/im";
import { Link } from "react-router-dom";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const EditTeamPayment = (props: IProps) => {
  const scrollContainerRef = useRef(null);

  const { changeModalStep, closeModal } = props;
  const [isFormFilled, setIsFormFilled] = useState(false);

  const {teamInfo, setTeamInfo, edit, updateTeam} = useContext(TeamInfoContext);

  const [paypalValid, setPaypalValid] = useState<boolean>(false);

  const [activeField, setActiveField] = useState<string>('');


  useEffect(() => {
    const isFormFilled = teamInfo.paypalLink !== '' && paypalValid;
    setIsFormFilled(isFormFilled);
  }, [teamInfo, paypalValid]);

  const validatePaypal = useCallback(() => {
    const paypalRegex = /^(((https|http):\/\/)?(www\.)?paypal\.me\/[a-zA-Z0-9]{1,20}|\w+@\w+\.\w+)$/i
    setPaypalValid(teamInfo.paypalLink === '' || paypalRegex.test(teamInfo.paypalLink));
  }, [teamInfo]);

  useEffect(() => {
    validatePaypal();
  }, [teamInfo, validatePaypal])

  const handleStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleTeamUpdate();
  };

  const [submitting, setSubmitting] = useState<boolean>(false);


  const handleTeamUpdate = async () => {
    setSubmitting(true); // state to track whether form currently submitting
    const teamUpdated: boolean = await updateTeam();
    if (teamUpdated) {
      closeModal();
    }
    setSubmitting(false);
  }

  const checkForOutsideInputClick: EventListener = useCallback((e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('.paymentInput') && !target.closest('.paymentInput')) {
        setActiveField('');
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [checkForOutsideInputClick])

  return (
    <>
      <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack">
        <form onSubmit={handleStepSubmit} className="h-full flex flex-col">
          <div className="px-4 py-3 border-b-[0.063rem] border-lightGray">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    changeModalStep(-1);
                  }}>
                  <CreateBackArrowIcon />
                </span>
                <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
                  {edit ? 'Edit Team' : 'Create team'}
                </h5>
              </div>
              <span
                className="cursor-pointer"
                onClick={() => {
                  closeModal();
                }}>
                <CrossIcon />
              </span>
            </div>
          </div>
          <div ref={scrollContainerRef} className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px] relative">

            <FaPaypal className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[50%] w-[250px] h-auto aspect-square text-lightGray/40"/>

            <div className="flex gap-[1.375rem] items-center justify-center pt-6 relative z-[2]">
              <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
                <RightCheckIcon />
              </div>
              <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
                <RightCheckIcon />
              </div>
              <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green bg-lightBlack"></div>
            </div>
            <div className="px-6 relative z-[2]">
              <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
                PayPal.me / PayPal Email *
              </h2>
              <div className={`${teamInfo.tournamentsInPlay.length > 0 ? 'opacity-50 pointer-events-none' : ''}`}>
                <input type="text"
                      value={teamInfo.paypalLink}
                      onChange={(e) => {
                        if (teamInfo.tournamentsInPlay.length === 0) {
                          setTeamInfo({
                            ...teamInfo,
                            paypalLink: e.target.value
                          })
                        }
                      }}
                      placeholder="paypal.me/username - john.smith@gmail.com"
                      onClick={() => setActiveField('paypal')}
                      className={`paymentInput gap-3 flex items-center justify-between bg-black placeholder:text-steelGray text-white mt-2 w-full rounded-xl py-3 px-4 relative cursor-pointer outline-none border-2 border-transparent
                                  ${activeField === 'paypal' ? paypalValid ? '!border-green' : '!border-red'
                                  : paypalValid ? '' : '!border-red/70'}`}>
                </input>
              </div>
              <div className="mt-2">
                {teamInfo.tournamentsInPlay.length === 0 ? (
                  <>
                    <Link to="https://www.paypal.com/tc/webapps/mpp/paypal-me" target="_blank"
                          className="text-white hover:opacity-75 font-compact font-thin text-sm transition-opacity">
                      <span className="underline">
                        Learn more about {' '}
                      </span>
                      <span className="text-green underline">
                        PayPal.me
                      </span>
                    </Link>
                    <p className="text-red/90 font-compact font-thin text-sm max-w-[80%] mx-auto mt-2">
                      <span>
                      Versus is not liable for unpaid winnings due to incorrect payment information.
                      </span>
                    </p>
                  </>
                ) : (
                  <p className="text-red/90 font-compact font-thin text-sm max-w-[80%] mx-auto">
                  <span>
                    You can not change your payment information while engaged in a tournament
                  </span>
                </p>
                )}
              </div>
            </div>
          </div>
          <div className="border-t-[0.063rem] border-lightGray pt-3 pb-3 px-4 h-fit">
            <button
              type="submit"
              className={`font-compact text-base font-medium !leading-4 !tracking-[0.01rem] uppercase w-full rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 ${
                isFormFilled
                  ? "cursor-pointer opacity-100"
                  : "cursor-not-allowed opacity-30"
              }`}
              disabled={!isFormFilled}>
              <span className="mb-[-0.1rem]">Update Team</span>
              <span className="mb-[0.01rem]">
                {submitting ? (
                  // icon spinner
                  <ImSpinner8 className="text-lg animate-spin"/>
                ) : ""}
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditTeamPayment;
