import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// // libraries
// import { toast } from 'react-toastify';
// components
import Modal from "@src/components/ui/Modal";
// icons
import { FaArrowUp } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  createTournament: () => Promise<boolean>,
  stepNames: string[]
}

const ReviewStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, createTournament, stepNames}) => {
  const [confirmationInput, setConfirmationInput] = useState<string>('');

  const [stepValid, setStepValid] = useState<boolean>(true);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleCreateTournament = async () => {
    setSubmitting(true);
    await createTournament();
    setSubmitting(false);
  }

  useEffect(() => {
    // TODO
    if (confirmationInput === tournamentInfo.name) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [confirmationInput, tournamentInfo]);

  return (
    <Modal title="Review"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={10}
           totalSteps={10}
           open={currentStep === CreationFlowPage.review}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid || submitting}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">Submit</span>
              {submitting ? (
                <ImSpinner8 className='animate-spin'/>
              ) : (
                <FaArrowUp/>
              )}
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.savePreset)}
           buttonOnClick={handleCreateTournament}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-center text-center w-full">
        <p>Tournaments require approval before they can be published.</p>
        <p>Please check event details are correct and confirm below.</p>
        <div className="w-full mt-2 flex flex-col gap-y-2">
          <p className="text-white font-compact font-thin text-center">To proceed in submittion, please type <em className="my-2 text-white not-italic p-[4px] bg-lightGray rounded">{tournamentInfo.name}</em> into the following box:</p>
          <input type="text"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
                className="w-full p-2 text-white bg-black rounded-lg outline-none border-none"/>
        </div>
      </div>
    </Modal>
  )
}

export default ReviewStep;
