import { Link } from "react-router-dom";
// context
import { useTournamentContext } from "../TournamentProvider";
// types
import { TournamentSubViews } from "../Tournament";
// components
import Banner from "./BannerBox/Banner";
import Info from "./InfoBox/Info";
// icons
import { GoBackIcon, NextRouteIcon } from "../../common/icons/TournamentIcons";

interface IHero {
  setCurrentSubView: (subView: TournamentSubViews) => void,
}

const Hero: React.FC<IHero> = ({setCurrentSubView}) => {
  const { tournament } = useTournamentContext();

  return tournament ? (
    <div className="md:px-4 lg:px-12 mx-auto relative max-md:bg-lightBlack custom_container">
      <Link to="/tournaments"
            className="absolute top-2 left-2 z-10 p-3 rounded-full bg-black/40 mt-3 md:hidden mobile_goback_icon hover:bg-black/80 duration-300">
        <GoBackIcon />
      </Link>
      <div className="pb-8 md:flex justify-between items-center hidden">
        <Link
          to="/tournaments"
          className="font-compact py-3 ps-3 pe-4 font-medium text-base !leading-4 !text-white gap-2 border rounded-xl border-lightGray flex items-center group hover:opacity-80"
        >
          <span className="duration-300 group-hover:translate-x-[-0.313rem]">
            <GoBackIcon />
          </span>
          <span className="-mb-0.5"> Back</span>
        </Link>
        <div className="flex gap-4 items-center">
          <Link
            to="/tournaments"
            className="font-compact font-normal text-sm !leading-4 text-ebonyClay duration-200 hover:text-slateGray -mb-0.5"
          >
            Tournaments
          </Link>
          <span>
            <NextRouteIcon />
          </span>
          <span className="font-compact font-normal text-sm !leading-4 text-slateGray -mb-0.5">
            {tournament.name}
          </span>
        </div>
      </div>
      <div className="flex flex-col custom_xl:flex-row gap-10 w-full">
        <div className="custom_xl:w-[45%] custom_xl:order-1 order-2 mt-2 md:mt-0 w-full">
          <Info setCurrentSubView={setCurrentSubView}/>
        </div>
        <div className="custom_xl:w-full order-1 custom_xl:order-2 hidden md:block">
          <div className="bg-lightBlack h-full custom_xl:rounded-xl">
            <Banner />
          </div>
        </div>
      </div>
    </div>
  ): '';
};

export default Hero;
