// utils
import { formatDate } from '../../../utils/Date';
// context
import { useTeamContext } from "../TeamShowProvider";
// icons
import { CalendarIcon } from "../../common/icons/ProfileIcons";
import { CompetedTournamentsIcon } from "../../common/icons/ProfileIcons";
import { TrophyIcon, TeamIcon } from '../../common/icons/Common';

interface IProfileSection {
  className?: string,
}

const ProfileAbout: React.FC<IProfileSection> = ({ className }) => {
  const { team, teamTrophies } = useTeamContext();

  if (team) {
    return (
      <div className={`${className}`}>
        <div className={`h-full`}>
          <h2 className="md:hidden font-wide font-bold text-white !leading-5 uppercase">
            About
          </h2>
          <div className="bg-lightBlack p-8 rounded-2xl mt-4 md:mt-0 h-full flex flex-col">
            <div className="flex flex-col gap-6 justify-between flex-grow py-2">

              <div className="flex items-center gap-4">
                <CalendarIcon />
                <div className="-mb-1">
                  <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
                    Created
                  </p>
                  <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
                    {formatDate(team.createdAt)}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <TeamIcon className='fill-white w-[20px] h-auto aspect-square' />
                <div className="-mb-1">
                  <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
                    Team Size
                  </p>
                  <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
                    {team.players.length} {team.players.length === 1 ? 'member' : 'members'}
                  </p>
                </div>
              </div>

              <div className={`flex ${team ? 'items-center' : '' } gap-4`}>
                <CompetedTournamentsIcon />
                <div className="-mb-1">
                  <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
                    Competed
                  </p>

                  <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
                    {team.competedTournaments.length} {team.competedTournaments.length === 1 ? "tournament" : "tournaments"}
                  </p>

                </div>
              </div>

              <div className={`flex ${team ? 'items-center' : '' } gap-4`}>
                <TrophyIcon className='fill-green w-[20px] h-auto aspect-square' />
                <div className="-mb-1">
                  <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
                    Trophies
                  </p>
                  <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
                    {teamTrophies ? teamTrophies.length : 0} trophies
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // show loading shell ?
  }
};

// Export ProfileAbout component as default
export default ProfileAbout;
