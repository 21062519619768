import { useState, useRef, useEffect } from "react";
// types
import { DBUser } from "../../firestore/users";
// context
import ProfileProvider from "./ProfileProvider";
// components
import ProfileHero from "./show/ProfileHero";
import ProfileAbout from './show/ProfileAbout';
import ProfileStream from "./show/ProfileStream";
import ProfileTrophies from "./show/ProfileTrophies";
import TeamPlayers from "./show/TeamPlayers";
import BioStandalone from "./show/BioStandalone";
// ads
import FindATeamGray from "@common/FindTeamGray";
// import SurfsharkAdMobile from "../adverts/surfshark/SurfsharkAdMobile";
// icons
import { LuUnlink } from "react-icons/lu";

const Profile = () => {
  const [profile, setProfile] = useState<DBUser | null>(null);

  const profileHeroRef = useRef(null);
  const profileAboutRef = useRef(null);

  const handleProfileChange = (team: DBUser | null) => {
    setProfile(team);
  }

  const resizeListener = () => {
    const hero = profileHeroRef.current;
    const about = profileAboutRef.current;
    if (hero && about) {
      (about as HTMLDivElement).style.height = `${(hero as HTMLDivElement).offsetHeight}px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    setTimeout(resizeListener, 500);

    return () => window.removeEventListener('resize', resizeListener);
  }, [])

  return (
    <div>
      <ProfileProvider handleProfileChange={handleProfileChange}>
          {profile ? (
              <div className="text-white pb-[5rem] custom_container">
                {/*  Top Row */}
                <div className="flex gap-x-12 h-fit sm:px-8 lg:px-12">
                  <div className="h-fit w-[65%] flex-grow flex flex-col gap-y-6">
                    <div ref={profileHeroRef} className="w-full h-fit">
                      <ProfileHero className="w-full"/>
                    </div>
                    <div className="px-4 sm:px-0 flex flex-col gap-y-6">
                      <ProfileAbout className="md:hidden"/>
                      <BioStandalone className="hidden md:block"/>
                      <ProfileStream />
                      <ProfileTrophies className="md:hidden"/>
                      <TeamPlayers/>
                    </div>
                  </div>
                  <div className="hidden md:flex w-[26%] flex-grow flex-col gap-y-6">
                    <div ref={profileAboutRef} className="w-full flex flex-col gap-y-4">
                      <ProfileAbout className="w-full flex-grow" />
                      {/* <div className="h-fit w-auto">
                        <SurfsharkAdMobile/>
                      </div> */}
                      <div className="relative bg-lightBlack w-full h-auto !aspect-[1.55] rounded-2xl flex items-center justify-center">
                        <FindATeamGray/>
                      </div>
                    </div>
                    <ProfileTrophies />
                  </div>
                </div>
              </div>
          ) : (
            <div className="relative w-full min-h-[80vh]">
              <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                            text-xl sm:text-4xl font-semibold text-green
                            flex flex-col sm:flex-row items-center gap-x-4">
                <p>Profile not found</p>
                <LuUnlink/>
              </h3>
            </div>
          )}
      </ProfileProvider>
    </div>
  );
};

export default Profile;
