
export const isDisplayNameValid = (displayName: string): boolean => {
  const isValidCharsLength = /^[a-zA-Z0-9_. ]{2,32}$/.test(displayName);
  const oneSpaceMax = [...displayName.matchAll(/\s/g)].length <= 1;
  return isValidCharsLength && oneSpaceMax;
}

export const usernameRequirementsMessage = "Usernames must be between 3 and 15 characters";

export const isUsernameValid = (username: string): boolean => {
  return /^[a-zA-Z0-9_]{3,15}$/.test(username);
}

export const isPasswordValid = (password: string): boolean => {
  const isValidChars = /^[\x20-\x7E]{8,200}$/.test(password);
  const oneCapitalLetter = password.split('').some((character) => /[A-Z]/.test(character));
  return isValidChars && oneCapitalLetter;
}
