import { firestore, functions } from '../firebase';
// functions
import { httpsCallable } from 'firebase/functions';
// firestore
import { DocumentData, DocumentSnapshot, SnapshotOptions, Timestamp, doc, getDoc } from "firebase/firestore";
// types
import { DBUser, userConverter } from './users';

// http functions
const cleanupTeamPlayers = httpsCallable(functions, 'cleanupTeamPlayers');

// Sub Types

export interface PlayerData {
  id: string,
  displayImage: string,
  displayName: string,
  input: string,
  platform: string,
  discord: string,
  twitter: string,
  twitch: string,
  instagram: string,
  youtube: string,
  username: string,
}

interface TrophyData {
  id: string,
  teamName: string,
  tournamentName: string,
  gameName: string,
  tournamentId: string,
  position: number,
  dateRecieved: Date,
  trophyType: number,
  winnings: number,
}

interface TrophyDataRaw extends Omit<TrophyData, 'dateRecieved'> {
  dateRecieved: Timestamp;
}

// -------

const getPlayerFromUid = async (uid: string) => {
  const userRef = doc(firestore, 'users', uid).withConverter(userConverter);
  const userData = (await getDoc(userRef)).data();
  return userData ? userData : uid;
}

const getTeamPlayerData = async (uids: string[]) => {
  const playerQuerys = uids.map((uid: string) => getPlayerFromUid(uid))
  const players = (await Promise.all(playerQuerys));
  return players;
}


export type DBTeam = {
  id: string,
  dissolved: boolean,
  apexMatchNum: number,
  apexMatchWins: number,
  apexMatchWinrate: number,
  totalWinnings: number,
  playerCount: number,
  trophyCount: number,
  tournamentsPlayed: number,
  playerData: PlayerData[],
  trophyData: TrophyData[],
  randomIndex: number,
  teamName: string,
  paypalLink: string,
  teamTag: string,
  teamBio: string,
  manager?: string,
  captain: string, // uid
  region: string,
  discordLink: string,
  twitchLink: string,
  twitterLink: string,
  websiteLink: string,
  mainGame: string,
  players: string[],
  getPlayerObjs?: () => Promise<DBUser[]>,
  pendingPlayers: string[],
  getPendingPlayerObjs?: () => Promise<DBUser[]>,
  logoUrl: string,
  bannerUrl: string,
  sponsorLogoUrls: string[],
  createdAt: Date,
  tournamentsInPlay: string[],
  competedTournaments: string[],
  activeTournaments: string[],
}

// Extended Type
export interface TeamWithPlayerObjs extends DBTeam {
  playerObjs: DBUser[],
}

export const teamConverter = {
  toFirestore: (team: DBTeam): DBTeam => {
    return {
      id: team.id ?? '',
      apexMatchNum: team.apexMatchNum ?? 0,
      apexMatchWins: team.apexMatchWins ?? 0,
      apexMatchWinrate: team.apexMatchWinrate ?? 0,
      totalWinnings: team.totalWinnings ?? 0,
      playerData: team.playerData ?? [],
      trophyData: team.trophyData ?? [],
      trophyCount: team.trophyCount ?? team.trophyData?.length ?? 0,
      playerCount: team.playerCount ?? team.players?.length ?? 0,
      tournamentsPlayed: team.tournamentsPlayed ??  team.competedTournaments?.length ?? 0,
      dissolved: team.dissolved ?? false,
      randomIndex: team.randomIndex ?? Math.random(),
      teamName: team.teamName ?? '',
      paypalLink: team.paypalLink ?? '',
      teamTag: team.teamTag ?? '',
      teamBio: team.teamBio ?? '',
      manager: team.manager ?? '',
      captain: team.captain ?? '',
      region: team.region ?? '',
      discordLink: team.discordLink ?? '',
      twitchLink: team.twitchLink ?? '',
      twitterLink: team.twitterLink ?? '',
      websiteLink: team.websiteLink ?? '',
      mainGame: team.mainGame ?? '',
      players: team.players ?? [],
      pendingPlayers: team.pendingPlayers ?? [],
      logoUrl: team.logoUrl ?? '',
      bannerUrl: team.bannerUrl ?? '',
      sponsorLogoUrls: team.sponsorLogoUrls ?? [],
      createdAt: team.createdAt ?? new Date(),
      tournamentsInPlay: team.tournamentsInPlay ?? [],
      activeTournaments: team.activeTournaments ?? [],
      competedTournaments: team.competedTournaments ?? [],
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions): DBTeam => {
    const data = snapshot.data(options)!;
    return {
      id: data.id !== '' ? data.id : snapshot.id,
      apexMatchNum: data.apexMatchNum ?? 0,
      apexMatchWins: data.apexMatchWins ?? 0,
      apexMatchWinrate: data.apexMatchWinrate ?? 0,
      totalWinnings: data.totalWinnings ?? 0,
      trophyCount: data.trophyCount ?? data.trophyData ? data.trophyData.length : 0,
      playerCount: data.playerCount ?? data.players ? data.players.length : 0,
      tournamentsPlayed: data.tournamentsPlayed ??  data.competedTournaments ? data.competedTournaments.length : 0,
      dissolved: data.dissolved ?? false,
      randomIndex: data.randomIndex ?? Math.random(),
      teamName: data.teamName,
      paypalLink: data.paypalLink ?? '',
      teamTag: data.teamTag,
      teamBio: data.teamBio,
      manager: data.manager,
      captain: data.captain,
      region: data.region,
      discordLink: data.discordLink ?? '',
      twitchLink: data.twitchLink ?? '',
      twitterLink: data.twitterLink ?? '',
      websiteLink: data.websiteLink ?? '',
      mainGame: data.mainGame,
      players: data.players,
      playerData: data.playerData ?? [],
      trophyData: data.trophyData ? data.trophyData.map((trophy: TrophyDataRaw) => {
        return {
          ...trophy,
          dateRecived: new Date(trophy.dateRecieved.seconds * 1000)
        }
      }) : [],
      getPlayerObjs: async () => {
        const playerData = await getTeamPlayerData(data.players);
        const undefinedIds = playerData.filter((playerData) => typeof playerData === 'string');
        if (undefinedIds.length > 0) {
          cleanupTeamPlayers({teamId: snapshot.id});
        }
        return playerData.filter((playerData) => typeof playerData !== 'string') as DBUser[];
      },
      pendingPlayers: data.pendingPlayers,
      getPendingPlayerObjs: async () => {
        const playerData = await getTeamPlayerData(data.pendingPlayers);
        const undefinedIds = playerData.filter((playerData) => typeof playerData === 'string');
        if (undefinedIds.length > 0) {
          cleanupTeamPlayers({teamId: snapshot.id});
        }
        return playerData.filter((playerData) => typeof playerData !== 'string') as DBUser[];
      },
      logoUrl: data.logoUrl,
      bannerUrl: data.bannerUrl,
      sponsorLogoUrls: data.sponsorLogoUrls,
      createdAt: data.createdAt.toDate(),
      tournamentsInPlay: data.tournamentsInPlay ?? [],
      activeTournaments: data.activeTournaments ?? [],
      competedTournaments: data.competedTournaments ?? [],
    };
  }
}

export const algoliaTeamConverter = (rawTeam: DocumentData): DBTeam => {
  return {
    id: rawTeam.id ?? '',
    apexMatchNum: rawTeam.apexMatchNum ?? 0,
    apexMatchWins: rawTeam.apexMatchWins ?? 0,
    apexMatchWinrate: rawTeam.apexMatchWinrate ?? 0,
    totalWinnings: rawTeam.totalWinnings ?? 0,
    trophyCount: rawTeam.trophyCount ?? rawTeam.trophyData ? rawTeam.trophyData.length : 0,
    playerCount: rawTeam.playerCount ?? rawTeam.players ? rawTeam.players.length : 0,
    tournamentsPlayed: rawTeam.tournamentsPlayed ??  rawTeam.competedTournaments ? rawTeam.competedTournaments.length : 0,
    dissolved: rawTeam.dissolved ?? false,
    randomIndex: rawTeam.randomIndex ?? Math.random(),
    teamName: rawTeam.teamName,
    paypalLink: rawTeam.paypalLink ?? '',
    teamTag: rawTeam.teamTag,
    teamBio: rawTeam.teamBio,
    manager: rawTeam.manager,
    captain: rawTeam.captain,
    region: rawTeam.region,
    discordLink: rawTeam.discordLink ?? '',
    twitchLink: rawTeam.twitchLink ?? '',
    twitterLink: rawTeam.twitterLink ?? '',
    websiteLink: rawTeam.websiteLink ?? '',
    mainGame: rawTeam.mainGame,
    players: rawTeam.players,
    playerData: rawTeam.playerData ?? [],
    trophyData: rawTeam.trophyData ? rawTeam.trophyData.map((trophy: TrophyDataRaw) => {
      return {
        ...trophy,
        dateRecived: new Date(trophy.dateRecieved as unknown as number)
      }
    }) : [],
    getPlayerObjs: async () => {
      const playerData = await getTeamPlayerData(rawTeam.players);
      const undefinedIds = playerData.filter((playerData) => typeof playerData === 'string');
      if (undefinedIds.length > 0) {
        cleanupTeamPlayers({teamId: rawTeam.id});
      }
      return playerData.filter((playerData) => typeof playerData !== 'string') as DBUser[];
    },
    pendingPlayers: rawTeam.pendingPlayers,
    getPendingPlayerObjs: async () => {
      const playerData = await getTeamPlayerData(rawTeam.pendingPlayers);
      const undefinedIds = playerData.filter((playerData) => typeof playerData === 'string');
      if (undefinedIds.length > 0) {
        cleanupTeamPlayers({teamId: rawTeam.id});
      }
      return playerData.filter((playerData) => typeof playerData !== 'string') as DBUser[];
    },
    logoUrl: rawTeam.logoUrl,
    bannerUrl: rawTeam.bannerUrl,
    sponsorLogoUrls: rawTeam.sponsorLogoUrls,
    createdAt: new Date(rawTeam.createdAt),
    tournamentsInPlay: rawTeam.tournamentsInPlay ?? [],
    activeTournaments: rawTeam.activeTournaments ?? [],
    competedTournaments: rawTeam.competedTournaments ?? [],
  };
}
