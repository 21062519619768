// types
import { LeaderboardTeam, useLeaderboardContext } from '@src/components/tournament/LeaderboardProvider';
// assets
import skullWhite from '@assets/images/tournament/skullWhite.png';
import damageWhite from '@assets/images/tournament/damageWhite.png';
import assistWhite from '@assets/images/tournament/assistWhite.png';
import knockdownWhite from '@assets/images/tournament/knockdownWhite.png';
import reviveWhite from '@assets/images/tournament/reviveWhite.png';
// icons
import { Player } from '@icons/Common';

interface ILeaderboardPlayerData {
  team: LeaderboardTeam
}

const LeaderboardPlayerData: React.FC<ILeaderboardPlayerData> = ({team}) => {
  const { selectedGameView } = useLeaderboardContext();

  const teamPlayersSortedByGameKills = selectedGameView === 0 ? team.players : [...team.players].sort((a, b) => {
    return (b.statsByGame.find((gameStats) => parseInt(gameStats.gameName.split(' ')[1]) === selectedGameView)?.kills ?? 0) -
    (a.statsByGame.find((gameStats) => parseInt(gameStats.gameName.split(' ')[1]) === selectedGameView)?.kills ?? 0);
  });

  return (
    <div className='flex w-full justify-between'>
      <div className='flex w-full flex-col gap-y-6 mt-[50px]'>
        {teamPlayersSortedByGameKills.map((player, index) => {
          const teamPlayerStats = selectedGameView === 0 ? player : (player.statsByGame.find((gameStats) => parseInt(gameStats.gameName.split(' ')[1]) === selectedGameView) ?? {
            name: player.name,
            kills: 0,
            damageDealt: 0,
            assists: 0,
            knockdowns: 0,
            revivesGiven: 0
          });

          return (
            <div key={teamPlayerStats.name} className='w-full flex justify-between'>
              <div className='flex gap-x-3'>
                <div className='flex items-center justify-center bg-lightGray w-[40px] h-auto aspect-square rounded-full border border-steelGray'>
                  <Player className='w-3/4 h-3/4 fill-steelGray'/>
                </div>
                <div>
                  <p className='text-white font-compact font-semibold'>{teamPlayerStats.name}</p>
                  <p className='text-sm font-compact text-steelGray'>In-game name</p>
                </div>
              </div>

              <div className='flex gap-x-6'>

              <div className='relative'>
                {index === 0 ? (
                  <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Kills</p>
                ) : ''}
                <div className='relative flex items-center gap-x-3 h-[30px]'>
                  <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                  <p className='ml-5 font-wide text-white font-semibold uppercase min-w-[20px]'>{teamPlayerStats.kills}</p>
                  <img src={skullWhite} className="h-3/4 w-auto aspect-square mb-1" alt="skull icon" />
                </div>
              </div>

              <div className='relative'>
                {index === 0 ? (
                  <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Damage</p>
                ) : ''}
                <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                  <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                  <p className='ml-5 font-wide text-white font-semibold uppercase min-w-[40px]'>{teamPlayerStats.damageDealt}</p>
                  <img src={damageWhite} className="h-4/5 w-auto aspect-square object-contain mb-1" alt="damage icon" />
                </div>
              </div>

              <div className='relative'>
                {index === 0 ? (
                  <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Assists</p>
                ) : ''}
                <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                  <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                  <p className='ml-5 font-wide text-white font-semibold uppercase'>{teamPlayerStats.assists}</p>
                  <img src={assistWhite} className="h-3/4 w-auto aspect-square mb-1" alt="assist icon" />
                </div>
              </div>

              <div className='relative'>
                {index === 0 ? (
                  <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Knocks</p>
                ) : ''}
                <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                  <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                  <p className='ml-5 font-wide text-white font-semibold uppercase'>{teamPlayerStats.knockdowns}</p>
                  <img src={knockdownWhite} className="h-3/4 w-auto aspect-square mb-1" alt="knockdown icon" />
                </div>
              </div>

              <div className='relative'>
                {index === 0 ? (
                  <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Revives</p>
                ) : ''}
                <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                  <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-red rounded'></div>
                  <p className='ml-5 font-wide text-white font-semibold uppercase'>{teamPlayerStats.revivesGiven}</p>
                  <img src={reviveWhite} className="h-3/4 w-auto aspect-square mb-1" alt="knockdown icon" />
                </div>
              </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default LeaderboardPlayerData;
