import { useState, useEffect } from 'react';
// context
import { usePlayersContext, PlayerResultsPerPage, PlayersRegionOption, PlayersFilterOption, PlayersSortingOption } from '../PlayersProvider';
// types
import { DBUser } from '@src/firestore/users';
// utils
// import { debounce } from '@utils/Debounce';
// components
import PlayerCard from './PlayerCard';
import PaginationBar from '@ui/PaginationBar';
import SearchSortFilterBar from '@src/components/global/SearchSortFilterBarTeamsPlayers';
import SkeletonPlayerCard from './SkeletonPlayerCard';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { VscDebugDisconnect } from 'react-icons/vsc';

const regionDropdownOptions = [
  { value: PlayersRegionOption.ALL, label: "All Regions" },
  { value: PlayersRegionOption.EMEA, label: "EMEA" },
  { value: PlayersRegionOption.NA, label: "NA" },
  { value: PlayersRegionOption.LATAM, label: "LATAM" },
  { value: PlayersRegionOption.APAC, label: "APAC" }
];

const sortingDropdownOptions = [
  { value: PlayersSortingOption.totalWinningsDesc, label: 'Winnings: High - Low'},
  { value: PlayersSortingOption.nameDesc, label: 'Name: A - Z'},
  { value: PlayersSortingOption.tournamentsPlayedDesc, label: 'Tournaments Played: High - Low'},
  { value: PlayersSortingOption.trophiesWonDesc, label: 'Trophies Won: High - Low'},
  { value: PlayersSortingOption.legendMainDesc, label: 'Legend Main: A - Z'},
];

const filterDropdownOptions = [
  {
    value: PlayersFilterOption.pc,
    label: 'PC'
  },
  {
    value: PlayersFilterOption.console,
    label: 'Console'
  },
  {
    value: PlayersFilterOption.mouseKeyboard,
    label: 'Mouse/Keyboard'
  },
  {
    value: PlayersFilterOption.controller,
    label: 'Controller'
  }
];

const MainList = () => {
  const { players,
          searchQuery,
          setSearchQuery,
          searchQueued,
          initiallyLoaded,
          loadMorePlayers,
          loadingMore,
          playersNumber,
          gameOption,
          setGameOption,
          sortingOption,
          setSortingOption,
          regionOption,
          setRegionOption,
          filterOptions,
          setFilterOptions,
          playersCount} = usePlayersContext();
  const [playersInPage, setPlayersInPage] = useState<DBUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [pageNum, setPageNum] = useState<number>(1);
  const [lastPageNum, setLastPageNum] = useState<number>(1);


  const changePage = async (newPageNum: number) => {
    setLoading(true);
    if (newPageNum > 0) {
      const loadedPages = players.length / PlayerResultsPerPage;
      if (newPageNum <= loadedPages) {
        setPageNum(newPageNum);
      } else if (newPageNum <= lastPageNum) {
        const amountNeeded = Math.ceil((newPageNum - loadedPages) * PlayerResultsPerPage);
        await loadMorePlayers(amountNeeded);
        setPageNum(newPageNum);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const pageStartIndex = Math.max(0, ((pageNum - 1) * PlayerResultsPerPage));
    const pageEndIndex = ((pageNum) * PlayerResultsPerPage);
    setPlayersInPage([...players].slice(pageStartIndex, pageEndIndex));
    setLoading(false);
  }, [players, pageNum]);

  useEffect(() => {
    setLastPageNum(Math.ceil(playersNumber / PlayerResultsPerPage));
  }, [playersNumber])

  const gameCheckBoxSubtextValues = {
    'Apex Legends': `${playersCount.apexLegends} players`,
    'Valorant': `${0} players`,
    'Fortnite': `${0} players`,
    'Rocket League': `${0} players`
  }

  return (
    <div className='overflow-hidden px-3 sm:px-5 lg:px-12 custom_container pt-[calc(3rem-4px)]'>

    <SearchSortFilterBar listTitle='players'
                          regionDropdownOptions={regionDropdownOptions}
                          searchEnabled={true}
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          selectedRegionOption={regionOption}
                          setSelectedRegionOption={setRegionOption}
                          selectedGameOption={gameOption}
                          setSelectedGameOption={setGameOption}
                          gameCheckBoxSubtextValues={gameCheckBoxSubtextValues}
                          selectedSortingOption={sortingOption}
                          setSelectedSortingOption={setSortingOption}
                          sortingOptions={sortingDropdownOptions}
                          filterOptions={filterDropdownOptions}
                          selectedFilterOptions={filterOptions}
                          setSelectedFilterOptions={setFilterOptions}
                          initiallyLoaded={initiallyLoaded}
                          searchBarPlaceholder='Search for a player'/>

      {!loading && initiallyLoaded ? (
        <>
          {searchQueued ? (
            <div className='flex flex-col items-center justify-center min-h-[350px]'>
                <ImSpinner8 className='w-[60px] h-auto aspect-square text-green animate-spin'/>
            </div>
          ) : (
            <>
              {playersInPage.length > 0 ? (
                <div className="w-full">
                  <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 mt-8 mb-6">
                    {playersInPage.map((player, index) => (
                      <PlayerCard key={`main-list-${player.displayName}-${player.uid}-${index}`} player={player}/>
                    ))}
                    {loadingMore ? (
                      <>
                        {Array.from({length: 12}).map((_i, index) => (
                          <SkeletonPlayerCard key={`main-list-loader-${index}`}/>
                        ))}
                      </>
                    ) : ''}
                  </div>
                  <PaginationBar className="my-[50px]"
                                 totalPages={lastPageNum}
                                 currentPage={pageNum}
                                 changePage={changePage}/>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center gap-y-2 min-h-[350px]'>
                  <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
                    <VscDebugDisconnect className='text-white text-2xl'/>
                  </div>
                  <p className='font-wide text-white text-2xl font-semibold uppercase'>No players found</p>
                  <p className='text-steelGray font-compact'>Players will show here when found</p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
          {Array.from({length: PlayerResultsPerPage}).map((_i, index) => (
            <SkeletonPlayerCard key={`main-list-loader-${index}`}/>
          ))}
        </div>
      )}
    </div>
  )
}

export default MainList;
