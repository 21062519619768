// types
import { DropDownOption } from "@src/components/ui/DropDown";

export const apexGameModes = ['Battle Royale: Solos', 'Battle Royale: Duos', 'Battle Royale: Trios'];

export const apexGameModesAsDropDownOptions: DropDownOption[] = apexGameModes.map((gameMode, index) => ({
  value: index,
  label: gameMode
}));

export const apexFormats = ['Series', 'Match Point'];

export const apexFormatsAsDropDownOptions: DropDownOption[] = apexFormats.map((format, index) => ({
  value: index,
  label: format
}));
