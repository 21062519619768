import { useState, useContext, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
// firebase
import { functions } from '@src/firebase';
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
// context
import { TeamShowContext } from "../TeamShowProvider";
import { TeamInfoContext } from "../TeamInfoProvider";
import { useAuthContext } from "../../../provider/AuthContextProvider";
// types
import { DBUser } from "../../../firestore/users";
import { DBTeam } from "../../../firestore/teams";
// utils
import { copyDiscordUsername } from "../../../utils/Socials";
// libraries
import { toast } from "react-toastify";
// components
import Modal from "@src/components/ui/Modal";
// assets
import imagePlaceholder from '../../../assets/images/placeholder/placeholder.png';
// icons
import { DiscordIcon, TwitterIcon, TwitchIcon, InstagramIcon, YouTubeIcon } from "../../common/icons/Socials";

const dissolveTeam = httpsCallable(functions, 'dissolveTeam');

// eslint-disable-next-line react-refresh/only-export-components
export const getPlayerTeamTitle = (playerUid: string, team: DBTeam) => {
  if (team) {
    if (playerUid === team.captain) {
      return 'Owner'
    } else if (playerUid === team.manager) {
      return 'Manager'
    } else if (team.players.includes(playerUid)) {
      return 'Player';
    }
  }
  return null;
}

interface ILeaveTeam {
  team: DBTeam,
  leaveTeam: () => void,
}

const LeaveTeam: React.FC<ILeaveTeam> = ({team, leaveTeam}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const inPlay = team.tournamentsInPlay.length > 0;

  return (
    <div className="flex items-center gap-x-3 w-full mt-2">
      {confirm ? (
        <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
          <div className="flex items-center gap-x-2 w-full">
            <button type="button"
                    onClick={() => leaveTeam()}
                    className="w-3/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center  text-red/70 bg-red/20 hover:opacity-75 transition-opacity">
              Confirm
            </button>
            <button type="button"
                    onClick={() => setConfirm(false)}
                    className="w-1/4 font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          {inPlay ? (
            <p className="font-medium font-compact text-red/70 mb-2">
              Can not leave team while it is engaged in a tournament.
            </p>
          ) : ''}
          <button type="button"
                  disabled={inPlay}
                  onClick={() => setConfirm(true)}
                  className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity w-full
                            disabled:opacity-50 disabled:pointer-events-none">
            Leave Team
          </button>
        </div>
      )}
    </div>
  );
}

interface IDissolveTeam {
  team: DBTeam,
  dissolveTeam: () => Promise<HttpsCallableResult<unknown>>,
}

const DissolveTeam: React.FC<IDissolveTeam> = ({team, dissolveTeam}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const inPlay = team.tournamentsInPlay.length > 0;
  const hasActiveTournament = team.activeTournaments.length > 0;

  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const [confirmationValid, setConfirmationValid] = useState<boolean>(false);

  const checkConfirmationValid = useCallback(() => {
    const localConfirmationValid = team !== null && team.teamName === confirmationInput;
    setConfirmationValid(localConfirmationValid);
  }, [team, confirmationInput]);

  useEffect(() => {
    checkConfirmationValid();
  }, [confirmationInput, checkConfirmationValid])

  const handleDissolveTeam = async () => {
    setSubmitting(true);
    const dissolvePromise = dissolveTeam();
    toast.promise(dissolvePromise, {
      success: 'Team dissolved',
      pending: 'Dissolving team',
      error: 'Error dissolving team'
    })
    await dissolvePromise;
    setSubmitting(false);
    setConfirm(false);
  }

  return (
    <div className="flex items-center gap-x-3 w-full mt-2">
      <Modal title="Dissolve Team" open={confirm} setOpen={setConfirm}
             buttonText="Dissolve my Team"
             buttonNegative={true}
             buttonOnClick={handleDissolveTeam}
             buttonDisabled={submitting || inPlay || hasActiveTournament || team.players.length > 1 || !confirmationValid}>
        <div className="flex flex-col gap-y-3">
          <p className="text-red/90 uppercase font-compact text-sm font-thin text-center w-fit mx-auto">
            This action remove all your registrations and team data.
          </p>
          <p className="text-red/90 font-compact text-sm font-thin text-center">To proceed please type <em className="my-2 text-white not-italic p-[6px] bg-lightGray rounded">{team.teamName}</em> into the following box:</p>
          <input type="text"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
                className="w-full p-2 text-white bg-black rounded-lg outline-none border-none"/>
        </div>
      </Modal>
      <div className="w-full">
        {inPlay ? (
            <p className="text-sm font-thin font-compact text-red/70 mx-auto w-fit my-2">
              Can not dissolve team while it is engaged in a tournament.
            </p>
          ) : hasActiveTournament ? (
            <p className="text-sm font-thin font-compact text-red/70 mx-auto w-fit my-2">
              Can not dissolve team while it is enrolled for a tournament.
            </p>
          ) : ''}

        <button type="button"
                disabled={inPlay || hasActiveTournament}
                onClick={() => {
                  if (team.players.length > 1) {
                    toast.error('You must remove all teammates before dissolving a team!')
                  } else {
                    setConfirm(true)
                  }
                }}
                className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity w-full
                          disabled:opacity-50 disabled:pointer-events-none">
          Dissolve Team
        </button>
      </div>
    </div>
  );
}


interface IRemovePlayer {
  player: DBUser,
  removePlayer: (uid: string, name: string) => void,
}

const RemovePlayer: React.FC<IRemovePlayer> = ({removePlayer, player}) => {
  const [confirm, setConfirm] = useState<boolean>(false);

  return (
    <div className="flex items-center gap-x-3">
      {confirm ? (
        <div className="flex flex-col sm:flex-row items-center gap-2">
          <div className="flex items-center gap-x-2">
            <button type="button"
                    onClick={() => removePlayer(player.uid, player.displayName)}
                    className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center  text-red/70 bg-red/20 hover:opacity-75 transition-opacity">
              Confirm
            </button>
            <button type="button"
                    onClick={() => setConfirm(false)}
                    className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <button type="button"
                onClick={() => setConfirm(true)}
                className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity">
          Remove Player
        </button>
      )}
    </div>
  )
}

interface ITeamPlayers {
  setCurrentModalStep: (step: number | null) => void,
}

const TeamPlayers: React.FC<ITeamPlayers> = ({setCurrentModalStep}) => {
  const { user } = useAuthContext();
  const { team, editor } = useContext(TeamShowContext);
  const { cancelPlayerInvite, removePlayer, leaveTeam, teamMember} = useContext(TeamInfoContext);

  const [teamPlayers, setTeamPlayers] = useState<DBUser[]>([]);
  const [teamInvitedPlayers, setTeamInvitedPlayers] = useState<DBUser[]>([]);

  useEffect(() => {
    if (team) {
      const loadPlayersFromUids = async () => {
        const localTeamPlayers = await team?.getPlayerObjs!() as DBUser[];
        setTeamPlayers(localTeamPlayers);
      }

      const loadInvitedPlayersFromUids = async () => {
        const localTeamPlayers = await team?.getPendingPlayerObjs!() as DBUser[];
        setTeamInvitedPlayers(localTeamPlayers);
      }

      loadPlayersFromUids();
      loadInvitedPlayersFromUids();
    }
  }, [team])

  return team ? (
    <>
      <div className="mt-3">
        <h2 className="font-wide font-bold text-white !leading-5 uppercase mb-6">
          Team players
        </h2>

        {/* Mapping through the list of team players */}
        {teamPlayers.map((player: DBUser, index) => (
          <div key={index}>
            <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
              <div
                className={`flex justify-between items-center`}>
                  <Link to={`/profile/${player.uid}`}
                        className="flex items-center gap-x-4 hover:opacity-75 transition-opacity">
                    <img
                      className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                      src={player.displayImage ? player.displayImage : imagePlaceholder}
                      alt="image"
                    />
                    <div className="flex flex-col items-start w-full gap-y-1">
                      <div className="flex items-center gap-x-2">
                        <h2 className="font-wide text-left font-bold text-white leading-4">
                          {player.displayName}
                        </h2>
                        <p className={`font-compact font-semibold bg-ebonyClay px-2 py-[0.1rem] pb-[0.07rem] text-xs mb-[2px] rounded-full
                                      ${getPlayerTeamTitle(player.uid, team) === 'Owner' ? "text-green" : "text-slateGray"}`}>
                          {getPlayerTeamTitle(player.uid, team)}
                        </p>
                      </div>
                      <h3 className="font-compact text-sm text-steelGray">
                        @{player.username}
                      </h3>
                    </div>
                  </Link>
                  <div className="flex items-center gap-x-4">
                    <div className='flex items-center gap-x-3'>
                      {player.discord ? (
                          <button type="button" aria-label="copy discord username"
                                  onClick={() => copyDiscordUsername(player.discord)}>
                            <DiscordIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                          </button>
                        ) : ''}
                      {player.twitter ? (
                        <a href={player.twitter} target="_blank">
                          <TwitterIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.twitch ? (
                        <a href={player.twitch} target="_blank">
                          <TwitchIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.youtube ? (
                        <a href={player.youtube} target="_blank">
                          <YouTubeIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                      {player.instagram ? (
                        <a href={player.instagram} target="_blank">
                          <InstagramIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                        </a>
                      ) : ''}
                    </div>
                    {user && editor && player.uid !== user.uid ? (
                      <RemovePlayer player={player} removePlayer={removePlayer}/>
                    ) : ''}
                  </div>
              </div>
            </div>
          </div>
        ))}
        {editor ? (
          <>
            <button
              type="button"
              onClick={() => setCurrentModalStep(5)}
              className="w-full bg-green uppercase font-semibold common_green_btn text-base !leading-4 px-6
            pt-4 pb-3 rounded-xl font-compact block text-center mt-6">
              Invite players
            </button>
            {teamInvitedPlayers.length > 0 ? (
              <>
                <h2 className="font-wide font-bold text-white !leading-5 uppercase my-6">
                  Pending players
                </h2>
                {teamInvitedPlayers.map((player: DBUser, index) => (
                  <div key={index}>
                    <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
                      <div className={`flex justify-between items-center`}>
                        <Link to={`/profile/${player.uid}`}
                              className="flex items-center gap-x-4 hover:opacity-75 transition-opacity">
                          <img
                            className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                            src={player.displayImage ? player.displayImage : imagePlaceholder}
                            alt="image"
                          />
                          <div className="flex flex-col items-start w-full gap-y-1">
                            <div className="flex items-center gap-x-2">
                              <h2 className="font-wide text-left font-bold text-white leading-4">
                                {player.displayName}
                              </h2>
                            </div>
                            <h3 className="font-compact text-sm text-steelGray">
                              @{player.username}
                            </h3>
                          </div>
                        </Link>
                        <div className="flex items-center gap-x-4">
                          <div className='flex items-center gap-x-3'>
                            {player.discord ? (
                                <button type="button" aria-label="copy discord username"
                                        onClick={() => copyDiscordUsername(player.discord)}>
                                  <DiscordIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                                </button>
                              ) : ''}
                            {player.twitter ? (
                              <a href={player.twitter} target="_blank">
                                <TwitterIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                              </a>
                            ) : ''}
                            {player.twitch ? (
                              <a href={player.twitch} target="_blank">
                                <TwitchIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                              </a>
                            ) : ''}
                            {player.youtube ? (
                              <a href={player.youtube} target="_blank">
                                <YouTubeIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                              </a>
                            ) : ''}
                            {player.instagram ? (
                              <a href={player.instagram} target="_blank">
                                <InstagramIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                              </a>
                            ) : ''}
                          </div>
                          {user && editor ? (
                            <button type="button"
                                  onClick={() => cancelPlayerInvite(player.uid, player.displayName)}
                                  className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-white bg-ebonyClay hover:opacity-75 transition-opacity">
                            Cancel Invite
                          </button>
                          ) : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : ''}
          </>
        ) : ''}
        {teamMember && !editor ? (
          <LeaveTeam team={team} leaveTeam={leaveTeam}/>
        ) : ''}
        {editor ? (
          <DissolveTeam team={team} dissolveTeam={() => {
            return dissolveTeam({teamId: team.id!});
          }}/>
        ) : ''}
      </div>
    </>
  ): '';
};

export default TeamPlayers;
