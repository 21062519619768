import { useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc} from 'firebase/firestore';
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useAuthContext } from '@src/provider/AuthContextProvider';
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@src/components/ui/Modal';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

const Publish = () => {
  const { userObj } = useAuthContext();
  const { tournament } = useTournamentContext();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const announceTournament = async () => {
    setSubmitting(true);
    if (tournament && tournament.hidden && userObj && userObj.admin) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentUpdatePromise = updateDoc(tournamentRef, {
        hidden: false
      })

      toast.promise(tournamentUpdatePromise, {
        pending: 'Publishing tournament',
        success: 'Tournament Published',
        error: 'Error publishing tournament'
      })
      await tournamentUpdatePromise;
    }
    setSubmitting(false);
    setConfirmOpen(false);
  }

  return tournament ? (
    <div>
      <button type="button"
              disabled={!tournament.hidden}
              onClick={() => setConfirmOpen(true)}
              className="flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                        hover:bg-gorse transition-colors disabled:opacity-50 disabled:hover:bg-green">

        <span>
          {!tournament.hidden ? 'Tournament Published' : 'Publish Tournament'}
        </span>
        {!tournament.hidden ? (
          <FaCheck/>
        ) : submitting ? (
          <ImSpinner8 className='animate-spin'/>
        ) : ''}
      </button>
      <Modal title='Publish Tournament'
             open={confirmOpen}
             setOpen={setConfirmOpen}
             buttonDisabled={!tournament.hidden}
             buttonText={(
              <div className='flex items-center gap-x-2'>
                <span>
                  Confirm
                </span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
             )}
             buttonOnClick={announceTournament}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
              <em className="not-italic text-red/70 uppercase"> WARNING! </em>
              Publishing tournament will make it visible to users and cannot be undone.
            </p>
            <p>
              By confirming you acknowledge that all information is correct.
            </p>
            <p>
              Are you sure?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  ) : '';
}

export default Publish;
