// components
import { SkeletonDark } from "@src/components/common/skeleton-loader/Skeletons";

const SkeletonTeamCard = () => {
  return (
    <SkeletonDark className='relative overflow-hidden w-[90%] lg:w-full rounded-xl p-2 flex flex-col group flex-shrink-0 flex-grow-0 transition-all
                             min-h-[220px]'>

    </SkeletonDark>
  )
}

export default SkeletonTeamCard;
