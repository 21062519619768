import { useState, useEffect, useCallback } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { collection, getDocs } from 'firebase/firestore'
// types
import { ApexPointSystem, apexPointSystemConverter } from '@src/firestore/tournamentPointsSystems';
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// utils
import { decodeBase64, encodeBase64 } from '@src/utils/encodingDecoding';
// components
import DropDown, {DropDownOption} from '@ui/DropDown';
import Modal from "@src/components/ui/Modal";
import NumericalInput from '@src/components/ui/NumericalInput';
import Input from '@src/components/ui/Input';
import CheckBox from '@src/components/ui/CheckBox';
// icons
import { BlackArrow } from '@icons/Common';
import { gameFormatOptionsByGame } from '@src/types/Games/Games';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const FormatStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);

  const [apexPointSystems, setApexPointSystems] = useState<ApexPointSystem[]>([]);
  const [apexPointSystemOptions, setApexPointSystemOptions] = useState<DropDownOption[]>([]);

  const [stepValid, setStepValid] = useState<boolean>(true);

  const getApexPointSystems = useCallback(async () => {
    try {
      const apexPointSystemsCollection = collection(firestore, 'apexTournamentPointSystems').withConverter(apexPointSystemConverter);
      const localApexPointSystems = (await getDocs(apexPointSystemsCollection)).docs.map((doc) => doc.data());
      setApexPointSystems(localApexPointSystems);
      if (localApexPointSystems.length > 0) {
        setTournamentInfo({
          ...tournamentInfo,
          pointSystem: localApexPointSystems[0].id
        })
      }
    } catch (err) {
      console.error(err);
    }
  }, [tournamentInfo]);

 useEffect(() => {
  const apexPointSystemsAsOptions = apexPointSystems.map((pointSystem, index) => ({
    value: index,
    label: pointSystem.name
  }));
  setApexPointSystemOptions(apexPointSystemsAsOptions);
 }, [apexPointSystems]);

 useEffect(() => {
   if (tournamentInfo.teamCapacity > 20 && tournamentInfo.interStages === -1) {
    setTournamentInfo({
      ...tournamentInfo,
      interStages: 0,
    })
   } else if (tournamentInfo.teamCapacity <= 20 && tournamentInfo.interStages > -1) {
    setTournamentInfo({
      ...tournamentInfo,
      interStages: -1,
    })
   }
 }, [tournamentInfo]);

  useEffect(() => {
    getApexPointSystems();
  }, [getApexPointSystems]);

  useEffect(() => {
    if (tournamentInfo.format && tournamentInfo.pointSystem && tournamentInfo.rules) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo]);

  return (
    <Modal title="Format"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={4}
           scroll={true}
           totalSteps={10}
           onContainerLoad={(container) => setModalContainer(container)}
           open={currentStep === CreationFlowPage.format}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.game)}
           buttonOnClick={() => changeStep(CreationFlowPage.entry)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start w-full">

        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Format</p>
          <DropDown currentOption={gameFormatOptionsByGame(tournamentInfo.game).find((option) => option.label === tournamentInfo.format) ?? gameFormatOptionsByGame(tournamentInfo.game)[0]}
                    options={gameFormatOptionsByGame(tournamentInfo.game)}
                    handleOnChange={(newValue) => {
                      const newGameValue = gameFormatOptionsByGame(tournamentInfo.game).find((option) => option.value === newValue)!.label;
                      setTournamentInfo({
                        ...tournamentInfo,
                        format: newGameValue
                      })
                    }}
                    black={true}/>
        </div>

        {tournamentInfo.format === 'Match Point' ? (
          <div className='flex items-center gap-x-4'>

             <div className='flex flex-col gap-y-2 text-start items-start'>
              <p className='text-sm text-steelGray font-compact'>Match Point Threshold</p>
              <NumericalInput value={tournamentInfo.matchPointFormatThreshold}
                              interval={1}
                              onChange={(newValue) => {
                                setTournamentInfo({
                                  ...tournamentInfo,
                                  matchPointFormatThreshold: newValue
                                })
                              }}
                              black={true}/>
            </div>

            <div className='flex flex-col gap-y-2 text-start items-start'>
              <p className='text-sm text-steelGray font-compact'>Match Point Max Games</p>
              <NumericalInput value={tournamentInfo.matchPointFormatMaxGames}
                              interval={1}
                              onChange={(newValue) => {
                                setTournamentInfo({
                                  ...tournamentInfo,
                                  matchPointFormatMaxGames: newValue
                                })
                              }}
                              black={true}/>
            </div>

          </div>
        ) : ''}

        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Team Capacity</p>
          <NumericalInput value={tournamentInfo.teamCapacity}
                          interval={20}
                          minValue={20}
                          maxValue={160}
                          onChange={(newValue) => {
                            setTournamentInfo({
                              ...tournamentInfo,
                              teamCapacity: newValue
                             })
                          }}
                          black={true}/>
        </div>

        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Stages</p>
          <NumericalInput value={tournamentInfo.interStages + 2}
                          minValue={1}
                          maxValue={3}
                          onChange={(newValue) => {
                            setTournamentInfo({
                              ...tournamentInfo,
                              interStages: newValue - 2
                             })
                          }}
                          black={true}/>
        </div>


        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p className='text-sm text-steelGray font-compact'>Server Type</p>
          <button onClick={() => setTournamentInfo({
                        ...tournamentInfo,
                        highPerformance: !tournamentInfo.highPerformance
                      })}
                  className={`flex gap-x-2 items-center font-compact bg-lightGray transition-all rounded-lg p-2`}>
            <span className={`${tournamentInfo.highPerformance ? 'text-white' : 'text-steelGray'} transition-colors translate-y-[1px]`}>High Performance</span>
            <CheckBox selected={tournamentInfo.highPerformance}
                      setSelected={(newValue) => setTournamentInfo({
                        ...tournamentInfo,
                        highPerformance: newValue
                      })}
                      small={true} asDiv={true} interactive={false}/>
          </button>
        </div>

        {apexPointSystemOptions.length > 0 ? (
          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Point System</p>
            <DropDown currentOption={apexPointSystemOptions.find((pointSystemOption) => apexPointSystems.find((pointSystem) => pointSystem.name === pointSystemOption.label) ?? false) ?? apexPointSystemOptions[0]}
                      options={apexPointSystemOptions}
                      handleOnChange={(newValue) => {
                        const pointSystemId = apexPointSystems.find((pointSystem) => pointSystem.name === apexPointSystemOptions.find((option) => option.value === newValue)?.label)?.id ?? '';
                        setTournamentInfo({
                          ...tournamentInfo,
                          pointSystem: pointSystemId
                        })
                      }}
                      black={true}
                      containerToScroll={modalContainer}/>
          </div>
        ) : ''}

        <div className='flex flex-col gap-y-2 text-start items-start w-full'>
          <p className='text-sm text-steelGray font-compact'>Rules</p>
          <Input value={decodeBase64(tournamentInfo.rules)}
                 onChange={(newValue) => setTournamentInfo({
                  ...tournamentInfo,
                  rules: encodeBase64(newValue)
                 })}
                 textArea={true} black={true} wrapperClassName='w-full'/>
        </div>

      </div>
    </Modal>
  )
}

export default FormatStep;
