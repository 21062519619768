// assets
// Player Card Backgrounds
import ashBackground from '@assets/images/apex/playerCardBackgrounds/ashBanner.png';
import alterBackground from '@assets/images/apex/playerCardBackgrounds/alterBanner.png';
import ballisticBackground from '@assets/images/apex/playerCardBackgrounds/ballisticBanner.png';
import bangaloreBackground from '@assets/images/apex/playerCardBackgrounds/bangaloreBanner.png';
import bloodhoundBackground from '@assets/images/apex/playerCardBackgrounds/bloodhoundBanner.png';
import catalystBackground from '@assets/images/apex/playerCardBackgrounds/catalystBanner.png';
import causticBackground from '@assets/images/apex/playerCardBackgrounds/causticBanner.png';
import conduitBackground from '@assets/images/apex/playerCardBackgrounds/conduitBanner.png';
import cryptoBackground from '@assets/images/apex/playerCardBackgrounds/cryptoBanner.png';
import fuseBackground from '@assets/images/apex/playerCardBackgrounds/fuseBanner.png';
import gibraltarBackground from '@assets/images/apex/playerCardBackgrounds/gibraltarBanner.png';
import horizonBackground from '@assets/images/apex/playerCardBackgrounds/horizonBanner.png';
import lifelineBackground from '@assets/images/apex/playerCardBackgrounds/lifelineBanner.png';
import lobaBackground from '@assets/images/apex/playerCardBackgrounds/lobaBanner.png';
import madmaggieBackground from '@assets/images/apex/playerCardBackgrounds/madmaggieBanner.png';
import mirageBackground from '@assets/images/apex/playerCardBackgrounds/mirageBanner.png';
import newcastleBackground from '@assets/images/apex/playerCardBackgrounds/newcastleBanner.png';
import octaneBackground from '@assets/images/apex/playerCardBackgrounds/octaneBanner.png';
import pathfinderBackground from '@assets/images/apex/playerCardBackgrounds/pathfinderBanner.png';
import rampartBackground from '@assets/images/apex/playerCardBackgrounds/rampartBanner.png';
import revenantBackground from '@assets/images/apex/playerCardBackgrounds/revenantBanner.png';
import seerBackground from '@assets/images/apex/playerCardBackgrounds/seerBanner.png';
import valkyrieBackground from '@assets/images/apex/playerCardBackgrounds/valkyrieBanner.png';
import wattsonBackground from '@assets/images/apex/playerCardBackgrounds/wattsonBanner.png';
import wraithBackground from '@assets/images/apex/playerCardBackgrounds/wraithBanner.png';
import vantageBackground from '@assets/images/apex/playerCardBackgrounds/vantageBanner.png';

// Legends
import ashHead from '@assets/images/apex/Legends/Ash Head.png';
import alterHead from '@assets/images/apex/Legends/Alter Head.png';
import ballisticHead from '@assets/images/apex/Legends/Ballistic Head.png';
import bangaloreHead from '@assets/images/apex/Legends/Bangalore Head.png';
import bloodhoundHead from '@assets/images/apex/Legends/Bloodhound Head.png';
import catalystHead from '@assets/images/apex/Legends/Catalyst Head.png';
import causticHead from '@assets/images/apex/Legends/Caustic Head.png';
import conduitHead from '@assets/images/apex/Legends/Conduit Head.png';
import cryptoHead from '@assets/images/apex/Legends/Crypto Head.png';
import fuseHead from '@assets/images/apex/Legends/Fuse Head.png';
import gibraltarHead from '@assets/images/apex/Legends/Gibraltar Head.png';
import horizonHead from '@assets/images/apex/Legends/Horizon Head.png';
import lifelineHead from '@assets/images/apex/Legends/Lifeline Head.png';
import lobaHead from '@assets/images/apex/Legends/Loba Head.png';
import maggieHead from '@assets/images/apex/Legends/Mad Maggie Head.png';
import mirageHead from '@assets/images/apex/Legends/Mirage Head.png';
import newcastleHead from '@assets/images/apex/Legends/Newcastle Head.png';
import octaneHead from '@assets/images/apex/Legends/Octane Head.png';
import pathfinderHead from '@assets/images/apex/Legends/Pathfinder Head.png';
import rampartHead from '@assets/images/apex/Legends/Rampart Head.png';
import revenantHead from '@assets/images/apex/Legends/Revenant Head.png';
import seerHead from '@assets/images/apex/Legends/Seer Head.png';
import valkyrieHead from '@assets/images/apex/Legends/Valkyrie Head.png';
import wattsonHead from '@assets/images/apex/Legends/Wattson Head.png';
import wraithHead from '@assets/images/apex/Legends/Wraith Head.png';
import vantageHead from '@assets/images/apex/Legends/Vantage Head.png';
// Weapons
import _3030White from '@assets/images/apex/Weapons/3030 white.png';
import alternatorWhite from '@assets/images/apex/Weapons/Alternator white.png';
import arcStarWhite from '@assets/images/apex/Weapons/Arc star white.png';
import bocekWhite from '@assets/images/apex/Weapons/Bocek white.png';
import carSmgWhite from '@assets/images/apex/Weapons/CarSMG white.png';
import chargeRifleWhite from '@assets/images/apex/Weapons/Charge Rifle white.png';
import devotionWhite from '@assets/images/apex/Weapons/Devotion white.png';
import eva8White from '@assets/images/apex/Weapons/Eva8 white.png';
import flatlineWhite from '@assets/images/apex/Weapons/Flatline White.png';
import fragWhite from '@assets/images/apex/Weapons/Frag white.png';
import g7ScoutWhite from '@assets/images/apex/Weapons/G7 Scout white.png';
import havocWhite from '@assets/images/apex/Weapons/Havoc white.png';
import hemlokWhite from '@assets/images/apex/Weapons/Hemlok white.png';
import kraberWhite from '@assets/images/apex/Weapons/Kraber white.png';
import longbowWhite from '@assets/images/apex/Weapons/Longbow white.png';
import lStarWhite from '@assets/images/apex/Weapons/LStar white.png';
import mastiffWhite from '@assets/images/apex/Weapons/Mastiff white.png';
import meleeWhite from '@assets/images/apex/Weapons/melee white.png';
import mozambiqueWhite from '@assets/images/apex/Weapons/Mozambique white.png';
import nemesisWhite from '@assets/images/apex/Weapons/Nemesis white.png';
import p2020White from '@assets/images/apex/Weapons/P2020 white.png';
import pkWhite from '@assets/images/apex/Weapons/PK white.png';
import prowlerWhite from '@assets/images/apex/Weapons/Prowler white.png';
import r99White from '@assets/images/apex/Weapons/R99 white.png';
import r301White from '@assets/images/apex/Weapons/r301 white.png';
import rampageWhite from '@assets/images/apex/Weapons/Rampage white.png';
import re45White from '@assets/images/apex/Weapons/RE-45 white.png';
import sentinelWhite from '@assets/images/apex/Weapons/Sentinel white.png';
import spitfireWhite from '@assets/images/apex/Weapons/Spitfire white.png';
import thermiteWhite from '@assets/images/apex/Weapons/Thermite white.png';
import tripletakeWhite from '@assets/images/apex/Weapons/TripleTake white.png';
import voltWhite from '@assets/images/apex/Weapons/Volt White.png';
import wingmanWhite from '@assets/images/apex/Weapons/Wingman white.png';


interface WeaponNameToImport {
  [key: string]: string;
}

const weaponNameToImport: WeaponNameToImport = {
  "Arc Star": arcStarWhite,
  "Melee": meleeWhite,
  "Thermite": thermiteWhite,
  "Frag": fragWhite,
  "30-30 Repeater": _3030White,
  "Alternator SMG": alternatorWhite,
  "Bocek Compound Bow": bocekWhite,
  "C.A.R. SMG": carSmgWhite,
  "Charge Rifle": chargeRifleWhite,
  "Devotion LMG": devotionWhite,
  "EVA-8 Auto": eva8White,
  "G7 Scout": g7ScoutWhite,
  "HAVOC Rifle": havocWhite,
  "Hemlok Burst AR": hemlokWhite,
  "Kraber .50-Cal Sniper": kraberWhite,
  "L-STAR EMG": lStarWhite,
  "Longbow DMR": longbowWhite,
  "M600 Spitfire": spitfireWhite,
  "Mastiff Shotgun": mastiffWhite,
  "Mozambique Shotgun": mozambiqueWhite,
  "Nemesis Burst AR": nemesisWhite,
  "P2020": p2020White,
  "Peacekeeper": pkWhite,
  "Prowler Burst PDW": prowlerWhite,
  "R-301 Carbine": r301White,
  "R-99 SMG": r99White,
  "RE-45 Auto": re45White,
  "Rampage LMG": rampageWhite,
  "Sentinel": sentinelWhite,
  "Triple Take": tripletakeWhite,
  "VK-47 Flatline": flatlineWhite,
  "Volt SMG": voltWhite,
  "Wingman": wingmanWhite
};

export const getWeaponImage = (weaponName: string) => {
  return weaponNameToImport[weaponName] !== undefined ? weaponNameToImport[weaponName] : '';
}

export const apexLegendNames = [
  "Alter",
  "Ash",
  "Bangalore",
  "Ballistic",
  "Bloodhound",
  "Caustic",
  "Catalyst",
  "Conduit",
  "Crypto",
  "Fuse",
  "Gibraltar",
  "Horizon",
  "Lifeline",
  "Loba",
  "Mad Maggie",
  "Mirage",
  "Newcastle",
  "Octane",
  "Pathfinder",
  "Rampart",
  "Revenant",
  "Seer",
  "Valkyrie",
  "Vantage",
  "Wattson",
  "Wraith"
];

export const apexLegendWeaponNames = [
  "30-30 Repeater",
  "Alternator SMG",
  "Bocek Compound Bow",
  "C.A.R. SMG",
  "Charge Rifle",
  "Devotion LMG",
  "EVA-8 Auto",
  "G7 Scout",
  "HAVOC Rifle",
  "Hemlok Burst AR",
  "Kraber .50-Cal Sniper",
  "L-STAR EMG",
  "Longbow DMR",
  "M600 Spitfire",
  "Mastiff Shotgun",
  "Mozambique Shotgun",
  "Nemesis Burst AR",
  "P2020",
  "Peacekeeper",
  "Prowler Burst PDW",
  "R-301 Carbine",
  "R-99 SMG",
  "RE-45 Auto",
  "Rampage LMG",
  "Sentinel",
  "Triple Take",
  "VK-47 Flatline",
  "Volt SMG",
  "Wingman"
];


export function getLegendHeadshot(legend: string) {
  interface LegendHeadshots {
    [key: string]: string;
  }

  const legendHeadshots: LegendHeadshots = {
      'ash': ashHead,
      'alter': alterHead,
      'ballistic': ballisticHead,
      'bangalore': bangaloreHead,
      'bloodhound': bloodhoundHead,
      'catalyst': catalystHead,
      'caustic': causticHead,
      'conduit': conduitHead,
      'crypto': cryptoHead,
      'fuse': fuseHead,
      'gibraltar': gibraltarHead,
      'horizon': horizonHead,
      'lifeline': lifelineHead,
      'loba': lobaHead,
      'madmaggie': maggieHead,
      'mirage': mirageHead,
      'newcastle': newcastleHead,
      'octane': octaneHead,
      'pathfinder': pathfinderHead,
      'rampart': rampartHead,
      'revenant': revenantHead,
      'seer': seerHead,
      'valkyrie': valkyrieHead,
      'wattson': wattsonHead,
      'wraith': wraithHead,
      'vantage': vantageHead
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendHeadshots[formattedLegend]) {
      return legendHeadshots[formattedLegend];
  } else {
      return 'Image not found';
  }
}

export function getLegendBackground(legend: string) {
  interface LegendBackgrounds {
    [key: string]: string;
  }

  const legendBackgrounds: LegendBackgrounds = {
      'ash': ashBackground,
      'alter': alterBackground,
      'ballistic': ballisticBackground,
      'bangalore': bangaloreBackground,
      'bloodhound': bloodhoundBackground,
      'catalyst': catalystBackground,
      'caustic': causticBackground,
      'conduit': conduitBackground,
      'crypto': cryptoBackground,
      'fuse': fuseBackground,
      'gibraltar': gibraltarBackground,
      'horizon': horizonBackground,
      'lifeline': lifelineBackground,
      'loba': lobaBackground,
      'madmaggie': madmaggieBackground,
      'mirage': mirageBackground,
      'newcastle': newcastleBackground,
      'octane': octaneBackground,
      'pathfinder': pathfinderBackground,
      'rampart': rampartBackground,
      'revenant': revenantBackground,
      'seer': seerBackground,
      'valkyrie': valkyrieBackground,
      'wattson': wattsonBackground,
      'wraith': wraithBackground,
      'vantage': vantageBackground
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendBackgrounds[formattedLegend]) {
      return legendBackgrounds[formattedLegend];
  } else {
      return 'Image not found';
  }
}
