import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// components
import Modal from "@src/components/ui/Modal";
// icons
import { BlackArrow } from '@icons/Common';
import Input from '@src/components/ui/Input';
import { ImSpinner8 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  savePreset: () => Promise<boolean>,
  stepNames: string[]
}

const SavePresetStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, savePreset, stepNames}) => {
  const [presetNameValid, setPresetNameValid] = useState<boolean>(false);
  const [submittingPreset, setSubmittingPreset] = useState<boolean>(false);

  const [stepValid] = useState<boolean>(true);

  useEffect(() => {
    const presetNameRegex = /[a-zA-Z-_0-9.]{3,}/
    if (tournamentInfo.presetName === '' || presetNameRegex.test(tournamentInfo.presetName)) {
      setPresetNameValid(true);
    } else {
      setPresetNameValid(false);
    }
  }, [tournamentInfo]);

  const handleSavePreset = async () => {
    setSubmittingPreset(true);
    await savePreset();
    setSubmittingPreset(false);
  }

  return (
    <Modal title="Save Preset"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={9}
           totalSteps={10}
           open={currentStep === CreationFlowPage.savePreset}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.date)}
           buttonOnClick={() => changeStep(CreationFlowPage.review)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start">
        <div className={`${tournamentInfo.presetSaved ? 'pointer-events-none opacity-50' : ''} w-full`}>
          <Input label='Preset Name'
                valid={presetNameValid}
                value={tournamentInfo.presetName}
                onChange={(newValue) => setTournamentInfo({
                  ...tournamentInfo,
                  presetName: newValue
                })}
                black={true}/>
        </div>
        <button type='button' aria-label='Load Tournament Preset'
                disabled={submittingPreset || tournamentInfo.presetSaved || (tournamentInfo.presetName !== '' && !presetNameValid)}
                onClick={handleSavePreset}
                className='flex items-center justify-center gap-x-2 w-full p-2 text-green font-compact uppercase font-semibold rounded-lg
                          bg-ebonyClay hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-all'>
          <span className='pt-[2px]'>
            {tournamentInfo.presetSaved ? 'Preset Saved' : 'Save Preset'}
          </span>
          {submittingPreset ? (
            <ImSpinner8 className='animate-spin'/>
          ) : tournamentInfo.presetSaved ? (
            <FaCheck/>
          ) : ''}
        </button>
      </div>
    </Modal>
  )
}

export default SavePresetStep;
