import { useEffect, useState } from 'react'
// context
import { useTournamentContext } from '@src/components/tournament/TournamentProvider';
// types
import { LeaderboardStage } from '../LeaderboardProvider';
// components
import GroupMaps from './GroupMaps';

interface IStageMaps {
  stage: LeaderboardStage,
}

const StageMaps: React.FC<IStageMaps> = ({stage}) => {
  const groups = stage.groups.sort((a, b) => a.groupName.split(' ')[1].localeCompare(b.groupName.split(' ')[1]));

  const { tournament } = useTournamentContext();

  const finalStage = tournament !== null && (stage.stageNum === tournament.totalStages - 1);

  const [initialGroupFocused, setInitialGroupFocused] = useState<boolean>(false);
  const [currentGroup, setCurrentGroup] = useState<string>('');

  useEffect(() => {
    if (!initialGroupFocused && groups.length > 0) {
      setCurrentGroup(groups[0].groupId);
      setInitialGroupFocused(true);
    }
  }, [groups, initialGroupFocused]);

  return (
    <div>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <div className='flex items-center gap-x-2 w-full'>
          {finalStage ? '' : (
            <div className='flex items-center gap-x-2'>
              {groups.map((group) => (
                <button key={`maps-group-${group.groupId}`} type="button"
                        onClick={() => setCurrentGroup(group.groupId)}
                        className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                    ${currentGroup === group.groupId ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
                  {group.groupName}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={`flex ${finalStage ? '' : 'mt-6'}`}>
        <div className='w-full'>
          <div>
            {groups.map((group) => (
              <div key={`maps-group-view-${group.groupId}`}>
              {currentGroup === group.groupId ? (
                <GroupMaps key={group.groupId} group={group}/>
              ) : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StageMaps;
