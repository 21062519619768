// context
import { useTournamentContext } from "../TournamentProvider";
// types
import { DBTeam, PlayerData } from '@src/firestore/teams';
// components
import EntrantsTeamCard from "./EntrantsTeamCard";
// icons
import { FaInfo } from "react-icons/fa6";
import { TournamentTeamStatus, TournamentStatus } from "@src/firestore/tournaments";

export interface TeamObjWithStatus extends DBTeam {
  status: TournamentTeamStatus,
  participatingPlayers: string[],
  participatingPlayerData: PlayerData[]
}

const Entrants = () => {
  const { tournament, tournamentTeams, tournamentTeamObjs } = useTournamentContext();

  const orderedTournamentTeams = tournamentTeams.sort((a, b) => {
    return a.joinedAt.seconds - b.joinedAt.seconds;
  });

  const nonWaitingListTeams: TeamObjWithStatus[] = orderedTournamentTeams.filter((team) => !team.fromWaitingList && tournamentTeamObjs.some((teamObj) => teamObj.id === team.id)).map((team) => {
    const teamObj = tournamentTeamObjs.find((teamObj) => teamObj.id === team.id)!;
    return {
      ...teamObj,
      status: team.status,
      participatingPlayers: team.participatingPlayers,
      participatingPlayerData: team.participatingPlayerData,
    } as TeamObjWithStatus;
  });

  const waitingListTeams: TeamObjWithStatus[] = orderedTournamentTeams.filter((team) => team.fromWaitingList && tournamentTeamObjs.some((teamObj) => teamObj.id === team.id)).map((team) => {
    const teamObj = tournamentTeamObjs.find((teamObj) => teamObj.id === team.id)!;
    return {
      ...teamObj,
      status: team.status,
      participatingPlayers: team.participatingPlayers,
      participatingPlayerData: team.participatingPlayerData,
    } as TeamObjWithStatus;
  });

  return tournament ? (
    <div className="px-4 sm:px-8 lg:px-12 mt-10 pb-3">
      <h2 className="font-wide font-bold text-2xl !leading-6 uppercase text-white flex items-start gap-2">
        <span>Entrants</span>
        <span className="text-green font-wide font-bold text-sm !leading-4 px-[0.4rem] pt-1.5 pb-[0.1875rem] bg-lightGray rounded-lg mb-1 -translate-y-[2px]">
          {nonWaitingListTeams.length}
        </span>
        {waitingListTeams.length > 0 ? (
          <span className='text-steelGray text-sm'>
            + {waitingListTeams.length} {tournament.status > TournamentStatus.confirmation ? 'from' : 'in'} waiting list
          </span>
        ) : ''}
      </h2>
      {nonWaitingListTeams.length > 0 ? (
        <>
          {nonWaitingListTeams.map((team) => (
            <EntrantsTeamCard key={`registered-entrant-${team.id}`} team={team}/>
          ))}
        </>
      ) : (
        <div className='flex flex-col items-center mt-10 gap-y-2'>
          <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
            <FaInfo className='text-white text-2xl'/>
          </div>
          <p className='font-wide text-white text-2xl font-semibold uppercase'>No Entrants</p>
          <p className='text-steelGray font-compact'>Entrants will show here as the tournament populates</p>
        </div>
      )}

      {nonWaitingListTeams.length > 0 && waitingListTeams.length > 0 ? (
        <>
        <h2 className="font-wide font-bold text-2xl !leading-6 uppercase text-white flex items-center gap-2 mt-6">
          <span>Waiting List</span>
          <span className="text-green font-wide font-bold text-sm !leading-4 px-[0.4rem] pt-1.5 pb-[0.1875rem] bg-lightGray rounded-lg mb-1">
            {waitingListTeams.length}
          </span>
        </h2>
        {waitingListTeams.map((team) => (
          <EntrantsTeamCard key={`waiting-entrant-${team.id}`} team={team}/>
        ))}
      </>
      ) : ''}
    </div>
  ): '';
};

export default Entrants;
