import { ReactNode, useState, useEffect, useCallback  } from "react";
import { Link } from "react-router-dom";
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
// components
import MiniTeamCard from "./MiniTeamCard";
// icons
import { DiscordSocialIcon } from "@icons/TournamentIcons";
import { Flag } from "@icons/Common";
import { FaClock } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import PopoverText from "@src/components/ui/PopoverText";

const PrepViews = () => {
  const { userTeam } = useAuthContext();

  const {
    tournament,
    userTournamentState,
  } = useTournamentContext();

  // button state
  const [buttonStyle, setButtonStyle] = useState<string>('');
  const [buttonInfo, setButtonInfo] = useState<ReactNode>('');
  const [buttonInner, setButtonInner] = useState<ReactNode | string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [secondaryButton] = useState<ReactNode>('');
  const [buttonOnClick] = useState<() => void>(() => () => false);
  const [buttonPopoverText, setButtonPopoverText] = useState<string>('');

  const setButtonState = useCallback(() => {
    if (!tournament) return;

    setButtonInfo('');
    setButtonInner('');
    setButtonPopoverText('');
    setButtonDisabled(false);

    if (userTournamentState.ignored) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonPopoverText('Repeat offenses affect future eligibility');
      setButtonStyle('!bg-red/70 hover:!bg-red/70');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Ignored
          </span>
          <span>
            <Flag className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.declined) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonStyle('!bg-steelGray hover:!bg-steelGray');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Declined
          </span>
          <span>
            <FaXmark className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else {
      if (userTournamentState.confirmed) setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>);

      setButtonInner(
        <>
         <span className="-mb-1">
            Tournament Starting soon
          </span>
          <span>
            <FaClock className=" text-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    }

  }, [tournament, userTeam, userTournamentState]);

  useEffect(() => {
    setButtonState();
  }, [tournament, userTournamentState, setButtonState]);

  return (
    <>
      <div className='w-1/2 h-full flex-grow flex flex-col items-center gap-y-8 justify-end'>
        {buttonInfo}
        <div className="w-full flex items-center gap-x-2">
          <Link to={tournament && tournament.discordSupport ? tournament.discordSupport : "https://discord.gg/versus-gg"}
            target="_blank"
            className="xl:hidden p-2 bg-lightGray rounded-xl h-[40px] w-auto aspect-square flex items-center justify-center">
            <DiscordSocialIcon />
          </Link>
          <div className="relative group w-5/6 flex-grow">
            {buttonPopoverText ? (
              <PopoverText right={true} visible={true} className="opacity-0 group-hover:opacity-100">
                {buttonPopoverText}
              </PopoverText>
            ) : ''}
            <button className={`font-compact font-semibold text-base !leading-4 text-black uppercase w-full h-[40px]
                            flex gap-2 items-center justify-center py-[0.8125rem] bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green opacity rounded-xl transition-opacity
                            ${buttonStyle}`}
              disabled={buttonDisabled}
              onClick={buttonOnClick}>
              {buttonInner}
            </button>
          </div>
          {secondaryButton}
        </div>
      </div>
    </>
  );

}

export default PrepViews;
