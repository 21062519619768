import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from "../TournamentProvider";
// types
import { TournamentGroup } from '@src/firestore/tournaments';
import { DBTeam } from "@src/firestore/teams";
import { LeaderboardGroup } from "../LeaderboardProvider";
// assets
import worldsEdge from '@assets/images/apex/maps/WE.png';
import stormPoint from '@assets/images/apex/maps/SP.png';
import olympus from '@assets/images/apex/maps/OL.png';
import brokenMoon from '@assets/images/apex/maps/BM.png';
import kingsCanyon from '@assets/images/apex/maps/KC.png';
import eDistrict from '@assets/images/apex/maps/ED.png';
// icons
import { FaCheck, FaCrown } from "react-icons/fa";
import { SwordsIcon } from "@src/components/common/icons/TournamentIcons";
import { VscDebugDisconnect } from "react-icons/vsc";
import { MatchPointPlannedMap } from '@src/firestore/tournaments';

const getMapImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdge;
    case 'BM':
      return brokenMoon;
    case 'KC':
      return kingsCanyon;
    case 'SP':
      return stormPoint;
    case 'OL':
      return olympus;
    case 'ED':
      return eDistrict;
    default:
      return '';
  }
}


const getMapNameFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return "World's Edge";
    case 'BM':
      return "Broken Moon";
    case 'KC':
      return "King's Canyon";
    case 'SP':
      return "Storm Point";
    case 'OL':
      return "Olympus";
    case 'ED':
      return "E-District";
    default:
      return '';
  }
}


interface IGameMapCard {
  winningTeam: DBTeam | null,
  gameName: string,
  mapAcronym: string,
  groupStarted: boolean,
  gameCompleted: boolean,
  gameInProgress: boolean,
  matchPointWin: boolean
}

const GameMapCard: React.FC<IGameMapCard> = ({gameName, mapAcronym, groupStarted, gameCompleted, gameInProgress, winningTeam, matchPointWin}) => {
  return (
    <div className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.3] rounded-xl bg-lightBlack p-4
                     border ${gameInProgress && groupStarted ? 'border-green' : 'border-transparent'}`}>
      <div className="relative h-4/5 rounded-lg w-full overflow-hidden">
        {winningTeam ? (
          <div className="w-full flex flex-col gap-y-3 items-center z-[2] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <p className="text-white font-wide font-semibold uppercase text-lg w-full text-center">
              {winningTeam.teamName}
            </p>
            <div className="w-[55%] h-auto aspect-square rounded-lg overflow-hidden border border-white bg-lightBlack">
              <img src={winningTeam.logoUrl} alt="winning team logo"
                  className="w-full h-full object-cover " />
            </div>
          </div>
        ) : ''}
        <img src={getMapImageFromAcronym(mapAcronym)} alt="World's Edge Apex Map"
          className={`w-full h-full object-cover ${gameCompleted ? 'opacity-50' : ''} transition-opacity`}/>
      </div>
      <div className='flex items-center h-1/5 w-full justify-between'>
        <div className='flex flex-col items-start'>
          <p className={`font-wide font-semibold text-white uppercase`}>{getMapNameFromAcronym(mapAcronym)}</p>
          <p className={`flex items-center gap-x-2
                         font-compact ${gameInProgress && groupStarted ? 'text-green' : 'text-steelGray'}`}>
            <span>{gameName}</span>
            {gameInProgress && groupStarted ? (
              <SwordsIcon className="w-[14px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            )
            : gameCompleted ? matchPointWin ? (
              <FaCrown className="w-[12px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            ): (
              <FaCheck className="w-[12px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            ) : ''}
          </p>
        </div>
      </div>
    </div>
  );
}

interface IGroupMaps {
  group: LeaderboardGroup
}

const GroupMaps: React.FC<IGroupMaps> = ({group}) => {
  const { tournament, tournamentTeams, tournamentStages } = useTournamentContext();

  const [tournamentGroup, setTournamentGroup] = useState<TournamentGroup | null>(null);

  const finalStage = tournament !== null && group.stageNum === tournament.totalStages - 1;

  useEffect(() => {
    const stageNum = group.stageNum;
    const groupId = group.groupId;

    if (tournamentStages.length > 0) {
      const localTournamentGroup = tournamentStages.find((stage) => stage.stageNum === stageNum)?.groups.find((group) => group.id === groupId) ?? null;
      setTournamentGroup(localTournamentGroup);
    }

  }, [group, tournamentStages]);

  const [plannedMaps, setPlannedMaps] = useState<MatchPointPlannedMap[]>([]);

  useEffect(() => {
    if (tournament && tournament.format === 'Match Point') {
      setPlannedMaps(tournament.matchPointFormatPlannedMaps)
    }
  }, [tournament])

  const activeGroupGame = tournamentGroup ? tournamentGroup.activeGame : 0;

  const leaderboardGames = group.games;

  const getWinningTeamFromGame = (gameId: string): DBTeam | null => {
    const leaderboardGame = leaderboardGames.find((game) => game.gameId === gameId);
    const result = leaderboardGame?.result ?? undefined;


    if (result) {
      const winningTeamNum = result.matchResult.team_results.find((team) => team.teamPlacement === 1)!.teamNum - 1;
      const teamsInGroup = tournamentTeams.filter((team) => team.groups.some((teamGroup) => teamGroup.groupId === group.groupId));

      const winningTeamDoc = teamsInGroup.find((team) => team.groups.find((teamGroup) => teamGroup.groupId === group.groupId)?.groupPosition === winningTeamNum);
      if (winningTeamDoc) {
        return winningTeamDoc.DBTeam;
      }
    }

    return null;
  }

  const games = tournamentGroup ? tournamentGroup.games?.sort((a,b) => a.gamePosition - b.gamePosition) ?? [] : [];

  const totalGamesCount = games.length;
  const shellGamesCount = tournament ? tournament.matchPointFormatMaxGames - totalGamesCount : 0;

  const groupStarted = games.find((game) => game.gamePosition === 1)?.playerCodesDistributed ?? false;

  return tournament && games.length > 0 && games.every((game) => game.map) ? (
    <div className='flex items-center flex-wrap gap-x-6 gap-y-8 w-full'>
      {games.map((game, index) => (
        <GameMapCard key={`${game.gameName}-map-card`}
                     groupStarted={groupStarted}
                     matchPointWin={index === totalGamesCount - 1 && tournament.status === 4 && tournament.matchPointFormatFinish}
                     winningTeam={getWinningTeamFromGame(game.id)}
                     gameName={game.gameName}
                     mapAcronym={game.map}
                     gameInProgress={index + 1 === activeGroupGame}
                     gameCompleted={index + 1 < activeGroupGame}/>
      ))}
      {tournament.format === 'Match Point' && finalStage && tournament.status < 4 ? (
        <>
          {Array.from({length: shellGamesCount}).map((_game, index) => (
            <GameMapCard key={`${totalGamesCount + 1 + index}-map-card`}
                         groupStarted={groupStarted}
                         matchPointWin={false}
                         winningTeam={null}
                         gameName={`Game ${totalGamesCount + 1 + index}`}
                         mapAcronym={plannedMaps.find((plannedMap) => plannedMap.gameNum === totalGamesCount + 1 + index)?.map ?? 'WE'}
                         gameInProgress={false}
                         gameCompleted={false}/>
          ))}
        </>
      ) : ''}
    </div>
  ) : (
    <div className='flex flex-col items-center mt-10 gap-y-2'>
      <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
        <VscDebugDisconnect className='text-white text-2xl'/>
      </div>
      <p className='font-wide text-white text-2xl font-semibold uppercase'>Maps not confirmed</p>
      <p className='text-steelGray font-compact'>Maps will show here after they have been confirmed</p>
    </div>
  );
}

export default GroupMaps;
