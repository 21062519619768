import { useState, useEffect } from 'react';
// types

// context
import { useTournamentContext } from "@src/components/tournament/TournamentProvider";
import { useTournamentAdminContext } from "../../../TournamentAdminProvider";
// utils
import { formatDateTime, getTimezone } from '@src/utils/Date';
// libraries
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
// components
import CheckBox from '@src/components/ui/CheckBox';
import DropDown from '@src/components/ui/DropDown';
import ButtonPrimary from "@src/components/ui/ButtonPrimary";
import NumericalInput from '@src/components/ui/NumericalInput';
import PopoverText from '@src/components/ui/PopoverText';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { MouseClickIcon } from '@icons/Common';
import { SwordsIcon } from '@src/components/common/icons/TournamentIcons';
import { FaLock } from 'react-icons/fa';
import { platformsAsDropDownOptions } from '@src/types/Platforms';
import { gameFormatOptionsByGame, gameModeOptionsByGame } from '@src/types/Games/Games';

interface ITournamentEditPage {
  checkInStarted: boolean
}

const Summary: React.FC<ITournamentEditPage> = ({checkInStarted}) => {
  const { tournament } = useTournamentContext();
  const { tournamentEditInfo, setTournamentEditInfo, saveTournamentEditInfo } = useTournamentAdminContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [stepValid, setStepValid] = useState<boolean>(false);


  const handleSaveChanges = async () => {
    setSubmitting(true);
    await saveTournamentEditInfo();
    setSubmitting(false);
  };

  useEffect(() => {
    if (!tournamentEditInfo) return;

    if (tournamentEditInfo.teamCapacity > 20 && tournamentEditInfo.interStages === -1) {
     setTournamentEditInfo({
       ...tournamentEditInfo,
       interStages: 0,
     })
    } else if (tournamentEditInfo.teamCapacity <= 20 && tournamentEditInfo.interStages > -1) {
     setTournamentEditInfo({
       ...tournamentEditInfo,
       interStages: -1,
     })
    }
  }, [tournamentEditInfo]);

  useEffect(() => {
    if (tournament && tournamentEditInfo) {
      if (
        tournament.statusDates.ongoing !== tournamentEditInfo.statusDates.ongoing
        || tournament.platform !== tournamentEditInfo.platform
        || tournament.gameMode !== tournamentEditInfo.gameMode
        || tournament.format !== tournamentEditInfo.format
        || tournament.teamCapacity !== tournamentEditInfo.teamCapacity
        || tournament.interStages !== tournamentEditInfo.interStages
        || tournament.highPerformance !== tournamentEditInfo.highPerformance
        || tournament.teamSize !== tournamentEditInfo.teamSize
        || tournament.maxTeamSize !== tournamentEditInfo.maxTeamSize
        || tournament.matchPointFormatThreshold !== tournamentEditInfo.matchPointFormatThreshold
        || tournament.matchPointFormatMaxGames !== tournamentEditInfo.matchPointFormatMaxGames) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    } else {
      setChangesMade(false);
    }
  }, [tournament, tournamentEditInfo]);

  useEffect(() => {
    if (tournamentEditInfo && tournamentEditInfo.statusDates.ongoing) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentEditInfo]);

  return tournament && tournamentEditInfo ?  (
    <div className='text-white font-compact flex flex-col gap-y-4'>


      <div className='flex flex-col gap-y-2 text-start items-start'>
          <p>Platform</p>
          <DropDown currentOption={platformsAsDropDownOptions.find((option) => option.value === tournamentEditInfo.platform) ?? platformsAsDropDownOptions[0]}
                    options={platformsAsDropDownOptions}
                    handleOnChange={(newValue) => {
                      const newPlatformValue = platformsAsDropDownOptions.find((option) => option.value === newValue)!.value;
                      setTournamentEditInfo({
                        ...tournamentEditInfo,
                        platform: newPlatformValue
                      })
                    }}/>
        </div>

      <div className="flex flex-col md:flex-row gap-2 w-full">
        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Game Mode</p>
          <DropDown currentOption={gameModeOptionsByGame(tournamentEditInfo.game).find((option) => option.label === tournamentEditInfo.gameMode) ?? gameModeOptionsByGame(tournamentEditInfo.game)[0]}
                    options={gameModeOptionsByGame(tournamentEditInfo.game)}
                    handleOnChange={(newValue) => {
                      const newGameModeValue = gameModeOptionsByGame(tournamentEditInfo.game).find((option) => option.value === newValue)!.label;
                      setTournamentEditInfo({
                        ...tournamentEditInfo,
                        gameMode: newGameModeValue,
                        format: gameFormatOptionsByGame(tournamentEditInfo.game)[0].label
                      })
                    }}/>
        </div>

        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Format</p>
          <DropDown currentOption={gameFormatOptionsByGame(tournamentEditInfo.game).find((option) => option.label === tournamentEditInfo.format) ?? gameFormatOptionsByGame(tournamentEditInfo.game)[0]}
                    options={gameFormatOptionsByGame(tournamentEditInfo.game)}
                    handleOnChange={(newValue) => {
                      const newGameValue = gameFormatOptionsByGame(tournamentEditInfo.game).find((option) => option.value === newValue)!.label;
                      setTournamentEditInfo({
                        ...tournamentEditInfo,
                        format: newGameValue
                      })
                    }}/>
        </div>
      </div>

      {tournamentEditInfo.format === 'Match Point' ? (
        <div className='flex flex-col md:flex-row gap-x-6 gap-y-2 w-full'>

            <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
            <p>Match Point Threshold</p>
            <NumericalInput value={tournamentEditInfo.matchPointFormatThreshold}
                            interval={1}
                            onChange={(newValue) => {
                              setTournamentEditInfo({
                                ...tournamentEditInfo,
                                matchPointFormatThreshold: newValue
                              })
                            }}/>
          </div>

          <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
            <p>Match Point Max Games</p>
            <NumericalInput value={tournamentEditInfo.matchPointFormatMaxGames}
                            interval={1}
                            onChange={(newValue) => {
                              setTournamentEditInfo({
                                ...tournamentEditInfo,
                                matchPointFormatMaxGames: newValue
                              })
                            }}/>
          </div>

        </div>
      ) : ''}

      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 w-full">
        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Team Capacity</p>
          <NumericalInput value={tournamentEditInfo.teamCapacity}
                          interval={20}
                          minValue={20}
                          maxValue={160}
                          onChange={(newValue) => {
                            setTournamentEditInfo({
                              ...tournamentEditInfo,
                              teamCapacity: newValue
                             })
                          }}/>
        </div>

        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Stages</p>
          <NumericalInput value={tournamentEditInfo.interStages + 2}
                          minValue={1}
                          maxValue={3}
                          onChange={(newValue) => {
                            setTournamentEditInfo({
                              ...tournamentEditInfo,
                              interStages: newValue - 2
                             })
                          }}/>
        </div>
      </div>


        <div className='flex flex-col gap-y-2 text-start items-start'>
          <p>Server Type</p>
          <button onClick={() => setTournamentEditInfo({
                        ...tournamentEditInfo,
                        highPerformance: !tournamentEditInfo.highPerformance
                      })}
                  className={`flex gap-x-2 items-center font-compact bg-lightGray transition-all rounded-lg p-2`}>
            <span className={`${tournamentEditInfo.highPerformance ? 'text-white' : 'text-steelGray'} transition-colors translate-y-[1px]`}>High Performance</span>
            <CheckBox selected={tournamentEditInfo.highPerformance}
                      setSelected={(newValue) => setTournamentEditInfo({
                        ...tournamentEditInfo,
                        highPerformance: newValue
                      })}
                      small={true} asDiv={true} interactive={false}/>
          </button>
        </div>

      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 w-full">
        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Minimum Team Size</p>
          <NumericalInput value={tournamentEditInfo.teamSize}
                          interval={1}
                          minValue={1}
                          maxValue={10}
                          onChange={(newValue) => {
                            setTournamentEditInfo({
                              ...tournamentEditInfo,
                              teamSize: newValue
                              })
                          }}/>
        </div>

        <div className='flex flex-col gap-y-2 text-start items-start w-full md:w-[40%] flex-grow'>
          <p>Maximum Team Size</p>
          <NumericalInput value={tournamentEditInfo.maxTeamSize}
                          interval={1}
                          minValue={1}
                          maxValue={10}
                          onChange={(newValue) => {
                            setTournamentEditInfo({
                              ...tournamentEditInfo,
                              maxTeamSize: newValue
                              })
                          }}/>
        </div>
      </div>


      <div className='w-full flex flex-col gap-y-1'
             onClick={(e) => e.stopPropagation()}>
          <p>Event Start Time</p>
          <div className="w-full h-fit border-2 border-lightGray rounded-lg">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker value={tournamentEditInfo.statusDates.ongoing}
                              onChange={(newDate: Date | null) => {
                                if (newDate) {
                                  setTournamentEditInfo({
                                    ...tournamentEditInfo,
                                    statusDates: {
                                      confirmation: new Date(newDate.getTime() - 7_200_000),
                                      ongoing: newDate,
                                      results: new Date(newDate.getTime() - 14_400_000),
                                    }
                                  })
                                }
                              }}
                              orientation="landscape"
              format="dd/MM/yyyy hh:mm" />
            </LocalizationProvider>
          </div>
        </div>

        <div className='flex flex-col gap-y-2 w-full text-start'>
          <p>Dates Chosen:</p>

          <div className='text-white font-compact text-sm bg-lightGray p-2 rounded
                            flex items-center gap-x-3 w-fit'>
            <MouseClickIcon className='w-[12px] h-auto aspect-square fill-white'/>
            <span className='w-[60px]'>Check-In: </span>
            <em className='not-italic text-green'>{formatDateTime(tournamentEditInfo.statusDates.confirmation)} {getTimezone()}</em>
          </div>

          <div className='text-white font-compact text-sm bg-lightGray p-2 rounded
                            flex items-center gap-x-3 w-fit'>
            <SwordsIcon className='w-[12px] h-auto aspect-square fill-white'/>
            <span className='w-[60px]'>Start Time: </span>
            <em className='not-italic text-green'>{formatDateTime(tournamentEditInfo.statusDates.ongoing)} {getTimezone()}</em>
          </div>
        </div>

      <div className='relative group w-full h-fit mt-4'>
        {checkInStarted ? (
          <PopoverText visible={true} right={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
            Unable to affect changes after check-in has started
          </PopoverText>
        ) : ''}
        <ButtonPrimary disabled={checkInStarted || submitting || !changesMade || !stepValid}
                      onClick={handleSaveChanges}>
          <span>Save Changes</span>
          {checkInStarted ? (
            <FaLock/>
          )
          : submitting ? (
            <ImSpinner8/>
          ) : ''}
        </ButtonPrimary>
      </div>
    </div>
  ) : '';
}

export default Summary;
