import valorantCardImage from "../../../assets/images/webp/news/news_valorant_img.webp";
import witcherCardImage from "../../../assets/images/webp/news/news_withcer_img.webp";
import codCardImage from "../../../assets/images/webp/news/news_cod_img.webp";
import rocketLeaugeCardImage from "../../../assets/images/webp/news/news_rocket_leauge_img.webp";
import interviewsBanner1 from "../../../assets/images/webp/news/interviews_banner_1.webp";
import interviewsBanner2 from "../../../assets/images/webp/news/interviews_banner_2.webp";
import interviewsBanner3 from "../../../assets/images/webp/news/interviews_banner_3.webp";
import interviewsBanner4 from "../../../assets/images/webp/news/interviews_banner_4.webp";
import patchBanner1 from "../../../assets/images/webp/news/patch_banner_1.webp";
import patchBanner2 from "../../../assets/images/webp/news/patch_banner_2.webp";
import patchBanner3 from "../../../assets/images/webp/news/patch_banner_3.webp";
import patchBanner4 from "../../../assets/images/webp/news/patch_banner_4.webp";
import LeakerImage1 from "../../../assets/images/png/news/leakers-img-1.webp";
import LeakerImage2 from "../../../assets/images/png/news/leakers-img-2.webp";
import speculationThumbnailimg from "../../../assets/images/png/news/speculation-thumbnailimg.png";
import newsDetailDemoVideo from "../../../assets/video/news-detail-demo-video.mp4";


// Here is news details for all news
export const NewsDetailsList = [
  {
    title: "Trending now",
    leftArrow: "trending_left_arrow",
    rightArrow: "trending_right_arrow",
    treadingList: [
      {
        img: valorantCardImage,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: witcherCardImage,
        label: "The Witcher",
        title: "Rumor: Destiny 2's Next Returning Raid Possibly Revealed",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: codCardImage,
        label: "COD Warzone",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed  Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: rocketLeaugeCardImage,
        label: "Rocket League",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By  Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: witcherCardImage,
        label: "The Witcher",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: codCardImage,
        label: "COD Warzone",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
    ],
  },
  {
    title: "interviews",
    leftArrow: "patch_left_arrow",
    rightArrow: "patch_right_arrow",
    treadingList: [
      {
        img: interviewsBanner1,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: interviewsBanner2,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: interviewsBanner3,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: interviewsBanner4,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: interviewsBanner1,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: interviewsBanner2,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
    ],
  },
  {
    title: "Patch notes",
    leftArrow: "interviews_left_arrow",
    rightArrow: "interviews_right_arrow",
    treadingList: [
      {
        img: patchBanner1,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: patchBanner2,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: patchBanner3,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: patchBanner4,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: patchBanner1,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
      {
        img: patchBanner2,
        label: "Valorant",
        title:
          "Rumor: Destiny 2's Next Returning Raid Possibly Revealed By Known Leaker",
        description:
          "A Destiny 2 leaker known for their accuracy says the game's next returning raid could once again come from the original Destiny.",
        date: "15 MAY 23",
      },
    ],
  },
];

export const NewsDetailMobilecategory = [
  {
    regionsHeading: "Category",
    regionsElements: [
      "Tournaments",
      "News",
      "Tech",
      "Valorant",
      "Rocket League",
      "Fortnite",
      "Apex Legends",
    ],
  },
];

export const NewsDetailMobileSortBy = [
  {
    regionsHeading: " sort by",
    regionsElements: ["Recently Added", "Most viewed"],
  },
];

export const NewsDetails = [
  {
    title: "the leaker's revelation",
    description: [
      {
        para: "Known for their accurate predictions and leaks in the gaming community, the anonymous leaker, codenamed &quotGuardianWhisper&quot has garnered attention once again. In a recent post on social media, GuardianWhisper suggested that the next raid to be reintroduced in Destiny 2 might be none other than the fan-favorite &quotVault of Glass.&quot ",
      },
      {
        para: "Vault of Glass: A Legendary Raid Returns? For Destiny veterans, the mention of the Vault of Glass raid evokes memories of epic battles and intricate mechanics. Originally introduced in the first Destiny game, Vault of Glass became an instant classic, offering players an unforgettable raid experience. Its return would undoubtedly generate excitement and nostalgia among the Destiny 2 player base. ",
      },
    ],
    image: [
      {
        videoSource: "",
        image: LeakerImage1,
        type: "image",
      },
      {
        videoSource: "",
        image: LeakerImage2,
        type: "image",
      },
    ],
  },
  {
    title: "speculations and excitedment",
    description: [
      {
        para: "As expected, the gaming community has been buzzing with speculations and excitement since GuardianWhisper's leak. Destiny 2 players and enthusiasts are eagerly discussing the potential return of Vault of Glass and how it might be updated for the game's current state. Bungie has a history of revamping and improving upon existing raids, ensuring that they align with the game's evolving mechanics and systems. ",
      },
      {
        para: "Impact on the Destiny 2 Community: The return of a beloved raid like Vault of Glass could have a profound impact on the Destiny 2 community. Reintroducing this iconic raid would not only provide an opportunity for seasoned players to revisit nostalgic memories but also offer newer players a chance to experience it for the first time. Raids in Destiny 2 foster a sense of camaraderie, teamwork, and accomplishment, making them a cornerstone of the game's social experience.9",
      },
    ],
    image: [
      {
        image: speculationThumbnailimg,
        videoSource: newsDetailDemoVideo,
        type: "image",
      },
    ],
  },
  {
    title: "bungie's response",
    description: [
      {
        para: "While Bungie, the developer of Destiny 2, has yet to officially confirm or deny GuardianWhisper's leak, they have been known to pay attention to the community's desires and feedback. Bungie's commitment to engaging with their player base suggests that they may be considering the return of Vault of Glass or a similar fan-favorite raid. ",
      },
      {
        para: "As rumors continue to circulate within the Destiny 2 community, the possibility of the return of Vault of Glass as the next raid remains a tantalizing prospect. Whether or not GuardianWhisper's leak holds true, one thing is certain: Destiny 2 players are eagerly awaiting Bungie's official announcement. The anticipation and excitement surrounding the potential revival of a legendary raid demonstrate the enduring appeal of Destiny 2 and its ability to captivate players with its rich and ever-expanding universe.",
      },
    ],
    image: [
      {
        videoSource: newsDetailDemoVideo,
        image: speculationThumbnailimg,
        type: "image",
      },
    ],
  },
];

