import { forwardRef, useEffect, ReactNode, useRef, useState } from 'react';
// components
import { SkeletonDark } from '@src/components/common/skeleton-loader/Skeletons';
import SearchBar from '@ui/SearchBar';
import DropDown from '@ui/DropDown';
import DropDownMultiSelect from '@ui/DropDownMultiSelectV2';
import PopoverText from '@ui/PopoverText';
// icons
import { SlidersIcon, TargetIcon, TeamIcon, UserIcon } from '@icons/Common';
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from "@icons/Games";
import { FaCheck } from 'react-icons/fa';
import { PrizePoolTrophyIcon } from '../common/icons/TournamentIcons';
import { FaXmark } from 'react-icons/fa6';

const selectableGames = [
  {
    value: 0,
    disabled: false,
    title: 'Apex Legends',
    icon: <ApexLegendsIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    iconSmall: <ApexLegendsIcon className='w-[20px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 1,
    disabled: true,
    title: 'Valorant',
    icon: <ValorantIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    iconSmall: <ValorantIcon className='w-[20px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 2,
    disabled: true,
    title: 'Fortnite',
    icon: <FortniteIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    iconSmall: <FortniteIcon className='w-[20px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  },
  {
    value: 3,
    disabled: true,
    title: 'Rocket League',
    icon: <RocketLeagueIcon className='w-[35px] h-auto aspect-square fill-white'/>,
    iconSmall: <RocketLeagueIcon className='w-[20px] h-auto aspect-square fill-white'/>,
    bgHex: '#CC3131'
  }
];

interface IGameCheckBox {
  loaded?: boolean,
  checked: boolean,
  handleClick: () => void,
  icon: ReactNode,
  iconSmall: ReactNode,
  title: string,
  subText: string,
  disabled?: boolean,
  className?: string,
}

const GameCheckBox = forwardRef<HTMLDivElement, IGameCheckBox>(({loaded, checked, handleClick, title, icon, iconSmall, subText, className, disabled}, ref) => {
  return loaded !== false ? (
    <div ref={ref}
         onClick={handleClick}
         className={`${className} relative md:max-w-[500px] group p-2 px-3 md:p-4 rounded-3xl md:rounded-xl bg-lightBlack md:hover:bg-lightGray transition-all overflow-hidden origin-center
                     ${disabled ? 'pointer-events-none' : 'cursor-pointer'} border border-transparent
                     ${checked ? 'border-green md:border-transparent max-md:bg-green/10' : ''}`}>
      {disabled ? (
        <div className='hidden md:flex w-[300px] h-[19px] bg-steelGray text-black text-[0.75rem] font-compact font-semibold uppercase
                        absolute top-[35%] left-[40px] -translate-y-1/2 -translate-x-1/2 items-center justify-center z-[3]
                        rotate-[-30deg]'>
            <span className='mt-[3px] mr-2'>Coming Soon</span>
        </div>
      ) : ''}
      <div className={`flex items-center gap-x-6 justify-between ${disabled ? 'saturate-0 opacity-50' : ''}`}>
        <div className="flex items-center gap-x-3">
          <div className={`hidden md:block ${checked ? '' : 'saturate-0'} transition-all`}>
            {icon}
          </div>
          <div className="md:hidden w-fit h-fit">{iconSmall}</div>
          <div>
            <p className="mt-1 md:mt-0 font-compact text-sm md:font-wide md:text-base md:uppercase font-medium md:font-semibold text-white group-hover:text-green whitespace-nowrap transition-colors">
              {title}
            </p>
            {!disabled ? (
              <p className="hidden md:block text-steelGray font-compact text-sm whitespace-nowrap">
                {subText}
              </p>
            ) : ''}
          </div>
        </div>

        <button type='button'
                aria-label={`toggle ${title}`}
                className={`md:hidden w-[20px] h-auto aspect-square rounded-full border-2 border-ebonyClay
                            ${checked ? "bg-green" : 'bg-transparent'} flex items-center justify-center transition-all`}>
          <FaXmark className={`${checked ? '' : 'hidden'} text-black text-sm`}/>
        </button>

        <button type='button'
                aria-label={`toggle ${title}`}
                className={`hidden md:flex w-[25px] h-auto aspect-square rounded-lg border-2 border-ebonyClay
                            ${checked ? "bg-green" : 'bg-transparent'} flex items-center justify-center transition-all`}>
          <FaCheck className={`${checked ? '' : 'hidden'} text-black`}/>
        </button>
      </div>

    </div>
  ) : (
    <SkeletonDark className={`${className} max-w-[500px] h-[60px] rounded-xl`}/>
  );
});
// ${players.filter((player) => player.addedGames.some((playerGame) => playerGame.title === game.title)).length} players

const getFilterIconForList = (listTitle: string): ReactNode => {
  switch (listTitle) {
    case 'tournaments':
      return (<PrizePoolTrophyIcon className='w-[16px] h-auto aspect-square fill-green'/>);
    case 'teams':
      return (<TeamIcon className='w-[16px] h-auto aspect-square fill-green'/>);
    case 'players':
      return (<UserIcon className='w-[12px] h-auto aspect-square fill-green'/>);
    default:
      return '';
  }
}

interface DropDownOption {
  value: number,
  label: string,
}

interface IGameCheckBoxSubtextValues {
  'Apex Legends': string,
  'Valorant': string,
  'Fortnite': string,
  'Rocket League': string
}

interface ISearchSortFilterBar {
  searchEnabled: boolean,
  searchQuery: string,
  setSearchQuery: (query: string) => void,
  selectedRegionOption: number,
  setSelectedRegionOption: (option: number) => void,
  selectedGameOption: number,
  setSelectedGameOption: (value: number) => void,
  gameCheckBoxSubtextValues: IGameCheckBoxSubtextValues,
  selectedSortingOption: number,
  setSelectedSortingOption: (option: number) => void,
  regionDropdownOptions: DropDownOption[],
  sortingOptions: DropDownOption[],
  filterOptions: DropDownOption[],
  selectedFilterOptions: number[],
  setSelectedFilterOptions: (options: number[]) => void,
  initiallyLoaded: boolean,
  listTitle: string,
  searchBarPlaceholder?: string,
  className?: string,
}

const SearchSortFilterBar: React.FC<ISearchSortFilterBar> = (
  {
  searchEnabled,
  searchQuery,
  setSearchQuery,
  regionDropdownOptions,
  selectedRegionOption,
  setSelectedRegionOption,
  selectedGameOption,
  setSelectedGameOption,
  gameCheckBoxSubtextValues,
  selectedSortingOption,
  setSelectedSortingOption,
  sortingOptions,
  filterOptions,
  selectedFilterOptions,
  setSelectedFilterOptions,
  searchBarPlaceholder,
  listTitle,
  initiallyLoaded,
  className
  }
) => {
  const gameCheckBoxRef = useRef<HTMLDivElement | null>(null);
  const dropDownRef = useRef<HTMLDivElement | null>(null);

  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const filterIcon = getFilterIconForList(listTitle.toLowerCase());

  const matchDropBoxWidthToCheckbox = () => {
    if (dropDownRef.current) {
      const dropDown = dropDownRef.current as HTMLDivElement;
      if (window.innerWidth > 1023  && gameCheckBoxRef.current) {
        const checkbox = gameCheckBoxRef.current as HTMLDivElement;
        dropDown.style.width = `${checkbox.offsetWidth + 5}px`;
      } else {
        dropDown.style.width = "unset";
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      matchDropBoxWidthToCheckbox();
    }, 200)

    window.addEventListener('resize', matchDropBoxWidthToCheckbox);

    return () => window.removeEventListener('resize', matchDropBoxWidthToCheckbox)
  }, []);

  const toggleFilterMenu = () => {
    const open = !filterMenuOpen;
    setFilterMenuOpen(open);
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  return (
    <div className={`relative ${className} flex flex-col gap-y-8 mb-6`}>

      <div className={`fixed z-[20] top-0 left-0 w-[100vw] h-[100dvh] bg-black/60
                       ${filterMenuOpen ? '' : 'opacity-0 pointer-events-none'} transition-all`}>
        <div className="absolute bg-lightBlack bottom-0 left-0 w-full h-[40%] rounded-t-xl
                        flex flex-col">
          <div className="w-full p-4 flex items-center justify-between border-b border-lightGray mb-2">
            <p className="font-wide text-white font-semibold text-lg uppercase">
              Filters
            </p>
            <button type='button'
                    aria-label='close filter menu'
                    onClick={toggleFilterMenu}
                    className='block p-2 !outline-0 !border-0 text-steelGray hover:opacity-75 transition-opacity'>
              <FaXmark className='text-3xl'/>
            </button>
          </div>

          <div className="px-4 h-fit flex-grow overflow-y-scroll pb-[120px]">

            <div className="flex flex-col my-6 gap-y-2">
              <p className="font-wide text-white font-semibold text-lg uppercase">
                Filter By
              </p>
              <DropDownMultiSelect icon={filterIcon}
                                title="Filters"
                                loaded={initiallyLoaded}
                                selectedOptions={selectedFilterOptions}
                                options={filterOptions}
                                className='bg-lightGray max-w-[200px]'
                                setSelectedOptions={setSelectedFilterOptions}/>
            </div>

            <div className="flex flex-col my-6 gap-y-2">
              <p className="font-wide text-white font-semibold text-lg uppercase">
                Order By
              </p>
              <DropDown icon={(
                      <SlidersIcon  className='w-[16px] h-auto aspect-square stroke-green'/>
                    )}
                    loaded={initiallyLoaded}
                    currentOption={sortingOptions[selectedSortingOption]}
                    options={sortingOptions}
                    className='bg-lightGray max-w-[200px]'
                    handleOnChange={setSelectedSortingOption}/>
            </div>

          </div>


        </div>
      </div>

      <div className="w-full flex flex-col gap-y-4">
        <div className="flex items-center gap-x-4 w-full">
          <div className='relative flex-grow-[4] group/search'>
            <SearchBar loaded={initiallyLoaded}
                      placeholder={searchBarPlaceholder ? searchBarPlaceholder : 'Search'}
                      value={searchQuery}
                      setValue={setSearchQuery}
                      className={`${!searchEnabled ? 'opacity-50 pointer-events-none' : ''} w-full`}/>
            {!searchEnabled ? (
              <PopoverText className="group-hover/search:opacity-100" visible={false}>
                Coming soon
              </PopoverText>
            ) : ''}
          </div>
          <button type="button"
                  onClick={toggleFilterMenu}
                  className='md:hidden p-3 flex items-center justify-center bg-lightBlack rounded-xl hover:opacity-75 transition-opacity'>
            <SlidersIcon  className='w-[18px] h-auto aspect-square stroke-green'/>
          </button>
          <DropDown ref={dropDownRef}
                    icon={(<TargetIcon className='w-[16px] h-auto aspect-square fill-green'/>)}
                    loaded={initiallyLoaded}
                    currentOption={regionDropdownOptions[selectedRegionOption]}
                    options={regionDropdownOptions}
                    handleOnChange={setSelectedRegionOption}
                    className={`hidden md:block flex-grow-[1] lg:flex-grow-[0]`}/>
        </div>
        <div className="flex items-center gap-x-4 w-full overflow-x-scroll max-md:pr-4">
          {selectableGames.map((game, index) => (
            <GameCheckBox key={`game-checkbox-${game.title}`}
                          ref={index === 0 ? gameCheckBoxRef : null}
                          loaded={initiallyLoaded}
                          checked={selectedGameOption === game.value}
                          disabled={game.disabled}
                          handleClick={() => setSelectedGameOption(game.value)}
                          title={game.title}
                          className='flex-shrink-0 md:flex-shrink md:flex-grow'
                          subText={gameCheckBoxSubtextValues[game.title as keyof typeof gameCheckBoxSubtextValues] ?? ''}
                          iconSmall={game.iconSmall}
                          icon={(
                            <div className={`w-fit h-fit p-1 rounded-xl bg-[${game.bgHex}]`}>
                              {game.icon}
                            </div>
                          )}/>
          ))}
        </div>
      </div>

      <div className="flex justify-between items-center border-t border-lightBlack pt-8">
        {initiallyLoaded ? (
          <h2 className='text-xl lg:text-2xl text-white font-wide font-semibold uppercase'>{listTitle}</h2>
        ) : (
          <SkeletonDark className='rounded-xl h-[25px] w-[120px]'></SkeletonDark>
        )}
        <div className='hidden md:flex items-center gap-x-4'>
          <DropDownMultiSelect  icon={filterIcon}
                                title="Filters"
                                loaded={initiallyLoaded}
                                selectedOptions={selectedFilterOptions}
                                options={filterOptions}
                                setSelectedOptions={setSelectedFilterOptions}/>
          <DropDown icon={(
                      <SlidersIcon  className='w-[16px] h-auto aspect-square stroke-green'/>
                    )}
                    loaded={initiallyLoaded}
                    currentOption={sortingOptions[selectedSortingOption]}
                    options={sortingOptions}
                    handleOnChange={setSelectedSortingOption}/>
        </div>
      </div>
    </div>
  )
}

export default SearchSortFilterBar
