import { Link } from "react-router-dom";
// assetts
import findTeamImg from "@assets/images/webp/about/find_team_img.webp";
// icons
import { BottomGreenIcon} from "./icons/Common";

interface IFindATeamGray {
  className?: string,
}

const FindATeamGray: React.FC<IFindATeamGray> = ({className}) => {
  return (
    <Link to="/teams"
          className={`${className} w-full h-full p-2 px-4 rounded-xl overflow-hidden relative duration-300 ease-out bg-lightBlack hover:bg-lightGray transition-colors
                    flex flex-col group`}>
      <span className="find_team_triangle_icon absolute end-3 bottom-3">
        <BottomGreenIcon />
      </span>

      <img src={findTeamImg} alt="find team image"
           className={`w-[70%] object-top h-[9rem] object-cover self-center group-hover:scale-105 transition-transform`}/>

      <div className="flex flex-col items-start flex-grow justify-center px-8">
        <p className={`font-wide font-bold text-sm !leading-4 uppercase duration-300 ease-out transition-all text-green`}>
          Players
        </p>
        <h2 className={`font-wide font-bold text-2xl leading-4 md:!leading-9 uppercase my-1 duration-300 ease-out transition-all text-white`}>
          Find a team now
        </h2>
        <p className={`font-compact font-normal text-sm !leading-4 opacity-60 duration-300 ease-out transition-all text-white`}>
          Join forces with other top players and dominate. Versus connects you with like-minded people ready to compete at all levels.
        </p>
      </div>
    </Link>
  );
};

export default FindATeamGray;
