import { createContext, useState, useEffect, ReactNode, useContext, useCallback, useMemo, useRef } from "react";
// firebase
import { firestore } from "@src/firebase";
import { collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, Query, query, QueryConstraint, QueryDocumentSnapshot, startAfter, where } from "firebase/firestore";
// types
import { algoliaTeamConverter, DBTeam, teamConverter } from "@src/firestore/teams";
import { debounce } from "@src/utils/Debounce";
import algoliasearch from "algoliasearch";

export const TeamResultsPerPage = 30;

interface TeamsCount { // lets use an aggragation do for this (meta collection ---> 'teams')
  apexLegends: number,
  fortnite: number,
  valorant: number,
  rocketLeague: number,
}

const defaultTeamsCount = {
  apexLegends: 0,
  fortnite: 0,
  valorant: 0,
  rocketLeague: 0
};

export enum TeamsGameOption {
  Apex,
  Valorant,
  Fortnite,
  RocketLeague
}

export enum TeamsRegionOption {
  ALL,
  EMEA,
  NA,
  LATAM,
  APAC,
}

export enum TeamsFilterOption {
  threeTournaments,
  threeMembers
}

export enum TeamsSortingOption {
  totalWinningsDesc,
  nameDesc,
  playerCountDesc,
  trophiesWonDesc,
  tournamentsPlayedDesc,
  apexWinNumDesc,
  apexWinRateDesc
}

interface ITeamsContext {
  teams: DBTeam[] // all tournaments
  featuredTeams: DBTeam[],
  teamsNumber: number,
  teamsCount: TeamsCount,
  loadMoreTeams: (amountNeeded: number) => Promise<boolean>,
  loadingMore: boolean,
  searchQuery: string,
  searchQueued: boolean,
  setSearchQueued: (queued: boolean) => void,
  setSearchQuery: (query: string) => void,
  gameOption: TeamsGameOption,
  setGameOption: (option: TeamsGameOption) => void,
  regionOption: TeamsRegionOption,
  setRegionOption: (option: TeamsRegionOption) => void,
  sortingOption: TeamsSortingOption,
  setSortingOption: (option: TeamsSortingOption) => void,
  filterOptions: TeamsFilterOption[],
  setFilterOptions: (options: TeamsFilterOption[]) => void,
  initiallyLoaded: boolean
}

const defaultTeamsContext = {
  teams: [],
  featuredTeams: [],
  teamsNumber: 0,
  teamsCount: defaultTeamsCount,
  loadMoreTeams: async () => false,
  loadingMore: false,
  searchQuery: '',
  searchQueued: false,
  setSearchQueued: (queued: boolean) => console.log(queued),
  setSearchQuery: (query: string) => console.log(query),
  gameOption: TeamsGameOption.Apex,
  setGameOption: (option: TeamsGameOption) => console.log(option),
  regionOption: TeamsRegionOption.ALL,
  setRegionOption: (option: TeamsRegionOption) => console.log(option),
  sortingOption: TeamsSortingOption.totalWinningsDesc,
  setSortingOption: (option: TeamsSortingOption) => console.log(option),
  filterOptions: [],
  setFilterOptions: (options: TeamsFilterOption[]) => console.log(options),
  initiallyLoaded: false
};

const TeamsContext = createContext<ITeamsContext>(defaultTeamsContext)

export const useTeamsContext = () => {
  const context = useContext(TeamsContext);
  return context;
}

interface ITeamsProvider {
  announceTeamsLoaded: (loaded: boolean) => void,
  children: ReactNode
}
// local types
type LastTeam = QueryDocumentSnapshot<DBTeam> | null;

const sortingConstraintConstructors = {
  totalWinningsDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('totalWinnings', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('totalWinnings', 'desc'), orderBy('id')],
  nameDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('teamName'), orderBy('id'), startAfter(lastTeam)] : [orderBy('teamName'), orderBy('id')],
  playerCountDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('playerCount', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('playerCount', 'desc'), orderBy('id')],
  trophiesWonDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('trophyCount', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('trophyCount', 'desc'), orderBy('id')],
  tournamentsPlayedDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('tournamentsPlayed', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('tournamentsPlayed', 'desc'), orderBy('id')],
  apexWinNumDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('apexMatchWins', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('apexMatchWins', 'desc'), orderBy('id')],
  apexWinRateDesc: (lastTeam: LastTeam) => lastTeam ? [orderBy('apexMatchWinrate', 'desc'), orderBy('id'), startAfter(lastTeam)] : [orderBy('apexMatchWinrate', 'desc'), orderBy('id')]
};


const getQueryConstraintsForSortingOption = (option: TeamsSortingOption, lastTeam: LastTeam): QueryConstraint[] => {
  switch (option) {
    case TeamsSortingOption.totalWinningsDesc:
      return sortingConstraintConstructors.totalWinningsDesc(lastTeam);
    case TeamsSortingOption.nameDesc:
      return sortingConstraintConstructors.nameDesc(lastTeam);
    case TeamsSortingOption.playerCountDesc:
      return sortingConstraintConstructors.playerCountDesc(lastTeam);
    case TeamsSortingOption.trophiesWonDesc:
      return sortingConstraintConstructors.trophiesWonDesc(lastTeam);
    case TeamsSortingOption.tournamentsPlayedDesc:
      return sortingConstraintConstructors.tournamentsPlayedDesc(lastTeam);
    case TeamsSortingOption.apexWinNumDesc:
      return sortingConstraintConstructors.apexWinNumDesc(lastTeam);
    case TeamsSortingOption.apexWinRateDesc:
      return sortingConstraintConstructors.apexWinRateDesc(lastTeam);
    default:
      return sortingConstraintConstructors.totalWinningsDesc(lastTeam);
  }
}

const getQueryConstraintsForRegionOption = (option: TeamsRegionOption): QueryConstraint[] => {
  let region: string = '';
  switch (option) {
    case TeamsRegionOption.ALL:
      region = '';
      break;
    case TeamsRegionOption.EMEA:
      region = 'EMEA';
      break;
    case TeamsRegionOption.NA:
      region = 'NA';
      break;
    case TeamsRegionOption.LATAM:
      region = 'LATAM';
      break;
    case TeamsRegionOption.APAC:
      region = 'APAC';
      break;
    default:
      region = '';
  }

  return region !== '' ? [where('region', '==', region)] : [];
}

const getQueryConstraintsForFilterOptions = (options: TeamsFilterOption[]): QueryConstraint[] => {
  const getConstraintForFilterOption = (option: TeamsFilterOption): QueryConstraint => {
    switch (option) {
      case TeamsFilterOption.threeMembers:
        return where('playerCount', '>', 3);
      case TeamsFilterOption.threeTournaments:
        return where('tournamentsPlayed', '>', 3);
    }
  };

  const constraints: QueryConstraint[] = [];
  for (const option of options) {
    constraints.push(getConstraintForFilterOption(option));
  }

  return constraints;
}

const TeamsProvider: React.FC<ITeamsProvider> = ({children, announceTeamsLoaded}) => {
  const [teams, setTeams] = useState<DBTeam[]>([]);
  const [teamsNumber, setTeamsNumber] = useState<number>(0);
  const [featuredTeams, setFeaturedTeams] = useState<DBTeam[]>([]);
  const [teamsCount, setTeamsCount] = useState<TeamsCount>(defaultTeamsCount);

  const searchQueryRef = useRef<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchQueued, setSearchQueued] = useState<boolean>(false);

  const [gameOption, setGameOption] = useState<TeamsGameOption>(TeamsGameOption.Apex);
  const [regionOption, setRegionOption] = useState<TeamsRegionOption>(TeamsRegionOption.ALL);
  const [sortingOption, setSortingOption] = useState<TeamsSortingOption>(TeamsSortingOption.totalWinningsDesc);

  const [filterOptions, setFilterOptions] = useState<TeamsFilterOption[]>([]);

  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);

  const [lastDBTeam, setLastDBTeam] = useState<LastTeam>(null);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const getFeaturedTeams = async () => {
    const teamsCollection = collection(firestore, 'teams').withConverter(teamConverter);
    const q = query(teamsCollection, orderBy('createdAt', 'desc'), where('dissolved', '==', false), limit(3)); // more indepth freatured algo later perhaps...

    onSnapshot(q, async (snapshots) => {
      const localTeams = snapshots.docs.map((doc) => doc.data());
      setFeaturedTeams(localTeams);
    })
  }

  const getTeamsCount = useCallback(async () => {
    const teamsCollection = collection(firestore, 'teams');
    const apexCountQuery = query(teamsCollection, where('mainGame', '==', 'Apex Legends') ,where('dissolved', '==', false));
    const apexCount = (await getCountFromServer(apexCountQuery)).data().count;

    const localTeamsCount = {
      apexLegends: apexCount,
      fortnite: 0,
      valorant: 0,
      rocketLeague: 0
    };

    setTeamsCount(localTeamsCount);
  }, [])


  const getTeams = useCallback(async (reset: boolean, amountNeeded: number) => {
    const teamsCollection = (collection(firestore, 'teams').withConverter(teamConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, reset ? null : lastDBTeam);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBTeam> = query(teamsCollection,
                                  ...querySortingConstraints,
                                   ...queryFilterConstraints,
                                   ...queryRegionConstraints,
                                   where('dissolved', '==', false),
                                   limit(amountNeeded));

    const snapshots = await getDocs(q);
    const localTeams = snapshots.docs.map((doc) => doc.data());

    const lastTeam = snapshots.docs[snapshots.docs.length - 1];
    setLastDBTeam(lastTeam);

    if (reset) {
      setTeams(localTeams);
    } else {
      setTeams(prevTeams => [...prevTeams, ...localTeams]);
    }

    announceTeamsLoaded(true);
    setInitiallyLoaded(true);
    setLoadingMore(false);

    return localTeams.length > 0;
  }, [sortingOption, filterOptions, regionOption, lastDBTeam, announceTeamsLoaded]);

  const getTeamsNumber = useCallback(async () => {
    const teamsCollection = (collection(firestore, 'teams').withConverter(teamConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, null);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBTeam> = query(teamsCollection,
                                   ...querySortingConstraints,
                                   ...queryFilterConstraints,
                                   ...queryRegionConstraints,
                                   where('dissolved', '==', false));
    const count = (await getCountFromServer(q)).data().count;
    setTeamsNumber(count);
  }, [sortingOption, filterOptions, regionOption]);

  const searchTeams = async () => {
    const localSearchQuery = searchQueryRef.current;
    if (localSearchQuery !== null && localSearchQuery !== '') {
      let teams: DBTeam[] = [];
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch('1EFPJPOFKM', 'fc4014a3e91daa3ee6f42d3a64dcba0e');
        const index = client.initIndex('teams');
        const { hits } = await index.search(localSearchQuery);
        teams = hits.map((hit) => algoliaTeamConverter(hit)).filter((team) => !team.dissolved);
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch('3JUVR45ABN', '0d5ba6842ac967b9bcd83eb1f908096e');
        const index = client.initIndex('teams');
        const { hits } = await index.search(localSearchQuery);
        teams = hits.map((hit) => algoliaTeamConverter(hit)).filter((team) => !team.dissolved);
      } else {
          const usersCollection = collection(firestore, "teams").withConverter(teamConverter);
         const q = query( usersCollection,
                          where('teamName', '==', localSearchQuery),
                          where('dissolved', '==', false),
                          limit(TeamResultsPerPage));
        const querySnapshot = await getDocs(q);
        teams = querySnapshot.docs.map((doc) => doc.data());
      }
      setTeams(teams);
      setTeamsNumber(teams.length);
    }
    setSearchQueued(false);
  };

  const debouncedSearchTeams = useMemo(() => debounce(searchTeams, 500), [])

  useEffect(() => {
    searchQueryRef.current = searchQuery;
    if (searchQuery !== '') {
      setSearchQueued(true);
      debouncedSearchTeams();
    } else if (initiallyLoaded) {
      getTeams(true, TeamResultsPerPage);
      getTeamsNumber();
    }
  }, [searchQuery, debouncedSearchTeams]);


  useEffect(() => {
    getTeamsNumber();
    getTeams(true, TeamResultsPerPage);
    getTeamsCount();
    getFeaturedTeams();
  }, []);

  useEffect(() => {
    getTeams(true, TeamResultsPerPage);
    getTeamsNumber();
  }, [sortingOption, filterOptions, regionOption, gameOption])

  const loadMoreTeams = (amountNeeded: number) => {
    return getTeams(false, amountNeeded);
  };

  const contextvalue = {
    teams: teams,
    featuredTeams: featuredTeams,
    teamsNumber: teamsNumber,
    teamsCount: teamsCount,
    loadMoreTeams: loadMoreTeams,
    loadingMore: loadingMore,
    searchQuery,
    searchQueued,
    setSearchQueued,
    setSearchQuery,
    gameOption,
    setGameOption,
    regionOption,
    setRegionOption,
    sortingOption,
    setSortingOption,
    filterOptions,
    setFilterOptions,
    initiallyLoaded: initiallyLoaded
  }

  return (
    <TeamsContext.Provider value={contextvalue}>
      {children}
    </TeamsContext.Provider>
  )
}

export default TeamsProvider;
