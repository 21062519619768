import { useEffect, useRef, useState, useCallback } from "react";
import '../../css/datePicker.css';
// context
import { useProfileInfoContext } from "./ProfileInfoProvider";
// components
import { InputWrapper } from "@components/common/inputfield-wrapper/InputWrapper";
// utils
import { isDiscordUsernameValid } from "@src/utils/validations/discord";
// icons
import {  GrayCrossIcon } from "@icons/Header";
import { BlackArrow } from "@icons/Common";
import { CreateBackArrowIcon, RightCheckIcon } from "@icons/ProfileIcons";
import { DiscordIcon, TwitterIcon, TwitchIcon, YouTubeIcon, InstagramIcon } from "@icons/Socials";

interface IProfileStep {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const SocialStep: React.FC<IProfileStep> = ({ changeModalStep, closeModal }) => {
  const scrollContainerRef = useRef(null);

  const {profileInfo, setProfileInfo, updateMode, reloadInfo} = useProfileInfoContext();
  const [formValid, setFormValid] = useState(false);

  const [activeField, setActiveField] = useState<string | null>(null);

  const [discordLinkValid, setDiscordLinkValid] = useState<boolean>(false);
  const [twitchLinkValid, setTwitchLinkValid] = useState<boolean>(false);
  const [twitterLinkValid, setTwitterLinkValid] = useState<boolean>(false);
  const [youtubeLinkValid, setYoutubeLinkValid] = useState<boolean>(false);
  const [instagramLinkValid, setInstagramLinkValid] = useState<boolean>(false);

  const validateFields = useCallback(() => {
    const twitchRegex = /((https|http):\/\/)?(www\.)?twitch\.tv\/[a-zA-Z@]+/i;
    setTwitchLinkValid(profileInfo.twitch === '' || twitchRegex.test(profileInfo.twitch));

    const twitterRegex = /((https|http):\/\/)?(www\.)?(twitter\.com|x\.com)\/[a-zA-Z@]+/i;
    setTwitterLinkValid(profileInfo.twitter === '' || twitterRegex.test(profileInfo.twitter));

    const youtubeRegex = /((https|http):\/\/)?(www\.)?youtube\.com\/[a-zA-Z@]+/i;
    setYoutubeLinkValid(profileInfo.youtube === '' || youtubeRegex.test(profileInfo.youtube));

    const instagramRegex = /((https|http):\/\/)?(www\.)?instagram\.com\/[a-zA-Z@]+/i;
    setInstagramLinkValid(profileInfo.instagram === '' || instagramRegex.test(profileInfo.instagram));

    setDiscordLinkValid(profileInfo.discord === '' || isDiscordUsernameValid(profileInfo.discord));
  }, [profileInfo])

  useEffect(() => {
    validateFields();
  }, [profileInfo, validateFields]);


  const validateForm = async () => {
    const fieldsValid = discordLinkValid && twitchLinkValid && twitterLinkValid && youtubeLinkValid && instagramLinkValid;
    const localFormValid = fieldsValid;
    setFormValid(localFormValid);
  }

  useEffect(() => {
    validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discordLinkValid, twitchLinkValid, twitterLinkValid, youtubeLinkValid, instagramLinkValid]);


  const handleStepSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeModalStep(1);
  };


  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('.createInput') && !target.closest('.createInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [])


  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="flex justify-between items-center border-b-[0.063rem] border-lightGray py-3 px-4 bg-lightBlack">
        <span
          className="cursor-pointer"
          onClick={() => {
            changeModalStep(-1);
          }}>
          <CreateBackArrowIcon />
        </span>
        <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
          {updateMode ? 'Edit' : 'Complete'} Profile
        </h5>
        <span
          className="cursor-pointer"
          onClick={() => {
            reloadInfo();
            closeModal();
          }}
        >
          <GrayCrossIcon className="group" />
        </span>
      </div>
      <div ref={scrollContainerRef} className={`h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]`}>

      <div className="font-compact text-base font-normal max-[26.25rem]:p-3 p-6 pb-0 ">
        <div className="flex gap-[1.375rem] items-center justify-center">
          <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
            <RightCheckIcon />
          </div>
          <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green bg-lightBlack"></div>
          <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
        </div>

        <div className="my-2 flex flex-col gap-y-2">
          <div className="text-steelGray flex items-center gap-x-2">
            <p className="text-left">Bio</p>{" "}
            <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
          </div>

          <textarea
            className={`createInput bg-black text-sm !font-compact w-full focus:outline-none text-white placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                            ${
                              activeField === "bio"
                                ? "border-green border-2 pr-10 "
                                : "border-transparent border-2"
                            }`}
            onClick={() => setActiveField("bio")}
            placeholder="Share some information about yourself with the community."
            value={profileInfo.bio}
            onChange={(e) => {
              if (/\w{0,400}/.test(e.target.value)) {
                setProfileInfo({
                  ...profileInfo,
                  bio: e.target.value
                })
              }
            }}
          />

          <p className="text-steelGray font-thin text-sm text-right w-full">
            {profileInfo.bio.length}/400
          </p>
        </div>



        <div className="text-steelGray flex items-center gap-x-2">
          <p className="text-left">Socials</p>{" "}
          <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
        </div>

          <div className="flex flex-col gap-y-3">
            <div className="flex items-center my-2 -ml-1">
              <div className="flex items-center gap-x-2">
                <DiscordIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper className="!mt-0">
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeField === "discord"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${discordLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveField("discord")}
                  placeholder="username#1234"
                  value={profileInfo.discord}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profileInfo,
                      discord: e.target.value
                    })
                  }}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center my-2 -ml-1">
              <div className="flex items-center gap-x-2">
                <TwitterIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper className="!mt-0">
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeField === "twitter"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${twitterLinkValid ? "" : "!border-red"}`}
                  placeholder="twitter.com/gg_versus"
                  onClick={() => setActiveField("twitter")}
                  value={profileInfo.twitter}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profileInfo,
                      twitter: e.target.value
                    })
                  }}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center my-2 -ml-1">
              <div className="flex items-center gap-x-2">
                <InstagramIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper className="!mt-0">
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeField === "instagram"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${instagramLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveField("instagram")}
                  placeholder="instagram.com/versusgaminggg"
                  value={profileInfo.instagram}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profileInfo,
                      instagram: e.target.value
                    })
                  }}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center my-2 -ml-1">
              <div className="flex items-center gap-x-2">
                <TwitchIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper className="!mt-0">
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeField === "twitch"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${twitchLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveField("twitch")}
                  placeholder="twitch.tv/versus_gg_"
                  value={profileInfo.twitch}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profileInfo,
                      twitch: e.target.value
                    })
                  }}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center my-2 -ml-1">
              <div className="flex items-center gap-x-2">
                <YouTubeIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper className="!mt-0">
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeField === "youtube"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${youtubeLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveField("youtube")}
                  placeholder="youtube.com/versus_gg"
                  value={profileInfo.youtube}
                  onChange={(e) => {
                    setProfileInfo({
                      ...profileInfo,
                      youtube: e.target.value
                    })
                  }}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[0.063rem] border-lightGray py-3 px-4">
        <button
          disabled={!formValid}
          onClick={handleStepSubmit}
          type="submit"
          className={`font-compact text-base font-medium leading-[100%] tracking-[0.01rem] uppercase w-full  rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 ${
            formValid
              ? "cursor-pointer opacity-100"
              : "cursor-not-allowed opacity-30"
          }`}
        >
          <span className="pt-[0.125rem]">next</span>
          <span>
            <BlackArrow />
          </span>
        </button>
      </div>
    </div>
  );
};
export default SocialStep;
