import { useEffect, useState } from "react";
// context
import { DBTeamWithGroups, useTournamentAdminContext } from "../TournamentAdminProvider";
// icons
import { DownSelectArrow } from "../../../common/icons/Common";
import { useTournamentContext } from "../../TournamentProvider";
import { TournamentGroup } from "../../../../firestore/tournaments";
import NoticeText from "@src/components/ui/NoticeText";

interface IUnassigned {
  stageNum: number,
  groups: TournamentGroup[],
  unassignedTeams: DBTeamWithGroups[],
}

const Unassigned: React.FC<IUnassigned> = ({stageNum, groups, unassignedTeams}) => {
  const { tournament } = useTournamentContext();

  const { changeTeamGroup } = useTournamentAdminContext();

  const [activeAssignmentDropdown, setActiveAssignmentDropdown] = useState<string>(''); // the team id for which dropdown is open

  useEffect(() => {
    const listener = (e: Event) => {
      if (!(e.target as Element).closest('.dropdown-toggle, .dropdown')) {
        setActiveAssignmentDropdown('');
      }
    }

    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [])

  return (
    <div className={`text-white flex flex-col gap-y-2 mt-2 ${tournament && tournament.lockedStages.includes(stageNum) ? 'opacity-50 pointer-events-none' : ''}`}>
      {unassignedTeams.length > 0 ? (
        <>
          {unassignedTeams.map((team) => (
            <div key={team.id!} className="flex items-center h-[55px] gap-x-1">
              <div className="relative flex items-center bg-lightGray h-full w-1/4 rounded-xl">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <p className="ml-5 font-wide font-medium text-white uppercase">Unnassigned</p>
              </div>
              <div className="relative flex items-center gap-x-1 h-full w-3/4">
                <div className="flex items-center gap-x-3 bg-lightGray/80 h-full w-2/3 rounded-xl pl-5">
                  <img src={team.logoUrl} alt={`${team.teamName} logo`}
                      className="h-3/5 w-auto aspect-square rounded-lg"/>
                  <div>
                    <p className="text-white font-compact">
                      {team.teamName}
                    </p>
                    <p className="text-steelGray font-compact">
                      {team.teamTag}
                    </p>
                  </div>
                </div>
                <div className="relative h-full w-1/3">
                  <button type='button'
                          onClick={() => {
                            setActiveAssignmentDropdown(team.id!);
                          }}
                          className={`flex dropdown-toggle items-center justify-center w-full h-full rounded-xl gap-x-2 bg-lightGray font-wide font-medium text-white uppercase'
                                      hover:opacity-75 transition-opacity
                                      ${activeAssignmentDropdown === team.id! ? 'hidden' : ''}`}>
                    <span>Assign</span>
                    <DownSelectArrow/>
                  </button>
                  <div className={`absolute z-[50] dropdown w-full h-fit rounded-xl flex flex-col gap-y-2 bg-lightGray min-h-full
                                  ${activeAssignmentDropdown === team.id! ? '' : 'hidden'}`}>
                    {groups.map((group) => (
                      <button key={group.id}
                              type='button'
                              onClick={() => {
                                setActiveAssignmentDropdown('');
                                changeTeamGroup(team, group, false);
                              }}
                              className="w-full h-[55px] flex items-center justify-center rounded-xl
                                      text-white font-wide border border-green/0 hover:opacity-75 hover:border-green transition-opacity">
                        {group.groupName}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <NoticeText>
          No unassigned teams
        </NoticeText>
      )}
    </div>
  )
}

export default Unassigned;
