import { useState } from 'react'
// context
import { useTournamentContext } from '@src/components/tournament/TournamentProvider';
// components
import Summary from './SubViews/Summary';
import EventRules from './SubViews/EventRules';
import StreamingRules from './SubViews/StreamingRules';

enum OverviewViews {
  summary,
  eventRules,
  streamingRules,
}

const Overview = () => {
  const { tournament } = useTournamentContext();

  const [currentView, setCurrentView] = useState<OverviewViews>(OverviewViews.summary);

  const checkInStarted = tournament !== null && tournament.status > 0;

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Overview
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView(OverviewViews.summary)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === OverviewViews.summary ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Summary</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(OverviewViews.eventRules)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === OverviewViews.eventRules ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Event Rules</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(OverviewViews.streamingRules)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === OverviewViews.streamingRules ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Streaming Rules</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-3/5'>
          {currentView === OverviewViews.summary ? (
            <Summary checkInStarted={checkInStarted}/>
          ) : ''}
          {currentView === OverviewViews.eventRules ? (
            <EventRules checkInStarted={checkInStarted}/>
          ) : ''}
          {currentView === OverviewViews.streamingRules ? (
            <StreamingRules checkInStarted={checkInStarted}/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Overview;
