import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// context
import { useProfileContext } from "../ProfileProvider";
import { useProfileInfoContext } from "@src/components/completeProfile/ProfileInfoProvider";
// types
import { TournamentTrophy, trophyPositionToString } from "../../../firestore/tournamentTrophies";
// utils
import { formatDate, getAgeFromDateOfBirth } from "../../../utils/Date";
import { getLegendBackground } from "@src/utils/Games/ApexLegends";
// libraries
import { toast } from "react-toastify";
// components
import { EditProfileBtn } from "../../common/icons/ProfileIcons";
// assets
import placeholderImage from '../../../assets/images/placeholder/placeholder.png';
// icons
import { ConsoleIcon, ControllerIcon, ExternalLinkIcon, MonitorIcon, MouseIcon } from '@icons/Common';
import { DiscordIcon, YouTubeIcon, TwitterIcon, TwitchIcon, InstagramIcon } from "@icons/Socials";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from "@icons/Games";
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from "@icons/TournamentIcons";
import { CupNumbering } from "@icons/ProfileIcons";
import { FaPen } from "react-icons/fa";
import PopoverText from "@src/components/ui/PopoverText";
import { getName } from "country-list";

const getCupIconForTrophy = (finalPosition: number) => {
  switch (finalPosition) {
    case 1:
      return <TrophyGoldImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    case 2:
      return <TrophySilverImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    case 3:
      return <TrophyBronzeImage className="w-[60px] sm:w-[80px] lg:w-[60px] xl:w-[80px] h-auto aspect-square object-contain"/>
    default:
      return '';
  }
}

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon'
}

const platformStringToIcon = (platform: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon className='w-[13px] h-auto aspect-square fill-steelGray  transition-colors'/>
      );
    default:
      return (
        <ConsoleIcon className='w-[20px] h-auto aspect-square fill-steelGray  transition-colors mt-[2px]'/>
      );
  }
}

const gameInputStringToIcon = (inputName: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon className='w-[13px] h-auto aspect-square fill-steelGray  transition-colors'/>
      );
    case 'Controller':
      return (
        <ControllerIcon className='w-[15px] h-auto aspect-square fill-steelGray  transition-colors'/>
      );
    default:
      return '';
  }
}

interface IProfileHero {
  className?: string,
}

// ProfileHero component to display the profile header or hero section
const ProfileHero: React.FC<IProfileHero> = ({ className }) => {
  const navigate = useNavigate();
  const { setCurrentModalStep, openEditProfile } = useProfileInfoContext();

  const { profile, profileTrophies, profileTeam, editor } = useProfileContext();

  const [winningsToolTip, setWinningsToolTip] = useState<boolean>(false);
  const [hoveredTrophyGroup, setHoveredTrophyGroup] = useState<number | null>(null);

  const apexGameEntry = profile ? profile.addedGames.find((game) => game.title === 'Apex Legends') : null;

  const noSocials = profile !== null && !profile.discord && !profile.twitter && !profile.youtube && !profile.instagram;

  const formatWinningsToString = (winnings: number) => {
    const winningsString = winnings.toString();
    return `$${winningsString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  const totalTeamWinnings = profileTrophies.reduce((acc, curr) => acc + Math.round(curr.winnings / curr.players.length), 0) ?? 0;

  const trophiesCount: { [key: string]: TournamentTrophy[] } = {
    '1': [],
    '2': [],
    '3': [],
  };

  profileTrophies.forEach((trophy) => {
    const finalPositionString = trophy.position.toString();
    trophiesCount[finalPositionString].push(trophy);
  })

  const copyProfileLinkToClipboard = () => {
    if (profile) {
      toast.info('Profile link copied to clipboard')
      navigator.clipboard.writeText(`https://versusgaming.gg/profile/${profile.uid}`)
    }
  }

  const copyDiscordUsername = (username: string) => {
    toast.info('Discord username copied to clipboard');
    navigator.clipboard.writeText(username);
  }

  if (profile) return (
    <div className={`${className}`}>
      {/* Display the profile header */}
      <div className="sm:rounded-2xl bg-lightBlack flex flex-col">
        {/* Cover image - desktop view */}
        <img
          className="sm:rounded-t-2xl w-full h-auto aspect-[3.15] object-cover hidden sm:block"
          src={profile.bannerImage !== '' ? profile.bannerImage : apexGameEntry ? getLegendBackground(apexGameEntry.mainLegend!) : placeholderImage}
          loading="lazy"
          alt="coverImg"
        />
        {/* Cover image - mobile view */}
        <img
          className={`w-full h-auto aspect-[3.15] object-cover object-center sm:hidden cursor-pointer`}
          src={profile.bannerImage !== '' ? profile.bannerImage : apexGameEntry ? getLegendBackground(apexGameEntry.mainLegend!) : placeholderImage}
          loading="lazy"
          alt="coverImg"
        />
        {/* Profile information */}
        <div className="p-4 pb-0 sm:p-6 sm:pb-0 lg:p-8 flex justify-between items-end relative lg:mt-0 flex-grow gap-x-4 xl:gap-x-8">
          <div className="flex gap-1 sm:gap-2 absolute top-0 right-2 sm:right-8 -translate-y-1/2 justify-end lg:justify-start mb-5 lg:mb-0  z-[30]">
            {Object.entries(trophiesCount).map((entry, groupIndex) => {
              const finalPosition = parseInt(entry[0]) as number;
              const finalPositionTrophies = entry[1];
              const count = finalPositionTrophies.length;
              if (count > 0) {
                return (
                  <span key={`group-${groupIndex}`}
                        className="relative"
                        onMouseOver={() => setHoveredTrophyGroup(groupIndex)}
                        onMouseOut={() => setHoveredTrophyGroup(null)}
                        >
                    {getCupIconForTrophy(finalPosition)}
                    <CupNumbering count={count}/>
                    <ul className={`${hoveredTrophyGroup === groupIndex ? '' : 'opacity-0'} transition-opacity pointer-events-none absolute w-[200px] bottom-0 right-0 translate-y-full
                                  p-2 rounded-lg bg-lightBlack/70 backdrop-blur-md text-steelGray border border-steelGray/50 font-semibold  flex flex-col gap-y-3`}>
                      {finalPositionTrophies.map((trophy, trophyIndex) => (
                        <li key={`${groupIndex}-${trophyIndex}`}
                            className="w-full font-compact">
                          <div>
                            <p className="text-white/80">{trophy.tournamentName}</p>
                            <div className="flex items-center gap-x-2">
                              <p className="text-green">{trophyPositionToString(trophy.position)}</p>
                              <p className="text-steelGray text-sm font-thin">
                                - {trophy.teamName}
                              </p>
                            </div>
                            <p className="text-sm opacity-75">{formatDate(trophy.dateRecieved)}</p>
                          </div>
                          {trophyIndex !== finalPositionTrophies.length - 1 ? (
                            <div className="w-full h-[1px] mt-3 bg-steelGray/50"></div>
                          ) : ''}
                        </li>
                      ))}
                    </ul>
                  </span>
                )
              }
            })}
          </div>

          <div className="absolute lg:relative top-0 left-4 lg:left-0 -translate-y-1/3 lg:-translate-y-0 w-fit h-fit flex-shrink-0">
            {profileTeam ? (
              <Link to={`/team/${profileTeam.id}`}
                    className="absolute right-[15px] top-[15px] sm:right-[20px] sm:top-[20px] translate-x-1/2 -translate-y-1/2
                                rounded-xl w-[40px] h-[40px] bg-lightBlack">
                <img src={profileTeam.logoUrl} alt={`User's team logo (${profileTeam.teamName})`}
                      className="w-full h-full border-[4px] border-lightBlack rounded-xl hover:opacity-75 transition-opacity"/>
              </Link>
            ) : ''}
            <img src={profile.displayImage ? profile.displayImage : placeholderImage} alt="profileImg"
                className={`w-[8rem] h-[8rem] sm:w-[10rem] sm:h-[10rem] lg:w-[11.55rem] lg:h-[11.55rem]
                border-[4px] border-lightBlack object-cover rounded-full`}/>
          </div>

          <div className="relative flex flex-col lg:flex-row gap-6 xl:gap-8 w-full pt-[7rem] lg:pt-0">
            <div className="absolute top-[25px] right-0 flex sm:hidden items-center gap-x-2">
              <div className={`${winningsToolTip ? '' : 'opacity-0'} transition-opacity pointer-events-none absolute w-[200px] z-[20] bottom-0 right-0 translate-y-[calc(100%+10px)]
                                  p-2 rounded-lg bg-lightBlack/70 backdrop-blur-md border border-steelGray/50 font-semibold flex flex-col gap-y-3`}>
                <p className="text-white text-sm font-compact font-thin">
                Individual winnings are estimated based on the number of players for each trophy win.
                </p>
              </div>
              <div onMouseOver={() => setWinningsToolTip(true)}
                    onMouseOut={() => setWinningsToolTip(false)}
                    className="relative w-[15px] h-[15px] rounded-full border border-green
                              flex items-center justify-center hover:opacity-75 cursor-default">
                <p className="font-compact text-white font-semibold text-xs mt-[2px]">i</p>
              </div>
              <p className={`text-white totalTeamWinnings text-lg font-wide !leading-4 uppercase font-bold items-center gap-2
                justify-end -mb-1 flex `}>
                <span>
                  Winnings:
                </span>
                <span className="text-green">
                  {formatWinningsToString(totalTeamWinnings)}
                </span>
              </p>
            </div>

            {/* Profile information */}
            <div className="flex flex-col justify-between flex-grow min-h-[120px] h-[120px] sm:h-auto gap-y-4">
              {profile.addedGames.map((addedGame, index) => (
                <p key={index} className="px-3 py-[0.4rem] sm:py-[0.45rem] !leading-4 bg-lightGray rounded-[3rem] flex items-center gap-2 text-sm font-compact text-white w-fit">
                  {gameNameToIcon(addedGame.title)}
                  <span className="-mb-0.5 whitespace-nowrap">{addedGame.title}</span>
                </p>
              ))}
              <div className="flex justify-between w-full h-full">
                <div className={`flex flex-col gap-y-4 ${noSocials ? '' : 'self-end'}`}>
                  <div className="flex flex-col">
                    <h5 className={`flex items-center gap-x-2 text-white font-bold !leading-[100%]`}>
                      <p className="font-wide text-3xl sm:text-2xl">{profile.displayName}</p>
                    </h5>
                    <div className="flex items-center gap-x-3">
                      <p className="font-compact text-steelGray font-medium">
                        @{profile.username}
                      </p>
                      <div className="relative group/nation  w-fit h-fit">
                        <div className={`h-[13px] opacity-75 fi fi-${profile.nationality}`}></div>
                        <PopoverText className="group-hover/nation:opacity-100" visible={false}>
                          {getName(profile.nationality)}
                        </PopoverText>
                      </div>
                      {profile.showAge ? (
                        <p className="text-steelGray group-hover:text-white/65 font-compact font-medium -mb-1 transition-colors">{getAgeFromDateOfBirth(profile.dateOfBirth)}</p>
                      ) : ''}
                      {apexGameEntry ? (
                        <>
                          <div className="relative group text-steelGray font-compact font-semibold">
                            <p>{platformStringToIcon(apexGameEntry.platform)}</p>
                            <PopoverText className="group-hover:opacity-100" visible={false}>
                              {apexGameEntry.platform}
                            </PopoverText>
                          </div>
                          <p className="text-steelGray font-compact font-semibold">{gameInputStringToIcon(apexGameEntry.input)}</p>
                        </>
                      ) : ''}
                    </div>
                  </div>

                  {profile.discord || profile.twitter || profile.youtube || profile.instagram ? (
                    <div className="hidden lg:flex gap-2">
                      {profile.discord ? (
                        <button type="button" aria-label="copy discord username"
                                onClick={() => copyDiscordUsername(profile.discord)}>
                          <DiscordIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </button>
                      ) : ''}
                      {profile.twitter ? (
                        <a href={profile.twitter} target="_blank">
                          <TwitterIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {profile.twitch ? (
                        <a href={profile.twitch} target="_blank">
                          <TwitchIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {profile.youtube ? (
                        <a href={profile.youtube} target="_blank">
                          <YouTubeIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                      {profile.instagram ? (
                        <a href={profile.instagram} target="_blank">
                          <InstagramIcon className="w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                        </a>
                      ) : ''}
                    </div>
                  ) : ''}
                </div>
                <div className="second flex flex-col justify-end">
                  <div className="hidden sm:flex items-center gap-x-2 relative self-end">
                    <div className={`${winningsToolTip ? 'opacity-100' : 'opacity-0'} transition-opacity pointer-events-none absolute w-[200px] h-fit bg-lightBlack/70 bottom-0 left-0 translate-y-[calc(100%+10px)]
                                        p-2 rounded-lg backdrop-blur-md border border-steelGray/50 font-semibold flex flex-col gap-y-3`}>
                      <p className="text-white text-sm font-compact font-thin">
                      Individual winnings are estimated based on the number of players for each trophy win.
                      </p>
                    </div>
                    <div onMouseOver={() => setWinningsToolTip(true)}
                          onMouseOut={() => setWinningsToolTip(false)}
                          className="relative w-[15px] h-[15px] rounded-full border border-green
                                    flex items-center justify-center cursor-default z-[20]">
                      <p className="font-compact text-white font-semibold text-xs mt-[2px]">i</p>
                    </div>
                    <p className={`text-white totalTeamWinnings text-2xl font-wide !leading-4 uppercase font-bold items-center gap-2
                      justify-end -mb-1 flex`}>
                      <span>
                        Winnings:
                      </span>
                      <span className="text-green">
                        {formatWinningsToString(totalTeamWinnings)}
                      </span>
                    </p>
                  </div>
                {/* Social icons - mobile view */}
                <div className={`justify-end lg:hidden gap-2 pt-6 flex`}>
                  {profile.discord ? (
                    <button type="button" aria-label="copy discord username"
                            onClick={() => copyDiscordUsername(profile.discord)}>
                      <DiscordIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </button>
                  ) : ''}
                  {profile.twitter ? (
                    <a href={profile.twitter} target="_blank">
                      <TwitterIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {profile.twitch ? (
                    <a href={profile.twitch} target="_blank">
                      <TwitchIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {profile.youtube ? (
                    <a href={profile.youtube} target="_blank">
                      <YouTubeIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                  {profile.instagram ? (
                    <a href={profile.instagram} target="_blank">
                      <InstagramIcon className="w-[45px] sm:w-[35px] h-auto aspect-square p-[0.65rem] bg-lightGray rounded-lg fill-white hover:fill-green transition-[300]"/>
                    </a>
                  ) : ''}
                </div>
                {/* Edit and Share buttons - desktop view */}
                <div className={`hidden lg:flex gap-4 mt-6 justify-end w-full`}>
                    {editor ? (
                      <>
                        {profile.profileComplete ? (
                          <button type="button" onClick={openEditProfile}
                            className="bg-lightGray hover:bg-ebonyClay duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-semibold px-6 rounded-xl flex gap-2 items-center !leading-4">
                            <span>Edit Profile</span>
                            <EditProfileBtn />
                          </button>
                        ) : (
                          <button type="button"
                            onClick={() => {
                              navigate('/my-profile');
                              setCurrentModalStep(0);
                            }}
                            className="bg-green hover:bg-gorse text-black duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-semibold px-6 rounded-xl flex gap-2 items-center !leading-4">
                            <span>Complete Profile</span>
                            <FaPen />
                          </button>

                        )}
                      </>
                    ) : ''}
                  <button type="button"
                          aria-label="copy team link to clipboard"
                          onClick={copyProfileLinkToClipboard}
                          className="bg-lightGray hover:bg-ebonyClay duration-200 pb-[0.938rem] pt-[1.063rem] !leading-4 uppercase font-medium px-6 rounded-xl font-compact text-white
                                    flex items-center gap-x-2">
                    <span>
                      Share
                    </span>
                    <ExternalLinkIcon className="w-[16px] h-auto aspect-square stroke-green"/>
                  </button>
                </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* Edit and Share buttons - mobile view */}
        <div className="p-4 lg:p-0">
          <div className={`flex lg:hidden max-[26.25rem]:gap-2 gap-4 border-t border-t-lightGray pt-6 ${editor ? '' : 'justify-end'}`}>
            {editor ? (
              <>
                {profile.profileComplete ? (
                  <button type="button" onClick={openEditProfile}
                    className="bg-lightGray hover:bg-ebonyClay duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-semibold px-6 rounded-xl flex gap-2 items-center !leading-4">
                    <span>Edit Profile</span>
                    <EditProfileBtn />
                  </button>
                ) : (
                  <button type="button"
                    onClick={() => {
                      navigate('/my-profile');
                      setCurrentModalStep(0);
                    }}
                    className="bg-green hover:bg-gorse text-black duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-semibold px-6 rounded-xl flex gap-2 items-center !leading-4">
                    <span>Complete Profile</span>
                    <FaPen />
                  </button>

                )}
              </>
            ) : ''}
            <button type="button"
                    aria-label="copy team link to clipboard"
                    onClick={copyProfileLinkToClipboard}
                    className="bg-lightGray hover:bg-ebonyClay duration-200 pb-[0.938rem] pt-[1.063rem] !leading-4 uppercase font-medium px-6 rounded-xl font-compact text-white
                              flex items-center gap-x-2">
              <span>
                Share
              </span>
              <ExternalLinkIcon className="w-[16px] h-auto aspect-square stroke-green"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileHero;
