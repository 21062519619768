import { RefObject, useEffect, useContext, useRef, useState, useCallback } from "react";
// firebase
import { firestore } from '../../../firebase';
import {collection, getDocs, query, where} from 'firebase/firestore';
// context
import { TeamInfoContext } from "../TeamInfoProvider";
// components
import { InputWrapper } from "../../common/inputfield-wrapper/InputWrapper";
// assets
import step1PositionImg from "../../../assets/images/png/team/step1PositionImg.png";
// icons
import {  GrayCrossIcon } from "../../common/icons/Header";
import { InputCross,BlackArrow } from "../../common/icons/Common";
import { SelectImageIcon, UploadArrowIcon, UploadLogoIcon} from "../../common/icons/ProfileIcons";
import { IoClose } from "react-icons/io5";
import { DiscordIcon, TwitterIcon, TwitchIcon, WebsiteIcon } from "@icons/Socials";
import { toast } from "react-toastify";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const CreateTeamProfile = (props: IProps) => {
  const { changeModalStep, closeModal } = props; //setDeleteProfile (removed for now (why are we offering a delete before the team is created?))
  const [formFilled, setFormFilled] = useState(false); // Track whether the form is filled

  const {teamInfo, setTeamInfo, edit} = useContext(TeamInfoContext);

  // local state for team name and tag field values (initially loaded from previous context + set to context on submit)
  const [teamName, setTeamName] = useState<string>(teamInfo.teamName);
  const [teamNameUnique, setTeamNameUnique] = useState<boolean | undefined>(true);
  const teamNameMaxLength = 30;

  const [discordLink, setDiscordLink] = useState<string>(teamInfo.discordLink);
  const [twitterLink, setTwitterLink] = useState<string>(teamInfo.twitterLink);
  const [twitchLink, setTwitchLink] = useState<string>(teamInfo.twitchLink);
  const [websiteLink, setWebsiteLink] = useState<string>(teamInfo.websiteLink);

  const [twitchLinkValid, setTwitchLinkValid] = useState<boolean>(false);
  const [twitterLinkValid, setTwitterLinkValid] = useState<boolean>(false);
  const [websiteLinkValid, setWebsiteLinkValid] = useState<boolean>(false);
  const [discordLinkValid, setDiscordLinkValid] = useState<boolean>(false);

  const validateLinks = useCallback(() => {
    const twitchRegex = /((https|http):\/\/)?(www\.)?twitch\.tv\/\w+/i;
    setTwitchLinkValid(twitchLink === '' || twitchRegex.test(twitchLink));

    const twitterRegex = /((https|http):\/\/)?(www\.)?(twitter\.com|x\.com)\/\w+/i;
    setTwitterLinkValid(twitterLink === '' || twitterRegex.test(twitterLink));

    const websiteRegex = /((https|http):\/\/)?(www\.)?\w+\.\w+(\w+\/)*/i;
    setWebsiteLinkValid(websiteLink === '' || websiteRegex.test(websiteLink));

    const discordRegex = /((https|http):\/\/)?discord\.gg\/\w+/i;
    setDiscordLinkValid(discordLink === '' || discordRegex.test(discordLink));
  }, [twitchLink, twitterLink, websiteLink, discordLink])

  useEffect(() => {
    validateLinks();
  }, [validateLinks, discordLink, twitterLink, twitchLink, websiteLink]);

  const [teamTag, setTeamTag] = useState<string>(teamInfo.teamTag);
  const teamTagMinLength = 2;
  const teamTagMaxLength = 3;

  const [activeInput, setActiveInput] = useState<string | null>(null);

  // local image files
  const [banner, setBanner] = useState<File|null>(teamInfo.banner);
  const [logo, setLogo] = useState<File|null>(teamInfo.logo);

  useEffect(() => {
    if (logo) {
      if (!["image/jpeg", "image/png", "image/webp"].includes(logo.type)) {
        setLogo(null);
        setLogoPreviewUrl('');
        toast.error(`You can only upload jpeg, png or webp image formats`)
      }
    }
  }, [logo]);

  useEffect(() => {
    if (banner) {
      if (!["image/jpeg", "image/png", "image/webp"].includes(banner.type)) {
        setBanner(null);
        setBannerPreviewUrl('');
        toast.error(`You can only upload jpeg, png or webp image formats`)
      }
    }
  }, [banner]);

  // local image file preview urls
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(teamInfo.logoPreviewUrl);
  const [bannerPreviewUrl, setBannerPreviewUrl] = useState(teamInfo.bannerPreviewUrl);

  // emulated clicks so file upload inputs can be opened from external buttons
  const bannerImageRef: RefObject<HTMLInputElement> = useRef(null);
  const logoImageRef: RefObject<HTMLInputElement> = useRef(null);

  const clickBannerUpload = () => {
    bannerImageRef.current!.click();
  };

  const clickLogoUpload = () => {
    logoImageRef.current!.click();
  };

  const checkTeamExists = async (teamName: string): Promise<boolean> => {
    const teamsCollectionRef = collection(firestore, 'teams');
    const q = query(teamsCollectionRef, where('teamName', '==', teamName));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  // Create URL reference to image on input change (user attached file)
  const handleBannerImageAttach = (e: { target: HTMLInputElement  }) => {
    const bannerLocal = e.target.files![0];
    const bannerUrl = URL.createObjectURL(bannerLocal);
    setBanner(bannerLocal);
    setBannerPreviewUrl(bannerUrl);
  };

  const removeBannerImageAttachement = () => {
    setBanner(null);
    setBannerPreviewUrl('');
  }

  // Create URL reference to image on input change (user attached file)
  const handleLogoImageAttach = (e: { target: HTMLInputElement }) => {
    const logoLocal = e.target.files![0];
    const logoUrl = URL.createObjectURL(logoLocal);
    setLogo(logoLocal);
    setLogoPreviewUrl(logoUrl);
  };

  const removeLogoImageAttachement = () => {
    setLogo(null);
    setLogoPreviewUrl('');
  }

  // State to handle the email input and password input after click on cross icon of the input
  const [isActiveInput, setIsActiveInput] = useState({
    activeTeamTag: false,
    activeTeamName: false,
  });

  const validateTeamName = async (): Promise<boolean> => {
    const lengthValidation = teamName.trim().length >= 3;
    let teamNameExists;
    if (edit) {
      if (teamName !== teamInfo.originalTeamName) { // we dont want to check for uniqueness against the team's original name
        teamNameExists = await checkTeamExists(teamName);
      } else {
        teamNameExists = false;
      }
    } else {
      teamNameExists = await checkTeamExists(teamName);
    }
    setTeamNameUnique(!teamNameExists);
    return lengthValidation && !teamNameExists;
  }

  const validateTeamTag = () => {
    return teamTag.length <= teamTagMaxLength && teamTag.length >= teamTagMinLength;
  }

  const validateForm = async () => {
    const teamFieldsValid = await validateTeamName() && validateTeamTag();
    const teamUploadsValid = !!logoPreviewUrl;
    const teamLinksValid = twitchLinkValid && twitterLinkValid && websiteLinkValid && discordLinkValid;
    const isFormFilled = teamFieldsValid && teamUploadsValid && teamLinksValid;
    setFormFilled(isFormFilled);
  }

  useEffect(() => {
    validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamName, teamTag, bannerPreviewUrl, logoPreviewUrl, twitchLinkValid, twitterLinkValid, websiteLinkValid, discordLinkValid]);


  const saveStepInfo = () => {
    // set team info context so entries persisted on step change
    const newTeamInfo = {
      ...teamInfo,
      teamName: teamName,
      teamTag: teamTag,
      banner: banner,
      bannerPreviewUrl: bannerPreviewUrl,
      logo: logo,
      logoPreviewUrl: logoPreviewUrl,
      discordLink: discordLink,
      twitterLink: twitterLink,
      twitchLink: twitchLink,
      websiteLink: websiteLink,
    };

    setTeamInfo(newTeamInfo);
  }

  const handleTeamNameChange = async (newTeamName: string) => {
    if (newTeamName.length <= teamNameMaxLength) {
      setTeamName(newTeamName);
    }
  }

  const handleTeamTagChange = (newTeamTag: string) => {
    if (/^[a-zA-Z]{0,3}$/.test(newTeamTag)) {
      setTeamTag(newTeamTag.toUpperCase());
    }
  }

  const handleStepSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    saveStepInfo();
    changeModalStep(1);
  };


  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('createInput') && !target.closest('createInput')) {
        setActiveInput('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mouesdown', checkForOutsideInputClick);
  }, [])


  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="flex justify-between items-center border-b-[0.063rem] border-lightGray py-3 px-4 bg-lightBlack">
        <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
          {edit ? "Edit Team" : "Create team"}
        </h5>
        <span
          className="cursor-pointer"
          onClick={() => {
            saveStepInfo();
            closeModal();
          }}
        >
          <GrayCrossIcon className="group" />
        </span>
      </div>
      <div className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">

        <div className="max-[26.25rem]:p-3 p-6 pb-0 ">
          <div className="flex gap-[1.375rem] items-center justify-center w-full">
            <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
          </div>
          {/* Team image upload section */}

          <div className="rounded-2xl bg-lightGray h-[11.063rem] mt-6 relative overflow-hidden">
            {bannerPreviewUrl ? (
              <>
                <img
                  className="w-full h-[11.063rem] object-cover"
                  src={bannerPreviewUrl}
                  alt="banner-image"
                />
                <button
                  type="button"
                  aria-label="remove banner attachment"
                  onClick={() => removeBannerImageAttachement()}
                  className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
                >
                  <p className="text-red text-lg">
                    <IoClose />
                  </p>
                </button>
              </>
            ) : (
              <div className="p-4">
                <img
                  className="absolute top-0 start-0 max-w-[19.188rem] h-full"
                  src={step1PositionImg}
                  alt=""
                />
                <div className="flex justify-end">
                  <span className="cursor-pointer" onClick={clickBannerUpload}>
                    <span className="rounded-full bg-ebonyClay p-3 w-[2.5rem] h-[2.5rem] cursor-pointer flex items-center justify-center">
                      <SelectImageIcon />
                    </span>
                  </span>
                </div>
                <input
                  hidden
                  id="upload-photo"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  ref={bannerImageRef}
                  onChange={(e) => handleBannerImageAttach(e)}
                />
              </div>
            )}
          </div>
          {/* Team logo upload section */}
          <div className="rounded-2xl border-4 border-solid border-lightBlack bg-lightGray w-[7.5rem] h-[7.5rem] mx-auto -mt-[4.375rem] z-50 relative overflow-hidden">
            {logoPreviewUrl ? (
              <>
                <img
                  className="w-full h-full object-cover"
                  src={logoPreviewUrl}
                  alt=""
                />
                <button
                  type="button"
                  aria-label="remove logo attachment"
                  onClick={() => removeLogoImageAttachement()}
                  className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
                >
                  <p className="text-red text-lg">
                    <IoClose />
                  </p>
                </button>
              </>
            ) : (
              <div className="h-full relative">
                <p className="absolute top-2 right-2 text-steelGray font-compact font-semibold text-xl">*</p>
                <span
                  onClick={clickLogoUpload}
                  className="w-full h-full cursor-pointer flex justify-center items-center"
                >
                  <UploadLogoIcon />
                </span>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  hidden
                  required
                  name="photo"
                  ref={logoImageRef}
                  id="upload-logo"
                  onChange={handleLogoImageAttach}
                />
              </div>
            )}
          </div>
          {/* Upload team logo description and arrow icon */}
          <div className="flex gap-1 items-center pt-4 justify-center pb-6">
            <p className="font-compact text-base font-medium tracking-[0.01rem] uppercase text-white text-center !leading-4 -mb-1">
              upload a team logo
            </p>
            <span className="block">
              <UploadArrowIcon />
            </span>
          </div>
          {/* Form for team details */}
        </div>
        {/* Team Name input field */}
        <div className="flex flex-col max-[26.25rem]:p-3 px-6">
          <label
            className="font-compact text-base font-normal text-steelGray tracking-[0.01rem] leading-normal text-start"
            htmlFor="teamname"
          >
            Team Name *
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`createInput !bg-transparent !font-compact w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4  ${
                  activeInput === "teamName"
                    ? "border-green border-2 pr-10 placeholder:text-white"
                    : "border-transparent border-2"
                }`}
                onClick={() => setActiveInput("teamName")}
                required
                id="teamname"
                type="text"
                value={teamName}
                onChange={(e) => handleTeamNameChange(e.target.value)}
                placeholder="Team name here"
              />
              {isActiveInput.activeTeamName && (
                <span
                  onClick={() => {
                    setIsActiveInput({
                      ...isActiveInput,
                      activeTeamName: false,
                    });
                  }}
                  className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                >
                  <InputCross />
                </span>
              )}
            </div>
          </InputWrapper>
          {!(teamNameUnique === true) ? (
            <p
              className={`font-compact font-semibold text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end text-red opacity-60`}
            >
              Team name: "{teamName}" already in use.
            </p>
          ) : (
            ""
          )}
          <p
            className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                         ${
                           teamName.length > 0
                             ? `${
                                 teamName.length >= 3
                                   ? "text-green opacity-60"
                                   : "text-red opacity-60"
                               }`
                             : "text-white opacity-40"
                         }`}
          >
            {teamName.length}/{teamNameMaxLength}
          </p>
          {/* Team Tag input field */}
          <div className="flex flex-col mt-6">
            <label
              className="font-compact text-base font-normal text-steelGray tracking-[0.01rem] leading-normal text-start"
              htmlFor="teamtag"
            >
              Team Tag *
            </label>
            <InputWrapper>
              <div className="relative w-full">
                <input
                  className={`createInput !bg-transparent !font-compact w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4 ${
                    activeInput === "teamTag"
                      ? "border-green border-2 pr-10 placeholder:text-white"
                      : "border-transparent border-2"
                  }`}
                  onClick={() => setActiveInput("teamTag")}
                  required
                  id="teamtag"
                  type="text"
                  value={teamTag}
                  onChange={(e) => handleTeamTagChange(e.target.value)}
                  placeholder="Team tag here"
                />
                {/* Clear button for teamtag */}
                {isActiveInput.activeTeamTag && (
                  <span
                    onClick={() => {
                      setIsActiveInput({
                        ...isActiveInput,
                        activeTeamTag: false,
                      });
                    }}
                    className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                  >
                    <InputCross />
                  </span>
                )}
              </div>
            </InputWrapper>
            <p
              className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                           ${
                             teamTag.length > 0
                               ? `${
                                   validateTeamTag()
                                     ? "text-green opacity-60"
                                     : "text-red opacity-60"
                                 }`
                               : "text-white opacity-40"
                           }`}
            >
              2-3 Characters
            </p>
          </div>
        </div>
        <div className="font-compact text-base font-normal px-6">
          <div className="text-steelGray flex items-center gap-x-2">
            <p className="text-left">Socials</p>{" "}
            <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <DiscordIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper>
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeInput === "discord"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${discordLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveInput("discord")}
                  placeholder="discord.gg/versus-gg"
                  value={discordLink}
                  onChange={(e) => setDiscordLink(e.target.value)}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <TwitterIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper>
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeInput === "twitter"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${twitterLinkValid ? "" : "!border-red"}`}
                  placeholder="twitter.com/gg_versus"
                  onClick={() => setActiveInput("twitter")}
                  value={twitterLink}
                  onChange={(e) => setTwitterLink(e.target.value)}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <TwitchIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper>
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeInput === "twitch"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${twitchLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveInput("twitch")}
                  placeholder="twitch.tv/versus_gg_"
                  value={twitchLink}
                  onChange={(e) => setTwitchLink(e.target.value)}
                />
              </InputWrapper>
            </div>

            <div className="flex items-center">
              <div className="flex items-center gap-x-2">
                <WebsiteIcon className="w-[40px] h-auto aspect-square p-[0.7rem] fill-white" />
              </div>
              <InputWrapper>
                <input
                  className={`createInput !bg-transparent !font-compact w-full focus:outline-none placeholder:text-darkGray py-4 sm:py-34 rounded-xl px-4
                                  ${
                                    activeInput === "website"
                                      ? "border-green border-2 pr-10 "
                                      : "border-transparent border-2"
                                  }
                                  ${websiteLinkValid ? "" : "!border-red"}`}
                  onClick={() => setActiveInput("website")}
                  placeholder="example.com"
                  value={websiteLink}
                  onChange={(e) => setWebsiteLink(e.target.value)}
                />
              </InputWrapper>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[0.063rem] border-lightGray py-3 px-4">
        <button
          disabled={!formFilled}
          onClick={handleStepSubmit}
          type="submit"
          className={`font-compact text-base font-medium leading-[100%] tracking-[0.01rem] uppercase w-full  rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 ${
            formFilled
              ? "cursor-pointer opacity-100"
              : "cursor-not-allowed opacity-30"
          }`}
        >
          <span className="pt-[0.125rem]">next</span>
          <span>
            <BlackArrow />
          </span>
        </button>
      </div>
    </div>
  );
};
export default CreateTeamProfile;
