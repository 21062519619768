import { createContext, ReactNode, useContext, useEffect, useState } from "react";
// firebase
import { firestore, storage } from "../../../firebase";
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, Unsubscribe, updateDoc, where, writeBatch } from "firebase/firestore";
// types
import { TournamentGroup, Tournament, TournamentTeam, TournamentTeamStatus, TournamentGame, TournamentStage, tournamentConverter } from "../../../firestore/tournaments";
import { DBTeam, teamConverter } from "../../../firestore/teams";
// libaries
import { toast } from "react-toastify";
import { resizeImage } from "@src/utils/ResizeImage";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

interface TournamentEditInfo extends Tournament {
  cardImageFile: File | null,
  bannerImageFile: File | null,
  cardImagePreviewUrl: string,
  bannerImagePreviewUrl: string,
}

export interface DBTeamWithGroups extends DBTeam {
  groups: {groupId: string, groupPosition: number}[],
  eliminated: boolean,
}

interface TournamentGroupGames {
  [key: string]: TournamentGame[];
}

interface ITournamentAdminContext {
  tournamentEditInfo: TournamentEditInfo | null,
  setTournamentEditInfo: (tournamentEditInfo: TournamentEditInfo) => void,
  saveTournamentEditInfo: () => Promise<void>,
  groups: TournamentGroup[],
  stages: TournamentStage[],
  groupGames: TournamentGroupGames,
  confirmedTeams: DBTeamWithGroups[],
  declinedTeams: DBTeam[],
  ignoredTeams: DBTeam[],
  playStarted: boolean,
  changeTeamGroup: (team: DBTeamWithGroups, group: TournamentGroup, remove: boolean) => void,
  setStageLocked: (stageNum: number, locked: boolean, unassignedTeamLength: number) => void,
}

const defaultTournamentAdminContext = {
  tournamentEditInfo: null,
  setTournamentEditInfo: (tournamentEditInfo: TournamentEditInfo) => console.log(tournamentEditInfo),
  saveTournamentEditInfo: () => Promise.resolve(),
  groups: [],
  stages: [],
  groupGames: {},
  confirmedTeams: [],
  declinedTeams: [],
  ignoredTeams: [],
  playStarted: false,
  changeTeamGroup: (team: DBTeamWithGroups, group: TournamentGroup, remove: boolean) => {
    console.log(team, group, remove);
  },
  setStageLocked: (stageNum: number, locked: boolean, unassignedTeamLength: number) => {
    console.log(stageNum);
    console.log(locked);
    console.log(unassignedTeamLength);
  },
}

const TournamentAdminContext = createContext<ITournamentAdminContext>(defaultTournamentAdminContext)

export const useTournamentAdminContext = () => {
  return useContext(TournamentAdminContext);
}

interface ITournamentAdminProvider {
  tournament: Tournament | null,
  tournamentTeams: TournamentTeam[],
  children: ReactNode,
}

const TournamentAdminProvider: React.FC<ITournamentAdminProvider> = ({tournament, tournamentTeams, children}) => {

  const [tournamentEditInfo, setTournamentEditInfo] = useState<TournamentEditInfo | null>(null);


  const [stages, setStages] = useState<TournamentStage[]>([]);
  const [playStarted, setPlayStarted] = useState<boolean>(false);
  const [groups, setGroups] = useState<TournamentGroup[]>([]);
  const [groupGames, setGroupGames] = useState<TournamentGroupGames>({})
  const [groupsListenerInitialised, setGroupsListenerInitialised] = useState<boolean>(false);
  const [confirmedTeams, setConfirmedTeams] = useState<DBTeamWithGroups[]>([]);
  const [declinedTeams, setDeclinedTeams] = useState<DBTeam[]>([]);
  const [ignoredTeams, setIgnoredTeams] = useState<DBTeam[]>([]);

  const saveTournamentEditInfo = async () => {
    if (tournamentEditInfo) {
      try {
        const cardImage = tournamentEditInfo.cardImageFile;
        const bannerImage = tournamentEditInfo.bannerImageFile;

        let newCardImageUrl = '';
        let newBannerImageUrl = '';

        if (cardImage) {
          const cardImageResized = await resizeImage(cardImage, {width: 1000});
          if (cardImageResized) {
            const cardImageStorageRef = ref(storage, `tournamentPresets/${tournamentEditInfo.name}/cardImage.webp`);
            await uploadBytes(cardImageStorageRef, cardImageResized).then(async (snapshot) => {
              newCardImageUrl = await getDownloadURL(snapshot.ref);
            });
          } else {
            console.error('error uploading card image');
            throw new Error('error uploading card image');
          }
        }

        if (bannerImage) {
          const bannerImageRezied = await resizeImage(bannerImage, {width: 1500});
          if (bannerImageRezied) {
            const bannerImageStorageRef = ref(storage, `tournamentPresets/${tournamentEditInfo.name}/bannerImage.webp`);
            await uploadBytes(bannerImageStorageRef, bannerImageRezied).then( async (snapshot) => {
              newBannerImageUrl = await getDownloadURL(snapshot.ref);
            });
          } else {
            console.error('error uploading banner');
            throw new Error('error uploading banner');
          }
        }

        const updatedTournamentInfo = {
          ...tournamentEditInfo,
          cardImage: newCardImageUrl ? newCardImageUrl : tournamentEditInfo.cardImage,
          bannerImage: newBannerImageUrl ? newBannerImageUrl : tournamentEditInfo.bannerImage
        }

        const updatedTournament = tournamentConverter.toFirestore(updatedTournamentInfo);

        const tournamentRef = doc(firestore, 'tournaments', tournamentEditInfo.id);
        const updatePromise = updateDoc(tournamentRef, updatedTournament);

        toast.promise(updatePromise, {
          pending: 'Updating tournament',
          success: 'Tournament updated',
          error: 'Error updating tournament'
        });

        await updatePromise;
      } catch (err) {
        console.error(err);
        toast.error('Error updating tournament');
      }
    }
  }

  useEffect(() => {
    getGroups();
    setTournamentEditInfo(tournament ? {
      ...tournament,
      cardImageFile: null,
      bannerImageFile: null,
      cardImagePreviewUrl: tournament.cardImage,
      bannerImagePreviewUrl: tournament.bannerImage
    } : null);
  }, [tournament]);

  useEffect(() => {
    getConfirmedTeams();
  }, [tournamentTeams]);

  const getGroups = async () => {
    if (tournament && !groupsListenerInitialised) {
      const groupsCollection = collection(firestore, 'tournaments', tournament.id, 'groups');
      const groupsQuery = query(groupsCollection);
      onSnapshot(groupsQuery, (snapshots) => {
        const localGroups = snapshots.docs.map((doc) => doc.data() as TournamentGroup);
        const localStages: TournamentStage[] = [];
        localGroups.forEach((group) => {
          const localStageIndex = localStages.findIndex((stage) => stage.stageNum === group.stage);
          if (localStageIndex !== -1) {
            localStages[localStageIndex].groups.push(group);
          } else {
            localStages.push({
              stageNum: group.stage,
              groups: [group],
            })
          }
        })
        localStages.sort((a,b) => a.stageNum - b.stageNum);
        for (let i = 0; i < localStages.length; i++) {
          localStages[i].groups.sort((a, b) => {
            const groupALetter = a.groupName.split(' ')[1]; // Convert to uppercase for case-insensitive comparison
            const groupBLetter = b.groupName.split(' ')[1];

            if (groupALetter < groupBLetter) {
              return -1;
            }
            if (groupALetter > groupBLetter) {
              return 1;
            }
            return 0;
          });
        }
        setStages(localStages);
        setGroups(localGroups);
        setGroupsListenerInitialised(true);
      })
    }
  }
  const getGroupGames = () => {
    if (tournament) {
      const unsubscribes: Unsubscribe[] = [];
      groups.forEach((group) => {
        const groupGamesCollection = collection(firestore, 'tournaments', tournament.id, 'groups', group.id, 'games');
        const groupGamesQuery = query(groupGamesCollection);
        const unsubscribe = onSnapshot(groupGamesQuery, (snapshots) => {
          const localGroupGames = {...groupGames};
          const games = snapshots.docs.map((doc) => doc.data() as TournamentGame);
          setPlayStarted(games.some((game) => game.playerCodesDistributed))
          localGroupGames[group.id] = games;
          setGroupGames(prevValue => ({...prevValue, ...localGroupGames}));
        });
        unsubscribes.push(unsubscribe);
      })
      return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
    }
  }

  useEffect(() => {
    getGroupGames();
  }, [groups])

  const changeTeamGroup = async (team: DBTeamWithGroups, group: TournamentGroup, remove: boolean) => {
    if (tournament && confirmedTeams) {
      const tournamentTeamRef = doc(firestore, 'tournaments', tournament.id, 'teams', team.id!);
      const teamsCollectionRef = collection(firestore, 'tournaments', tournament.id, 'teams');
      const teamsQuery = query(teamsCollectionRef, where('status', '==', 2));
      let teamsInGroup = (await getDocs(teamsQuery)).docs.map((doc) => doc.data() as TournamentTeam).filter((team) => team.groups.some((teamGroup) => teamGroup.groupId === group.id));
      if (remove) {
        const teamGroups = team.groups.filter(teamGroup => teamGroup.groupId !== group.id);
        await updateDoc(tournamentTeamRef, {
          groups: teamGroups,
        })
      } else {
        if (teamsInGroup.length < 20) {
          const teamGroups = team.groups;
          teamGroups.push({groupId: group.id, groupPosition: -1});
          await updateDoc(tournamentTeamRef, {
            groups: teamGroups,
          })
        } else {
          toast.error('Cannot assign team to group, group is full');
          return;
        }
      }
      teamsInGroup = (await getDocs(teamsQuery)).docs.map((doc) => doc.data() as TournamentTeam).filter((team) => team.groups.some((teamGroup) => teamGroup.groupId === group.id));
      teamsInGroup.sort((a, b) => {
        const teamNameA = a.teamName.toUpperCase(); // Convert to uppercase for case-insensitive comparison
        const teamNameB = b.teamName.toUpperCase();

        if (teamNameA < teamNameB) {
          return -1;
        }
        if (teamNameA > teamNameB) {
          return 1;
        }
        return 0;
      });
      const batch = writeBatch(firestore);
      teamsInGroup.forEach((team, index) => {
        const teamRef = doc(teamsCollectionRef, team.id);
        const newGroup = {groupId: group.id, groupPosition: index + 1};
        const teamGroups = team.groups.filter(teamGroup => teamGroup.groupId !== group.id);
        teamGroups.push(newGroup);
        batch.update(teamRef, {groups: teamGroups});
      });
      const teamOrderPromise = batch.commit();
      const combinedPromise = Promise.all([teamOrderPromise]);
      toast.promise(combinedPromise, {
        pending: !remove ? `Assigning team to ${group.groupName}` : 'Unassigning team',
        success: !remove ? `Team assigned to ${group.groupName}` : 'Team unassigned',
        error: !remove ? `Error assigning team` : 'Error unassigning team',
      })
    }
  }

  const getConfirmedTeams = async () => {
    if (tournamentTeams) {
      const tournamentConfirmedTeamIDGroups: [string, {groupId: string, groupPosition: number}[], boolean][] = tournamentTeams.filter((team) => team.status === TournamentTeamStatus.confirmed).map((team) => [team.id, team.groups, team.eliminated]);
      const tournamentDeclinedTeamIds: string[] = tournamentTeams.filter((team) => team.status === TournamentTeamStatus.declined).map((team) => team.id);
      const tournamentIgnoredTeamIds: string[] = tournamentTeams.filter((team) => team.status === TournamentTeamStatus.ignored).map((team) => team.id);

      const confirmedTeamQueries = tournamentConfirmedTeamIDGroups.map(async ([teamId, teamGroups, eliminated]) => {
        const teamRef = doc(firestore, "teams", teamId).withConverter(teamConverter);
        const teamSnapshot = getDoc(teamRef);
        const team = (await teamSnapshot).data() as DBTeamWithGroups;
        team.groups = teamGroups;
        team.eliminated = eliminated;
        return team
      })

      const declinedTeamQueries = tournamentDeclinedTeamIds.map(async (teamId) => {
        const teamRef = doc(firestore, "teams", teamId).withConverter(teamConverter);
        const teamSnapshot = getDoc(teamRef);
        const team = (await teamSnapshot).data()!;
        return team
      })

      const ignoredTeamQueries = tournamentIgnoredTeamIds.map(async (teamId) => {
        const teamRef = doc(firestore, "teams", teamId).withConverter(teamConverter);
        const teamSnapshot = getDoc(teamRef);
        const team = (await teamSnapshot).data()!;
        return team
      })

      const confirmedTeams = await Promise.all(confirmedTeamQueries);
      const declinedTeams = await Promise.all(declinedTeamQueries);
      const ignoredTeams = await Promise.all(ignoredTeamQueries);
      setConfirmedTeams(confirmedTeams);
      setDeclinedTeams(declinedTeams);
      setIgnoredTeams(ignoredTeams)
    }
  }

  const setStageLocked = async (stageNum: number, locked: boolean, unassignedTeamLength: number) => {
    if (tournament) {
      if (locked && unassignedTeamLength > 0) {
        toast.error('Can not lock groups while some teams remain unassigned!')
        return;
      }
      if (!locked && tournament.stagesInPlay.includes(stageNum)) {
        toast.error('Can not unlock groups, stage has started/already run')
        return;
      }
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      let updatePromise = Promise.resolve();
      if (locked) {
        updatePromise = updateDoc(tournamentRef, {
          lockedStages: arrayUnion(stageNum),
        })
      } else {
        updatePromise = updateDoc(tournamentRef, {
          lockedStages: arrayRemove(stageNum),
        })
      }
      toast.promise(updatePromise, {
        pending: `${locked ? 'Locking' : 'Unlocking'} groups`,
        success: `Groups ${locked ? 'locked' : 'unlocked'}`,
        error: `Error ${locked ? 'Locking' : 'Unlocking'} groups`
      })
    }
  }

  const contextValue = {
    groups: groups,
    stages: stages,
    groupGames: groupGames,
    confirmedTeams: confirmedTeams,
    declinedTeams: declinedTeams,
    ignoredTeams: ignoredTeams,
    playStarted: playStarted,
    changeTeamGroup: changeTeamGroup,
    setStageLocked: setStageLocked,
    tournamentEditInfo: tournamentEditInfo,
    setTournamentEditInfo: setTournamentEditInfo,
    saveTournamentEditInfo: saveTournamentEditInfo
  }

  return (
    <TournamentAdminContext.Provider value={contextValue}>
      {children}
    </TournamentAdminContext.Provider>
  );
}

export default TournamentAdminProvider;
